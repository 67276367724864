import { useEffect, useState } from "react";
import "./UserProfileSavedItems.css";
import SavedItemsList from "../../components/SavedItems/SavedItemsList";
import SIWatchList from "../../components/SavedItems/SIWatchList";
import SIMyPurchases from "../../components/SavedItems/SIMyPurchases";
import SICorporateSearch from "../../components/SavedItems/SICorporateSearch";
import SICorporateProfile from "../../components/SavedItems/SICorporateProfile";
import BellIcon from '../../assets/images/icons/BellIcon.png'
import corporateLinkIcon from '../../assets/images/icons/corporateLinkIcon.png'
import corporateSearch from '../../assets/images/icons/corporateSearch.png'
import corporateProfileIcon from '../../assets/images/icons/corporateProfileIcon.png'
import JudgmentIcon from '../../assets/images/icons/JudgmentIcon.png'
import arrowDown from '../../assets/images/icons/arrowDownBlack.png'

const UserProfileSavedItems = () => {
    const [activeTab, setActiveTab] = useState("My Purchases");
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const tabs = [
        { name: "My Purchases", icon: JudgmentIcon },
        { name: "Watch List", icon: BellIcon },
        { name: "Corporate Link", icon: corporateLinkIcon },
        { name: "Corporate Search", icon: corporateSearch },
        { name: "Corporate Profile", icon: corporateProfileIcon },
    ];

    return (
        <div className="up-saved-items-container">
            <div className="up-header">
                <div>
                    <h2 className="user-profile-dashboard-title">My Saved Items</h2>
                    <p style={{ fontWeight: "normal", fontSize: "13px" }}>
                        Here you can see your saved data of all time.
                    </p>
                </div>
            </div>
            <hr style={{ marginTop: "0" }} />
            {!isMobile &&
                <div div className="up-tabs">
                    {tabs.map((tab) => (
                        <button
                            key={tab.name}
                            className={`up-tab ${activeTab === tab.name ? "active" : ""}`}
                            onClick={() => setActiveTab(tab.name)}
                        >
                            <img style={{ height: '40px', width: '40px' }} src={tab.icon} alt={tab.name} />
                            {tab.name}
                        </button>
                    ))}
                </div>
            }

            {isMobile &&
                <div>
                    <button className="up-dropdown-button" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                        <div>
                            <img style={{ height: '30px', width: '30px', marginRight: '10px' }} src={tabs.find(tab => tab.name === activeTab)?.icon} alt={activeTab} />
                            {activeTab}
                        </div>
                        <img src={arrowDown} alt="" />
                    </button>
                    {isDropdownOpen && (
                        <div className="up-tabs">
                            {tabs
                                .filter(tab => tab.name !== activeTab) // Filter out the active tab
                                .map((tab) => (
                                    <button
                                        key={tab.name}
                                        className="up-tab"
                                        onClick={() => {
                                            setActiveTab(tab.name);
                                            setIsDropdownOpen(false);
                                        }}
                                    >
                                        <img style={{ height: '40px', width: '40px' }} src={tab.icon} alt={tab.name} />
                                        {tab.name}
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
            }

            {/* Show records only when tab is active */}
            {activeTab === "My Purchases" && <SIMyPurchases />}
            {activeTab === "Watch List" && <SIWatchList />}
            {activeTab === "Corporate Link" && <SavedItemsList />}
            {activeTab === "Corporate Search" && <SICorporateSearch />}
            {activeTab === "Corporate Profile" && <SICorporateProfile />}
        </div >
    );
};

export default UserProfileSavedItems;
