import React, { useState, useContext } from 'react';
import LogoIcon from '../../assets/images/icons/Logo-colored.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../../context/Context';
import './additional.css';
import './FeedbackForm.css';
import ProbeButton from '../../components/ProbeButton/ProbeButton';
import showToast from '../../utils/showToast';
import { apiConfig, buildUrl } from '../../api/apiConfig';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

const FeedbackForm = () => {
  const { user } = useContext(AppContext);
  const { setIsLoading } = useLoading();

  const [formData, setFormData] = useState({
    title: '',
    description: '',
    priority: 'High',
    email: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    email: '',
  });

  const setValue = (prop, val) => {
    setFormData(prev => ({ ...prev, [prop]: val }));

    setErrors(prev => ({ ...prev, [prop]: '' }));
  };


  const validateForm = () => {
    const newErrors = {
      title: formData.title ? '' : 'This field is required',
      description: formData.description ? '' : 'This field is required',
      email: formData.email ? '' : 'This field is required',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every(err => err === '');
  };

  const onSubmit = async () => {
    if (!validateForm()) return;

    if (!user?.uid) {
      showToast('error', 'You need to login before submitting a suggestion');
      return;
    }

    const payload = {
      user_id: user.uid,
      title: formData.title,
      detailed_information: formData.description,
      importance: formData.priority,
      email: formData.email,
      phone: formData.phone,
    };
    setIsLoading(true);

    try {
      const response = await axios.post(
        buildUrl(apiConfig.base, apiConfig.feedback.endpoints.submitFeedbackForm),
        payload
      );

      if (response.status === 200) {
        showToast('success', 'Feedback submitted successfully');
        setFormData({ title: '', description: '', priority: 'High', email: '', phone: '' });
      }
    } catch (error) {
      showToast('error', 'Error submitting feedback');
      console.error('Feedback submission error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <section className="login-section feedback-section" style={{ backgroundColor: '#f4f4f4' }}>
          <div className="login-form-div feedback-form-div" style={{ backgroundColor: 'white' }}>
            <Link to="/home">
              <div className="text-center">
                <img className="logo-login" src={LogoIcon} alt="Logo" />
              </div>
            </Link>

            <div className="heading-1 dark-heading-color first-heading">Request a Feature</div>

            <div className="mb-4 FeedbackForm-text">
              Get your idea to our team. We view and approve each feature request. Approved ideas
              make their way onto our community roadmap where other users can vote.
            </div>

            <div className="input-outer-div">
              <div className="label-text FeedbackForm-title">Give your suggestion a title*</div>
              <div className="input-div">
                <input
                  className="input-login"
                  type="text"
                  placeholder="Enter title here"
                  value={formData.title}
                  onChange={e => setValue('title', e.target.value)}
                />
                {errors.title && <div className="error-text">{errors.title}</div>}
              </div>

              <div className="label-text">Write detailed information about your suggestion*</div>
              <div className="input-div">
                <textarea
                  className="input-login"
                  rows={5}
                  placeholder="Write your details here"
                  value={formData.description}
                  onChange={e => setValue('description', e.target.value)}
                />
                {errors.description && <div className="error-text">{errors.description}</div>}
              </div>

              <div className="label-text">How important is this feature to you?</div>
              <div className="input-div">
                <select
                  className="input-login"
                  value={formData.priority}
                  onChange={e => setValue('priority', e.target.value)}
                >
                  <option>High</option>
                  <option>Medium</option>
                  <option>Low</option>
                </select>
              </div>

              <div className="parallel-div mt-2">
                <div className="flex-grow-1">
                  <div className="label-text FeedbackForm-email">Email*</div>
                  <div className="input-div">
                    <input
                      className="input-login"
                      type="email"
                      placeholder="Enter email address here"
                      value={formData.email}
                      onChange={e => setValue('email', e.target.value)}
                    />
                    {errors.email && <div className="error-text">{errors.email}</div>}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="label-text">Phone</div>
                  <div className="input-div">
                    <input
                      className="input-login"
                      type="text"
                      placeholder="Enter phone number here"
                      value={formData.phone}
                      onChange={e => setValue('phone', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="FeedbackForm-submit-info-btn">
              <ProbeButton
                name="Submit Information"
                backgroundColor="#0466D1"
                borderRadius="10px"
                borderColor="blue"
                height="40px"
                width="237px"
                onClick={onSubmit}
                fontColor="#FFF"
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default FeedbackForm;
