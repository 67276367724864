import React, { useContext, useEffect, useRef, useState } from 'react';
import './Sidebar.css';
import { NavLink, useNavigate } from 'react-router-dom';
import probeDigitalCollapsed from '../assets/images/icons/probe-digital-collapsed-icon.svg';
import probeDigitalLogo from '../assets/images/icons/probe-digital-logo.svg';
import searchOnPerson from '../assets/images/icons/search-on-person.svg';
import CorporateIcon from '../assets/images/icons/Corporate.svg';
import OwnerShipIcon from '../assets/images/icons/Ownership.svg';
import BlogIcon from '../assets/images/icons/Blog.svg';
import FeedbackIcon from '../assets/images/sidebar/Mask group.png';
import JudgementCheckerIcon from '../assets/images/icons/judgement-checker.svg';
import CorporateExplorerNav from '../assets/images/corporateExplorer/corporateExplorerNav.svg';
import { AppContext } from '../context/Context';
import CONSTANTS from '../utils/constants';

function Sidebar() {
  const { toggleSidebar, setToggleSidebar, setUserCurrentLocation } =
    useContext(AppContext);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);

  const navigateHome = () => {
    if (process.env.REACT_APP_ENV_NAME === 'prod') {
      window.location.href = CONSTANTS.HUB_SPOT_URL;
    } else {
      navigate('/services');
    }
  };

  const menuItem = [
    {
      path: '/ccj-dashboard/judgement-checker',
      name: 'Judgments & Fines',
      icon: JudgementCheckerIcon,
      iconType: 'image',
    },
    {
      path: '/cl-dashboard/search-entites',
      name: 'Corporate Links',
      icon: CorporateIcon,
      iconType: 'image',
    },
    {
      path: '/ce-dashboard/ce-corporate-explorer',
      name: 'Corporate Search',
      icon: CorporateExplorerNav,
      iconType: 'image',
    },
    {
      path: '/cei-dashboard/cei-corporate-explore-insight',
      name: 'Corporate Profile',
      icon: CorporateExplorerNav,
      iconType: 'image',
    },
    {
      path: '/fo-dashboard/foregn-uk-ownership',
      name: 'Foreign UK Ownership',
      icon: OwnerShipIcon,
      iconType: 'image',
    },
    {
      path: '/bi-dashboard/background-investigation',
      name: 'Background Investigation',
      icon: searchOnPerson,
      iconType: 'image',
    },
    {
      path: '/fbf-dashboard/feedbackform',
      name: 'Feedback',
      icon: FeedbackIcon,
      iconType: 'image',
    },
    // {
    //   path: '/blog-dashboard/view',
    //   name: 'Blogs',
    //   icon: BlogIcon,
    //   iconType: 'image',
    // },
  ];

  useEffect(() => {
    setIsOpen(toggleSidebar);
  }, [toggleSidebar]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setToggleSidebar(false);
      }
    }

    if (toggleSidebar) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleSidebar]);

  return (
    <div className="sidebar-outer">
      {toggleSidebar ? (
        <div
          ref={sidebarRef}
          className={
            isOpen
              ? 'sidebar sidebar-opened show'
              : 'sidebar sidebar-closed hide'
          }
        >
          {/* Close icon */}
          <div className="close-icon" onClick={() => setToggleSidebar(false)}>
            &#10005; {/* You can replace this with an image or SVG */}
          </div>

          <div
            className="top_section"
            onClick={navigateHome}
            style={{ cursor: 'pointer' }}
          >
            <div className="logo">
              <img
                alt="icon here"
                src={isOpen ? probeDigitalLogo : probeDigitalCollapsed}
              />
            </div>
          </div>
          {menuItem.map((Item, index) => (
            <NavLink
              onClick={() => {
                setToggleSidebar(false);
                setUserCurrentLocation(Item.path);
              }}
              to={Item.path}
              key={index}
              className="link"
              end
              style={{ justifyContent: isOpen ? 'left' : 'center' }}
            >
              <div className="sidebar-icon">
                {Item.iconType === 'image' && (
                  <img alt="icon here" src={Item.icon} />
                )}
              </div>
              <div
                className="link_text small-text"
                style={{ display: isOpen ? 'block' : 'none' }}
              >
                {Item.name}
              </div>
            </NavLink>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default Sidebar;
