import React, { useEffect, useState } from 'react';
import ProbLabel from '../../../components/ProbLabel/ProbLabel';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import AddIcon from '@mui/icons-material/Add';
// import usePageTitle from '../../../context/PageContext/PageContext.js';
import CESearchEntitiesSection from './CESearchEntitiesSearchSection';
import '../../CorporateLinks/CESearchEntities/CESearchEntitiesFlowSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import InsightCard from '../../../components/CorporateExploreInsights/CEIInsightResultsCard/CEIinsightResultsCard.jsx';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem.jsx';
import ChevronDown from '../../../assets/images/icons/ChevronDown.png';
import ChevronUp from '../../../assets/images/icons/ChevronUp.png';
import PersonBlack from '../../../assets/images/icons/PersonBlack.png';
import PersonWhite from '../../../assets/images/icons/PersonWhite.png';
import CompanyBlack from '../../../assets/images/icons/CompanyBlack.png';
import CompanyWhite from '../../../assets/images/icons/CompanyWhite.png';
import EmptyPerson from '../../../assets/images/icons/EmptyPerson.png';
import ExclaimationMark from '../../../assets/images/icons/ExclaimationMark.png';
import { formatNumber, formatNumberForCL } from '../../../utils/helper.js';

const CESearchEntitiesFlowSection = ({
  searchContainerRef,
  inputValue,
  setInputValue,
  handleInputBlur,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  removeItem,
  itemClicked,
  setSelectedType,
  hideButtonsAndSearch,
  setHideButtonsAndSearch,
  setSuggestions,
  setIsOpen,
  totalOfficersAndCompanies,
  handleConfirm,
  isSearching,
  isLimitReached,
  setSelectedSuggestions,
  setIsLimitReached,
  selectedActualSuggestions,
  setIsSearching,
  resetAdvanceFilters,
  currentPage,
  setCurrentPage,
}) => {
  // const pageTitle = usePageTitle();
  const [selectEntityTypeButtonList] = useState(['Person', 'Company']);
  const [isSearchSectionEnabled, setIsSearchSectionEnabled] = useState(false);
  // const [isCESearchOnlyVisible, setIsCESearchOnlyVisible] = useState(true);
  const [isChevronVisible, setIsChevronVisible] = useState(true);
  const [isSelectedCardChevronVisible, setIsSelectedCardChevronVisible] =
    useState(false);
  const [selectedCardIndices, setSelectedCardIndices] = useState([]);
  const [isDottedCardClicked, setIsDottedCardClicked] = useState(false);
  const [isSelectedEntitiesModalOpen, setIsSelectedEntitiesModalOpen] =
    useState(false);
  const [itemsPerPage] = useState(9);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = suggestions
    ? suggestions.slice(indexOfFirstItem, indexOfLastItem)
    : [];
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const pageCount = Math.ceil(
    (suggestions ? suggestions.length : 0) / itemsPerPage
  );

  const handleItemClick = (type, item, index) => {
    if (selectedCardIndices.includes(index)) {
      // Deselect item
      setSelectedCardIndices(prev => {
        const updatedIndices = prev.filter(i => i !== index);

        if (selectedType === 'Person') {
          removeItem(item.name);
        } else if (selectedType === 'Company') {
          removeItem(item.title);
        }

        return updatedIndices; // Return updated indices array
      });
    } else {
      // Select item
      if (selectedSuggestions.length < 2) {
        setSelectedCardIndices(prev => [...prev, index]);
      }
      itemClicked(type, item);
    }
  };

  // const handleInputFocusChild = () => {
  //   setIsCESearchOnlyVisible(false);
  // };

  const toggleChevronVisibility = () => {
    setIsChevronVisible(!isChevronVisible);
  };

  const toggleSelectedCardChevronVisible = () => {
    setIsSelectedCardChevronVisible(!isSelectedCardChevronVisible);
  };

  const handleSelectEntity = type => {
    handleButtonClick(type);
    setIsSearchSectionEnabled(true);
    setSelectedCardIndices([]);
  };

  const selectEntityType = () => {
    return (
      <div className="corporate-links-flow-section-add-entity-button-holder">
        {selectEntityTypeButtonList.map(item => {
          const isSelected = item === selectedType;
          return (
            <ProbeButton
              key={item}
              width="150px"
              height="40px"
              name={item}
              fontColor={isSelected ? '#FFFFFF' : '#000000'}
              backgroundColor={
                isSelected
                  ? 'linear-gradient(to bottom right, #0466D1, #00D1A9)'
                  : '#CFCFCF'
              }
              borderColor="#CFCFCF"
              borderRadius="20px"
              iconSrc={
                item === 'Person'
                  ? isSelected
                    ? PersonWhite
                    : PersonBlack
                  : isSelected
                    ? CompanyWhite
                    : CompanyBlack
              }
              iconSize="22px"
              iconType="png"
              onClick={() => {
                setIsDottedCardClicked(false);
                handleSelectEntity(item);
              }}
              disabled={item === '...' ? true : false}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="content-container">
        <div className="d-flex justify-content-center">
          <div className="corporate-links-flow-section-top-container">
            <div>
              <div>
                {/* <h2 className="text-black">{pageTitle}</h2> */}
                <h2
                  className="text-black"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Search & Explore Connections
                  <span
                    style={{ marginLeft: '8px', cursor: 'pointer' }}
                    onClick={toggleChevronVisibility}
                  >
                    <img
                      src={isChevronVisible ? ChevronUp : ChevronDown}
                      alt="Chevron Icon"
                      style={{ width: '16px', height: '16px' }}
                    />
                  </span>
                </h2>
                {isChevronVisible && (
                  <>
                    <ProbLabel text="Step 1: Select Entity Type ( Person or Company)" />
                    <ProbLabel text="Step 2: Type the name and press search" />
                    <ProbLabel text="Step 3: Filter results" />
                  </>
                )}
              </div>
              <div className="corporate-links-flow-section-add-entity">
                {selectEntityType()}
                {selectedSuggestions.length > 0 && suggestions.length > 0 && (
                  <div
                    className="selected-entites-button"
                    style={{ position: 'relative' }}
                  >
                    SELECTED ENTITIES : {selectedSuggestions.length}
                    <span
                      style={{ marginLeft: '8px', cursor: 'pointer' }}
                      onClick={toggleSelectedCardChevronVisible}
                    >
                      <FontAwesomeIcon
                        icon={
                          isSelectedCardChevronVisible
                            ? faChevronLeft
                            : faChevronRight
                        }
                      />
                    </span>
                    {isSelectedCardChevronVisible && (
                      <div
                        style={{
                          position: 'absolute',
                          right: '-8px',
                          top: '100%',
                          zIndex: 10, // Ensure it appears above other elements
                          width: '150%', // Adjust width dynamically
                          marginTop: '8px', // Add spacing below the button,
                          backgroundColor: '#FFF',
                          padding: '0 10px',
                        }}
                      >
                        {selectedSuggestions.map((sugg, index) => {
                          return (
                            <div key={index}>
                              {sugg.type === 'Officer' && (
                                <div
                                  className="card-wrapper"
                                  style={{ position: 'relative' }}
                                >
                                  <InsightCard
                                    isSelected={true}
                                    officer={sugg}
                                  />
                                  <div
                                    className="overlay"
                                    onClick={() => {
                                      const indexToRemove =
                                        selectedCardIndices[
                                        selectedSuggestions.indexOf(sugg)
                                        ];
                                      setIsSelectedCardChevronVisible(false);
                                      removeItem(sugg.title || sugg.name);
                                      setSelectedCardIndices(prev =>
                                        prev.filter(
                                          index => index !== indexToRemove
                                        )
                                      );
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              )}
                              {sugg.type === 'Company' && (
                                <div
                                  className="card-wrapper"
                                  style={{ position: 'relative' }}
                                >
                                  <CompanyItem
                                    isSelected={true}
                                    company={sugg}
                                    isCardView={true}
                                  />
                                  <div
                                    className="overlay"
                                    onClick={() => {
                                      const indexToRemove =
                                        selectedCardIndices[
                                        selectedSuggestions.indexOf(sugg)
                                        ];
                                      setIsSelectedCardChevronVisible(false);
                                      removeItem(sugg.title || sugg.name);
                                      setSelectedCardIndices(prev =>
                                        prev.filter(
                                          index => index !== indexToRemove
                                        )
                                      );
                                    }}
                                  >
                                    Remove
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row mt-2 corporate-links-flow-section-search-container">
              <div className="col-md-8">
                <CESearchEntitiesSection
                  // setIsCESearchOnlyVisible={setIsCESearchOnlyVisible}
                  // isCESearchOnlyVisible={isCESearchOnlyVisible}
                  searchContainerRef={searchContainerRef}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  handleInputBlur={handleInputBlur}
                  handleChange={handleChange}
                  selectedSuggestions={selectedSuggestions}
                  handleGraphPage={handleGraphPage}
                  isOpen={isOpen && isSearchSectionEnabled}
                  selectedType={selectedType}
                  handleButtonClick={handleButtonClick}
                  isLoadingPage={isLoadingPage}
                  loadingGif={loadingGif}
                  suggestions={suggestions}
                  removeItem={removeItem}
                  itemClicked={itemClicked}
                  showCards={false}
                  totalOfficersAndCompanies={totalOfficersAndCompanies}
                  disabled={
                    !selectedType || selectedActualSuggestions.length === 2
                      ? true
                      : false
                  }
                  startIcon={<FontAwesomeIcon icon={faSearch} />}
                  isDottedCardClicked={isDottedCardClicked}
                  setSuggestions={setSuggestions}
                  setSelectedType={setSelectedType}
                  setSelectedSuggestions={setSelectedSuggestions}
                  setIsLimitReached={setIsLimitReached}
                  setIsSearching={setIsSearching}
                  selectedActualSuggestions={selectedActualSuggestions}
                  resetAdvanceFilters={resetAdvanceFilters}
                  currentItems={currentItems}
                />
              </div>
              <div className="col-md-3 flowSection-top-button">
                <ProbeButton
                  width={'100%'}
                  height={'40px'}
                  borderRadius={'20px'}
                  marginRight={'32px'}
                  name={isSearching ? 'Search' : 'Confirm'}
                  fontColor={!(inputValue.length > 0) ? '#000000' : '#FFFFFF'}
                  backgroundColor={
                    !(inputValue.length > 0)
                      ? '#CFCFCF'
                      : 'linear-gradient(to bottom right, #0466D1, #00D1A9)'
                  }
                  borderColor={'#CFCFCF'}
                  disabled={!(inputValue.length > 0)}
                  onClick={() => {
                    // setIsCESearchOnlyVisible(false);
                    handleConfirm();
                  }}
                />
              </div>
              <div className="col-md-11">
                <hr style={{ padding: 0, marginTop: 5, marginBottom: 5 }} />
              </div>
            </div>
          </div>
        </div>
        <div className="scrollable-content-container">
          {/* {isLoadingPage && <div style={{ width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
          <div>
            <img src={loadingGif} alt="Loading..." width={30} />
          </div>
        </div>
        } */}
          {!isLoadingPage && (
            <div>
              {suggestions.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '3rem',
                  }}
                >
                  {selectedSuggestions.length > 0 && suggestions.length > 0 ? (
                    <p
                      className="ce-flow-section-search-results-mob-screen"
                      onClick={() => setIsSelectedEntitiesModalOpen(true)}
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14px',
                        letterSpacing: '0.015em',
                        textAlign: 'center',
                        cursor: 'pointer',
                        flex: 1,
                      }}
                    >
                      SELECTED ENTITIES : {selectedSuggestions.length}
                    </p>
                  ) : (
                    <p
                      className="ce-flow-section-search-results-mob-screen"
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '14px',
                        letterSpacing: '0.015em',
                        textAlign: 'center',
                        flex: 1,
                      }}
                    >
                      <span>Top </span> {suggestions ? suggestions.length : 0}{' '}
                      Results out of{' '}
                      {totalOfficersAndCompanies &&
                        formatNumberForCL(totalOfficersAndCompanies)}
                    </p>
                  )}

                  <p
                    className="ce-flow-section-search-results"
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '14px',
                      letterSpacing: '0.015em',
                      textAlign: 'center',
                      flex: 1,
                    }}
                  >
                    <span>Top </span>
                    {suggestions ? suggestions.length : 0} Results out of{' '}
                    {totalOfficersAndCompanies &&
                      formatNumberForCL(totalOfficersAndCompanies)}
                  </p>

                  {isLimitReached && (
                    <p
                      className=""
                      style={{
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '14px',
                        letterSpacing: '0.015em',
                        marginRight: '15px',
                        color: '#FF0000',
                      }}
                    >
                      <img
                        style={{
                          width: '16px',
                          height: '16px',
                          marginRight: '7px',
                        }}
                        src={ExclaimationMark}
                        alt="User Icon"
                      />
                      Maximum Limit Reached
                    </p>
                  )}
                </div>
              )}
              <div
                className="flowSection-card-container"
                style={{
                  display: suggestions.length < 1 ? 'none' : 'flex',
                }}
              >
                {selectedType === 'Person'
                  ? currentItems.map((person, index) => (
                    <div
                      className="flowSection-card-item"
                      key={index} // Use index as key
                      onClick={() =>
                        handleItemClick(selectedType, person, index)
                      }
                    >
                      <InsightCard
                        isSelected={selectedCardIndices.includes(index)}
                        officer={person}
                      />
                    </div>
                  ))
                  : currentItems.map((comp, index) => (
                    <div
                      className="flowSection-card-item"
                      key={index} // Use index as key
                      onClick={() => handleItemClick(selectedType, comp, index)}
                    >
                      <CompanyItem
                        company={comp}
                        isCardView={true}
                        isSelected={selectedCardIndices.includes(index)}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}

          {currentItems.length > 0 && (
            <div
              className="ce-search-entities-pagination-container"
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '20px 0',
              }}
            >
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="ce-search-entities-pagination-button prev-next" // Added `prev-next` class here
              >
                &lt; PREVIOUS
              </button>
              {isMobile ? (
                <>
                  {/* <span>
                  {currentPage} out of {pageCount}
                </span> */}
                  <button
                    className={`ce-search-entities-pagination-button active`}
                    style={{
                      // width: "32px",
                      // height: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {currentPage}
                  </button>
                  out of
                  {/* Show ellipsis if there are more than 2 pages */}
                  {pageCount > 2 && (
                    <button
                      onClick={() => handlePageChange(pageCount)}
                      className={`ce-search-entities-pagination-button ${currentPage === pageCount ? 'active' : ''}`}
                    >
                      {pageCount}
                    </button>
                  )}
                </>
              ) : (
                [...Array(pageCount)].map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`ce-search-entities-pagination-button ${currentPage === index + 1 ? "active" : ""
                      }`}
                    style={{
                      // width: "32px",
                      // height: "32px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {index + 1}
                  </button>
                ))
              )}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === pageCount}
                className="ce-search-entities-pagination-button prev-next" // Added `prev-next` class here
              >
                NEXT &gt;
              </button>
            </div>
          )}

          <div className="ec-search-entites-explore-link-officer-card-main">
            {suggestions.length < 1 && (
              <div
                className="ec-search-entites-explore-link-officer-card"
                style={{
                  width: selectedActualSuggestions.length < 2 ? 'none' : '70%',
                }}
              >
                {selectedActualSuggestions.length === 1 && (
                  <div
                    onClick={() => {
                      if (!selectedType) {
                        setIsDottedCardClicked(true);
                      }
                    }}
                    className="ec-search-entites-explore-link-officer-mock-card-container-mobile"
                  >
                    <div className="ec-search-entites-explore-link-officer-mock-card">
                      <div className="card-icon">
                        <img src={EmptyPerson} alt="User Icon" />
                      </div>
                      <div className="card-text">
                        ADD ANOTHER ENTITY TO SEE THE CONNECTION BETWEEN THEM
                      </div>
                    </div>
                  </div>
                )}

                {selectedActualSuggestions &&
                  selectedActualSuggestions.map(sugg => {
                    if (sugg.type === 'Officer') {
                      return (
                        <div
                          className="explore-link-officer-card-item"
                          key={sugg.id}
                        >
                          <InsightCard
                            isSelected={true}
                            officer={sugg}
                            removeItem={removeItem}
                          />
                        </div>
                      );
                    } else if (sugg.type === 'Company') {
                      return (
                        <div
                          className="explore-link-officer-card-item"
                          key={sugg.id}
                        >
                          <CompanyItem
                            isSelected={true}
                            company={sugg}
                            isCardView={true}
                            removeItem={removeItem}
                          />
                        </div>
                      );
                    }
                    return null; // Return null for suggestions that don't match the types
                  })}

                {selectedActualSuggestions.length === 1 && (
                  <div
                    onClick={() => {
                      if (!selectedType) {
                        setIsDottedCardClicked(true);
                      }
                    }}
                    className="ec-search-entites-explore-link-officer-mock-card-container"
                  >
                    <div className="ec-search-entites-explore-link-officer-mock-card">
                      <div className="card-icon">
                        <img src={EmptyPerson} alt="User Icon" />
                      </div>
                      <div className="card-text">
                        ADD ANOTHER ENTITY TO SEE THE CONNECTION BETWEEN THEM
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {inputValue.length < 1 && (
              <div className="cesearch-entites-footer">
                <ProbeButton
                  width="343px"
                  height="40px"
                  name="Create Network"
                  borderRadius="20px"
                  backgroundColor={
                    selectedActualSuggestions.length === 0
                      ? '#CFCFCF'
                      : 'linear-gradient(to right, #0466D1, #00D1A9)'
                  }
                  fontColor="white"
                  borderColor="#CFCFCF"
                  disabled={selectedActualSuggestions.length === 0 ? true : false}
                  iconSrc={<AddIcon />}
                  onClick={handleGraphPage}
                />
              </div>
            )}
            {inputValue.length > 0 && (
              <div className="cesearch-entites-footer-button">
                <ProbeButton
                  width={'100%'}
                  height={'40px'}
                  borderRadius={'20px'}
                  marginRight={'0px'}
                  name={isSearching ? 'Search' : 'Confirm'}
                  fontColor={!(inputValue.length > 0) ? '#000000' : '#FFFFFF'}
                  backgroundColor={
                    !(inputValue.length > 0)
                      ? '#CFCFCF'
                      : 'linear-gradient(to bottom right, #0466D1, #00D1A9)'
                  }
                  borderColor={'#CFCFCF'}
                  disabled={!(inputValue.length > 0)}
                  onClick={() => {
                    // setIsCESearchOnlyVisible(false);
                    handleConfirm();
                  }}
                />
              </div>
            )}
          </div>
          {/* <div style={{ marginTop: '0' }}>
          <hr style={{
            width: '66%',
            marginLeft: 'auto',
            marginRight: '20%',
            marginTop: '0',
            marginBottom: '0',
          }} />
        </div> */}
          {isSelectedEntitiesModalOpen && (
            <div
              className="ce-flow-section-footer-popup"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                }}
              >
                <h3 style={{ marginLeft: '10px' }}>Selected Entities</h3>
                <div
                  style={{
                    zIndex: 10, // Ensure it appears above other elements
                    width: 'max-width', // Adjust width dynamically
                    marginTop: '8px', // Add spacing below the button,
                    backgroundColor: '#FFF',
                    padding: '0 10px',
                  }}
                >
                  {selectedSuggestions.map((sugg, index) => {
                    return (
                      <div key={index}>
                        {sugg.type === 'Officer' && (
                          <div className="card-wrapper">
                            <InsightCard isSelected={true} officer={sugg} />
                            <div
                              className="overlay"
                              onClick={() => {
                                const indexToRemove =
                                  selectedCardIndices[
                                  selectedSuggestions.indexOf(sugg)
                                  ];
                                setIsSelectedCardChevronVisible(false);
                                removeItem(sugg.title || sugg.name);
                                setSelectedCardIndices(prev =>
                                  prev.filter(index => index !== indexToRemove)
                                );
                              }}
                            >
                              Remove
                            </div>
                          </div>
                        )}
                        {sugg.type === 'Company' && (
                          <div className="card-wrapper">
                            <CompanyItem
                              isSelected={true}
                              company={sugg}
                              isCardView={true}
                            />
                            <div
                              className="overlay"
                              onClick={() => {
                                const indexToRemove =
                                  selectedCardIndices[
                                  selectedSuggestions.indexOf(sugg)
                                  ];
                                setIsSelectedCardChevronVisible(false);
                                removeItem(sugg.title || sugg.name);
                                setSelectedCardIndices(prev =>
                                  prev.filter(index => index !== indexToRemove)
                                );
                              }}
                            >
                              Remove
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <button
                  style={{
                    padding: '10px 0',
                    margin: '0 10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'white',
                    background:
                      'linear-gradient(90deg,#0298be 0%, #00beaf  99.76%)',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    width: '93%',
                  }}
                  onClick={() => setIsSelectedEntitiesModalOpen(false)}
                >
                  Continue
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CESearchEntitiesFlowSection;
