import React, { useContext, useEffect, useState } from 'react';

import './CorporateExplorer.css';
import {
  companiesRegisteredLocationAutoSearch,
  explorerFilter,
  getTotalDocumentsOfIndex,
} from '../../api/corporateExplorer/corporateExplorer';
import CorporateExplorerFilter from '../../components/CorporateExplorerFilter/CorporateExplorerFilter';
import CorporateExplorerFilterExpanded from '../../components/CorporateExplorerFilter/CorporateExplorerFilterExpanded';
import { AppContext } from '../../context/Context';
import CorporateExplorerGrid from '../../components/CorporateExplorerFilter/CorporateExplorerGrid/CorporateExplorerGrid';
import Pagination from '../../components/Pagination/Pagination';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import FilterListIcon from '@material-ui/icons/FilterList';

export default function CorporateExplorer() {
  const { user } = useContext(AppContext);
  const [buildFilterResponseDefaultQuery, setBuildFilterResponseDefaultQuery] =
    useState([{ header: 'company_status', filter: ['active'] }]);
  const [locationSearchResults, setLocationSearchResults] = useState([]);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [filter, setFilter] = useState([]);
  const [totalCompaniesDocument, setTotalCompaniesDocument] = useState(0);
  const [countHolder, setCountHolder] = useState(0);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [filteredDataHolder, setFilteredDataHolder] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [accountsData, setAccountsData] = useState([]);

  const [buildFilterResponseHolder, setBuildFilterResponseHolder] =
    useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [paginationFrom, setpaginationFrom] = useState(0);
  const [sortValue, setSortValue] = useState(null);
  const [showColumns, setShowColumns] = useState([
    { key: 'company_name', header: 'Name' },
    { key: 'type', header: 'Type' },
    { key: 'can_file', header: 'Can File' },
    { key: 'sic_codes', header: 'Sic Codes' },
    { key: 'company_status', header: 'Status' },
    { key: 'date_of_creation', header: 'Date of Creation' },
    { key: 'accounts.last_accounts.type', header: 'Account Type' },
    {
      key: 'registered_office_address',
      no_header: 'registered_office_address',
    },
    {
      key: 'company_number',
      no_header: 'company_number',
    },
    {
      key: 'company_number',
      no_header: 'company_number',
    },
  ]);

  const [filterReadyToApply, setFilterReadyToApply] = useState([]);

  const buildFilter = filters => {
    // Define header mappings
    const headerMappings = {
      Name: 'company_name',
      Status: 'company_status',
      Type: 'type',
      'Accounts Category': 'accounts.last_accounts.type',
      'SIC Code': 'sic_codes',
      'Registered Address': 'registered_address',
      Incorporation: 'date_of_creation',
      'Accounts Year End': 'accounts.last_accounts.period_end_on',
      'Accounts Due By': 'accounts.next_due',
      'Accounts Last Made': 'accounts.last_accounts.made_up_to',
    };

    // Iterate through each filter object and update the header
    const filteredFilters = filters.filter(filter => filter.filter.length > 0);

    const updatedFilters = filteredFilters.map(filter => {
      // Get the corresponding header mapping
      const mappedHeader = headerMappings[filter.header];

      // Return a new object with the updated header
      return {
        ...filter,
        header: mappedHeader,
      };
    });
    return updatedFilters;
  };

  const locationSearchInput = async searchInput => {
    let result = await companiesRegisteredLocationAutoSearch(searchInput);

    if (result?.success) {
      setLocationSearchResults(result?.data);
    }
  };

  const { setIsLoading } = useLoading();

  const applyFilters = () => {
    setFilteredData(filteredDataHolder);
    setShowFilters(false);
  };

  const resetFilter = async () => {
    window.location.reload();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const toggleCompanyInfoModal = () => {
    setShowCompanyInfoModal(!showCompanyInfoModal);
  };

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const fetchDefaultData = async () => {
    try {
      setIsLoading(true);
      const response = await getTotalDocumentsOfIndex();
      setTotalCompaniesDocument(response?.count);
      setCountHolder(response?.count);

      const companiesList = await explorerFilter(
        buildFilterResponseDefaultQuery,
        1,
        50,
        null,
        null
      );

      setTotalPages(companiesList?.pagination?.totalPages);
      setpaginationFrom(currentPage * companiesList?.result?.length);
      setFilteredData(companiesList?.result);
      setAccountsData(companiesList?.accountsInfo);
      setIsLoading(false);
      setSortValue(companiesList?.pagination?.sortValueForSearchAfter);
    } catch (error) {
      setTotalCompaniesDocument(0);
      setIsLoading(false);
    }
  };

  const getHighestSortValue = response => {
    const sortValues = response?.pagination?.sortValueForSearchAfter;
    if (Array.isArray(sortValues) && sortValues.length > 0) {
      return [Math.max(...sortValues)];
    }
    return [];
  };

  useEffect(() => {
    const apiCall = async () => {
      let sortValueCheck = null;

      console.log('sortValueCheck', sortValue);

      const response = await explorerFilter(
        buildFilterResponseHolder === null
          ? buildFilterResponseDefaultQuery
          : buildFilterResponseHolder,
        currentPage + 1,
        50,
        sortValue,
        totalCompaniesDocument
      );

      setTotalCompaniesDocument(response?.pagination?.totalCompanies);
      setTotalPages(response?.pagination?.totalPages);
      setpaginationFrom(currentPage * response?.result?.length);
      setFilteredData(response?.result);
      setAccountsData(response?.accountsInfo);
      setSortValue(response?.pagination?.sortValueForSearchAfter);
    };

    apiCall();
  }, [currentPage]);

  useEffect(() => {
    if (filter?.length > 0) {
      let buildFilterResponse = buildFilter(filter);

      if (buildFilterResponse?.length > 0) {
        const fetchData = async () => {
          try {
            setIsLoading(true);
            setBuildFilterResponseHolder(buildFilterResponse);
            const response = await explorerFilter(buildFilterResponse);
            setTotalCompaniesDocument(response?.pagination?.totalCompanies);
            setTotalPages(response?.pagination?.totalPages);
            setFilteredDataHolder(response?.result);
            setFilteredData(response?.result);
            setAccountsData(response?.accountsInfo);
            setpaginationFrom(currentPage * response?.result?.length);
            setSortValue(response?.pagination?.sortValueForSearchAfter);
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
          }
        };
        fetchData();
      } else {
        setTotalCompaniesDocument(countHolder);
      }
    } else {
      setTotalCompaniesDocument(countHolder);
    }
  }, [filter]);

  useEffect(() => {
    setFilter(filterReadyToApply);
  }, [filterReadyToApply]);

  useEffect(() => {
    fetchDefaultData();
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className='cp-explorer-banner-image'>
          <h3>Welcome to</h3>
          <h1>Corporate Search</h1>
          <p>Effortlessly learn about companies that matter with comprehensive insight.</p>
        </div>
        <div className="dashboard-content-div">
          <section className="cp-explorer-section">

            <div className="cp-explorer-section-filter">
              <div className="cp-explorer-left-section">
                <h6>Welcome Back {user?.displayName}</h6>
                <p>Learn anything about any company just by applying filters</p>
              </div>
              <div className="cp-explorer-right-section">
                <div className="cp-explorer-right-search-container">
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ marginRight: '10px' }}
                  />
                  <input className="cp-explorer-right-search-input" type="text" placeholder='Search' />
                </div>
                {/* <div>
                  <CorporateExplorerFilter
                    totalCompaniesDocument={totalCompaniesDocument}
                    setTotalCompaniesDocument={setTotalCompaniesDocument}
                    applyFilters={applyFilters}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    toggleModal={toggleModal}
                    locationSearchInput={locationSearchInput}
                    locationSearchResults={locationSearchResults}
                    filterReadyToApply={filterReadyToApply}
                    setFilterReadyToApply={setFilterReadyToApply}
                  />
                </div> */}
                <div className="corporate_explorer_filter_button_text" onClick={toggleFilters}>
                  <FilterListIcon />
                  <div>Apply filters</div>
                </div>
                {/* <div
                  className="cp-explorer-filterreset-button"
                  onClick={resetFilter}
                >
                  <div>
                    <RestartAltIcon />
                  </div>
                  <div className="cp-explorer-filterreset-button-text">Reset</div>
                </div> */}
              </div>
            </div>
            {showFilters && <CorporateExplorerFilterExpanded
              totalCompaniesDocument={totalCompaniesDocument}
              setTotalCompaniesDocument={setTotalCompaniesDocument}
              applyFilters={applyFilters}
              locationSearchInput={locationSearchInput}
              locationSearchResults={locationSearchResults}
              filterReadyToApply={filterReadyToApply}
              setFilterReadyToApply={setFilterReadyToApply}
              onClose={() => setFilter(false)} />
            }

            <div className="cp-explorer-section-table">
              <CorporateExplorerGrid
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                setAccountsData={setAccountsData}
                accountsData={accountsData}
                hiddenColumns={hiddenColumns}
                showColumns={showColumns}
                paginationFrom={paginationFrom}
                showCompanyInfoModal={showCompanyInfoModal}
                setShowCompanyInfoModal={setShowCompanyInfoModal}
                toggleCompanyInfoModal={toggleCompanyInfoModal}
              />
            </div>

            <div style={{ marginTop: '20px' }}></div>
            {filteredData && (
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
