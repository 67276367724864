import './CESearchEntities.css';
import React, { useEffect, useState } from 'react';
import CESAdvancedFilter from './CESAdvancedFilter/CESAdvancedFilter';
import CESAdvancedFilterPerson from './CESAdvancedFilterPerson/CESAdvancedFilterPerson';
import CloseIcon from '@mui/icons-material/Close';
import { GradientIcon } from '../../../utils/helper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CESearchEntitiesSection = ({
  searchContainerRef,
  inputValue,
  setInputValue,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  itemClicked,
  showCards = true,
  totalOfficersAndCompanies,
  disabled,
  startIcon,
  isDottedCardClicked,
  setSuggestions,
  setSelectedType,
  setSelectedSuggestions,
  setIsSearching,
  setIsLimitReached,
  selectedActualSuggestions,
  resetAdvanceFilters,
  currentItems,
}) => {
  const minDate = 1800;
  const maxDate = new Date().getFullYear() + 5;

  const [showAdvancedFilter, setShowAdvancedFilter] = useState(true);
  const [inputFocused, setInputFocused] = useState(false);

  // Temporary state for filters
  const [tempFilterPerson, setTempFilterPerson] = useState({
    nationality: '',
    occupation: '',
    positionRole: '',
    address: '',
    postal_code: '',
    age: [minDate, maxDate],
  });

  // Temporary state for filters
  const [tempFilterCompany, setTempFilterCompany] = useState({
    title: '',
    company_status: '',
    company_number: '',
    company_type: '',
    nationality: '',
    postal_code: '',
    creationDateRange: [minDate, maxDate],
    cessationDateRange: [minDate, maxDate],
  });

  const toggleAdvancedFilter = e => {
    e.preventDefault();
    setShowAdvancedFilter(prev => !prev);
  };

  const resetAdvanceFiltersHandler = () => {
    resetAdvanceFilters();
    if (selectedType === 'People') {
      setTempFilterPerson({
        nationality: '',
        occupation: '',
        positionRole: '',
        address: '',
        postal_code: '',
        age: [minDate, maxDate],
      });
    }
    if (selectedType === 'Companies') {
      setTempFilterCompany({
        title: '',
        company_status: '',
        company_number: '',
        company_type: '',
        nationality: '',
        postal_code: '',
        creationDateRange: [minDate, maxDate],
        cessationDateRange: [minDate, maxDate],
      });
    }
  };

  useEffect(() => {
    resetAdvanceFilters();
    setShowAdvancedFilter(true);
  }, [selectedType]);

  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          ref={searchContainerRef}
          style={{
            width: '100%',
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {startIcon && !(inputValue.length > 0) && (
              <div
                className="input-icon"
                style={{
                  position: 'absolute',
                  left: '20px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                  color: selectedType ? '#000' : '#aaa',
                  fontSize: '15px',
                }}
              >
                {startIcon}
              </div>
            )}
            <input
              type="text"
              className={`cesearch-entites-search-input ${isDottedCardClicked ? 'placeholder-red' : ''}`}
              value={inputValue}
              placeholder={
                isDottedCardClicked
                  ? 'Select Entity Type above'
                  : selectedType || 'Enter name and apply filter as required'
              }
              onFocus={() => setInputFocused(true)}
              // onBlur={handleInputBlur}
              onBlur={() => setInputFocused(false)}
              onChange={handleChange}
              disabled={disabled}
              style={{
                paddingLeft:
                  startIcon && !(inputValue.length > 0) ? '35px' : '15px',
                border:
                  inputFocused && !disabled
                    ? '1px #029CBD solid'
                    : '1px #8A8A8A solid',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: '7px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'row',
                gap: '7px',
              }}
            >
              <div
                onClick={() => {
                  setInputValue('');
                  setSuggestions([]);
                  setSelectedSuggestions(selectedActualSuggestions);
                  setSelectedType(null);
                  setIsLimitReached(false);
                  setIsSearching(true);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  paddingRight: '8px',
                  borderRight: suggestions?.length > 0 ? '2px solid grey' : '',
                }}
              >
                {inputValue.length > 0 && (
                  <CloseIcon style={{ fontSize: 25, color: 'black' }} />
                )}
              </div>
              <div
                onClick={toggleAdvancedFilter}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {suggestions?.length > 0 ? (
                  <GradientIcon IconComponent={FilterAltIcon} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`cesearch-entites-advanced-filter-container ${!showAdvancedFilter ? 'show' : ''}`}
        >
          {!showAdvancedFilter && (
            <div className="cesearch-entites-advanced-filter">
              <div
                className="container"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <h2 className="advance-search-heading">
                  {selectedType === 'Person'
                    ? 'Advanced people Search'
                    : 'Advanced Companies Search'}
                </h2>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <button
                    className="ce-search-entities-close-button"
                    onClick={() => setShowAdvancedFilter(true)}
                  >
                    X
                  </button>
                  <button
                    className="ce-search-entities-close-button"
                    onClick={() => resetAdvanceFiltersHandler()}
                  >
                    Reset Filters
                  </button>
                </div>
              </div>
              <hr />
              <div className="cesearch-entites-advanced-filter-inner-container">
                {selectedType === 'Person' ? (
                  <CESAdvancedFilterPerson
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    tempFilterPerson={tempFilterPerson}
                    setTempFilterPerson={setTempFilterPerson}
                    suggestions={suggestions}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                ) : (
                  <CESAdvancedFilter
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    tempFilterCompany={tempFilterCompany}
                    setTempFilterCompany={setTempFilterCompany}
                    minCessationDate={minDate}
                    maxCessationDate={maxDate}
                    minCreationDate={minDate}
                    maxCreationDate={maxDate}
                    currentItems={currentItems}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CESearchEntitiesSection;
