import React, { useContext, useState } from 'react';
import { loginWithGoogle } from '../../api/login/loginWIthGoogle';
import { loginWithEmail } from '../../api/login/loginWithEmail';
import './Login.css';
import { AppContext } from '../../context/Context';
import showToast from '../../utils/showToast';
import { useAuth } from '../../context/AuthProvider';
import { useNavigate } from 'react-router';
import fingerPrintLogo from '../../assets/images/icons/fingerPrintLogo.png';
import googleIcon from '../../assets/images/icons/googleIcon.png';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

const Login = ({ selectedPlan, toggleForgotPassword }) => {
  const { user, setUser } = useContext(AppContext);
  const { logout } = useAuth();
  const { setIsLoading } = useLoading();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const validateForm = () => {
    let isValid = true;
    const newErrors = { email: '', password: '' };

    // Email validation
    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid';
      isValid = false;
    }

    // Password validation
    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleEmailLogin = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      setIsLoading(true);
      const user = await loginWithEmail(email, password);

      if (user?.success && user.firebaseUser?.is_payment_done === false) {
        showToast(
          'error',
          `Your payment is under process ${user?.firebaseUser?.plan}, Please wait.`
        );
        setIsLoading(false);
        await logout(false);
        return;
      }

      if (user?.success && user.firebaseUser?.is_payment_done === true) {
        setIsLoading(false);
        navigate('/services');
        return;
      }

      if (user === null) {
        setIsLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, email: 'No user found' }));
        return;
      }

      if (user?.success === false) {
        setIsLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, password: user?.error }));
        return;
      }
    } catch (error) {
      setIsLoading(false);
      setErrors(prevErrors => ({ ...prevErrors, password: error.message }));
    }
  };

  const handleGmailLogin = async () => {
    try {
      setIsLoading(true);
      const userRes = await loginWithGoogle(true, selectedPlan);

      if (userRes?.success && userRes.firebaseUser?.is_payment_done === false) {
        setIsLoading(false);
        setErrors(prevErrors => ({
          ...prevErrors,
          email: 'Your payment is under process',
        }));
        await logout(false);
        return;
      }

      if (userRes?.success && userRes.firebaseUser?.is_payment_done === true) {
        let updatedUser = {
          ...userRes?.user,
          FirebaseUser: userRes.firebaseUser,
        };
        setUser(updatedUser);
        navigate('/services');
        setIsLoading(false);
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: userRes?.error || 'Google login failed',
        }));
        await logout(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setErrors(prevErrors => ({ ...prevErrors, email: error.message }));
    }
  };

  return (
    <div className="probe-login-container">
      {/* Login Section */}
      <div className="probe-login-container-inner-div">
        <div className="probe-login-container-logo-div">
          <img
            className="probe-login-container-logo"
            src={fingerPrintLogo}
            alt="Logo"
          />
        </div>
        <h2 className="probe-login-title">Login</h2>

        {/* Email/Password Login */}
        <label
          style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
          htmlFor="email"
        >
          Email
        </label>
        <input
          type="email"
          className="probe-login-input"
          placeholder="Email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && handleEmailLogin()}
        />
        {errors.email && <p className="probe-login-error">{errors.email}</p>}

        <label
          style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
          htmlFor="password"
        >
          Password
        </label>
        <input
          type="password"
          className="probe-login-input"
          placeholder="Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          onKeyDown={e => e.key === 'Enter' && handleEmailLogin()}
        />
        {errors.password && (
          <p className="probe-login-error">{errors.password}</p>
        )}

        <div className="probe-login-other-options">
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input type="checkbox" style={{ marginRight: '8px' }} />
            Remember Me
          </label>
          <span
            onClick={() => {
              toggleForgotPassword();
            }}
            style={{ color: '#0380C7', cursor: 'pointer' }}
          >
            Forgot Password?
          </span>
        </div>

        <button onClick={handleEmailLogin}>Login</button>

        {/* Google Login */}
        <div className="google-auth-button" onClick={handleGmailLogin}>
          <img
            src={googleIcon}
            alt="logo"
            style={{ height: '22px', width: '22px', marginRight: '8px' }}
          />
          Sign in with Google
        </div>
      </div>
    </div>
  );
};

export default Login;
