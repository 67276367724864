import React, { useEffect, useState } from "react";
import "./QuickView.css";
import {
  calculateNetAssets,
  calculateYearsUntilNow,
  formatNumber,
  processRegisteredOfficeAddress,
} from "../../utils/helper";
import { corporateExplorerOfficerInfo } from "../../api/corporateExplorer/corporateExplorer";
import overview from '../../assets/images/company1.png';
import { capitalize } from "lodash";

const QuickView = ({
  visible,
  singleAccountsInfo,
  hoveredCompanyInfo,
  position
}) => {
  const [selectedOfficersInfo, setsetSelectedOfficersInfo] = useState([]);

  const OfficerList = ({ selectedOfficersInfo }) => {
    // Ensure selectedOfficersInfo is not undefined and is an array
    const validOfficersInfo = Array.isArray(selectedOfficersInfo)
      ? selectedOfficersInfo
      : [];

    if (validOfficersInfo.length === 0) {
      return <div></div>;
    }
    const firstTwoOfficers = validOfficersInfo.slice(0, 2);
    const remainingCount = Math.max(validOfficersInfo.length - 2, 0);

    return (
      <>
        <i className="bi bi-people-fill persons-icon"></i>
        <span>
          {firstTwoOfficers.map((officer, index) => (
            <span key={officer.person_number || index}>
              {" "}
              {/* Use person_number if available for a more stable key */}
              {officer.name}
              {index < firstTwoOfficers.length - 1 ? ", " : ""}
            </span>
          ))}
          {remainingCount > 0 && <span> &amp; {remainingCount} more</span>}
        </span>
      </>
    );
  };
  const getStatusStyle = (status) => {
    let style = {
      borderRadius: "4px",
      padding: "1px 5px",
      fontSize: "11px",
      fontWeight: "bold",
      textTransform: "uppercase",
      textAlign: "center",
      display: "inline-block",
      color: "#fff",
      whiteSpace: "nowrap",
      background: "",
    };

    if (status === "active" || status?.includes("active")) {
      style.background = "#16a086"; // Green color for active status
    } else if (status === "liquidation") {
      style.background = "#cc5834"; // Red color for reactive status
    } else if (status === "dormant") {
      style.background = "#8c8c8c"; // #8c8c8c color for reactive status
    } else if (status === "dissolved") {
      style.background = "#8B0000"; // #8c8c8c color for reactive status
    } else if (status === "in administration" || status === "administration") {
      style.background = "#cc5834";
    } else if (status === "resigned") {
      style.background = "gray";
    }

    return style;
  };

  useEffect(() => {
    const fetchOfficersInfo = async () => {
      try {
        const officersInfo = await corporateExplorerOfficerInfo(
          hoveredCompanyInfo?.company_number
        );
        setsetSelectedOfficersInfo(officersInfo);
      } catch (error) {
        setsetSelectedOfficersInfo([]);
      }
    };

    if (hoveredCompanyInfo) {
      fetchOfficersInfo();
    }
  }, [hoveredCompanyInfo]);

  return (
    <div
      id="quickview"
      style={{
        zIndex: 100,
        visibility: visible ? "visible" : "hidden",
        transition: "opacity 0.3s ease-in-out",
        opacity: visible ? 1 : 0,
        position: 'fixed',
        left: position?.x ? `${position.x}px` : '0',
        top: position?.y ? `${position.y + position.height + 10}px` : '0',
      }}
    >
      <div className="quickview-content">

        <span
          className={`top-right-badge${(hoveredCompanyInfo['Status'] === "d" || hoveredCompanyInfo['Status'] === "dissolved") ? "-dissolved" : ""
            }`}
        >
          {hoveredCompanyInfo['Status'] === "d"
            ? "Dissolved"
            : capitalize(hoveredCompanyInfo['Status'])}
        </span>

        <div className="corporate-explore-insight-company-Logo">
          <img src={overview} alt="Company Logo" className="company-logo" />
          <div className="corporate-explore-insight-company-Name">
            <a href="#" style={{ color: '#021B41' }}>
              {hoveredCompanyInfo['Name']}
            </a>
          </div>
          <div style={{ marginBottom: '0px', textAlign: 'center' }} className="corporate-explore-insight-company-Address">
            Company Number: {hoveredCompanyInfo['company_number']} | Since {hoveredCompanyInfo["Date of Creation"]}
          </div>{' '}
          <div style={{ textAlign: 'center' }} className="corporate-explore-insight-company-Address">
            {processRegisteredOfficeAddress(hoveredCompanyInfo)}
          </div>{' '}
        </div>

        {/* <div className="quickview-header">
          <i className="bi bi-building building-icon"></i>
          <span className="company-name">{hoveredCompanyInfo["Name"]}</span>
          <span className="address">
            {processRegisteredOfficeAddress(hoveredCompanyInfo)}
          </span>
        </div>
        <div className="items">
          <div className="item">
            <span
              className="status-green"
              style={getStatusStyle(hoveredCompanyInfo["Status"])}
            >
              {hoveredCompanyInfo["Status"]}
            </span>
          </div>
          <div className="item">
            <i className="bi bi-info-circle company-icon"></i>
            <span>Company No. {hoveredCompanyInfo["company_number"]}</span>
          </div>
          {hoveredCompanyInfo["Type"] && (
            <div className="item">
              <i className="bi bi-card-text type-icon"></i>
              <span>{hoveredCompanyInfo["Type"]}</span>
            </div>
          )}

          <div className="item">
            <i className="bi bi-calendar-date date-icon"></i>
            <span>
              {hoveredCompanyInfo["Date of Creation"]}
              <span style={{ color: "#777" }}>
                {" "}
                (
                {calculateYearsUntilNow(hoveredCompanyInfo["Date of Creation"])}
                )
              </span>
            </span>
          </div>
          <div className="item">
            {<OfficerList selectedOfficersInfo={selectedOfficersInfo} />}
          </div>
        </div> */}
        <div className="finance">
          <div className="finance-total">
            <span className="t3">Total Assets</span>
            <span className="t1">
              £{formatNumber(singleAccountsInfo?.total_assets)}
            </span>
          </div>
          <div>
            <span className="t3">Total Liab.</span>
            <span className="t1">
              £{formatNumber(singleAccountsInfo?.total_liabilities)}
            </span>
          </div>
          <div>
            <span className="t3">Net Assets</span>
            <span className="t1">
              £
              {calculateNetAssets(
                singleAccountsInfo?.total_assets,
                singleAccountsInfo?.total_liabilities
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickView;
