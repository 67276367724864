import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Confetti from 'react-confetti';

function PaymentSuccess() {
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    // Stop the confetti after 3 seconds
    const confettiTimer = setTimeout(() => setShowConfetti(false), 4000);

    // Wait for 3 seconds before navigating
    const navigationTimer = setTimeout(() => {
      navigate('/services');
    }, 4000);

    // Cleanup timers on component unmount
    return () => {
      clearTimeout(confettiTimer);
      clearTimeout(navigationTimer);
    };
  }, [navigate]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f8ff',
        fontFamily: 'Arial, sans-serif',
        textAlign: 'center',
        overflow: 'hidden', // Prevent confetti overflow
      }}
    >
      {/* {showConfetti && <Confetti />} */}
      <h1 style={{ color: '#4caf50', marginBottom: '20px' }}>
        Payment Successful!
      </h1>
      <p style={{ color: '#555', marginBottom: '30px' }}>
        Thank you for your payment. Your transaction was successful.
      </p>
      <p style={{ color: '#555', marginTop: '20px' }}>
        Redirecting to the Home Screen...
      </p>
    </div>
  );
}

export default PaymentSuccess;
