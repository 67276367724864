import React from 'react';
import './CEIPersonInsightsAppointments.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import appointmentIcon from '../../../../assets/images/icons/AppointmentIcon.png'

const CEIPersonInsightsAppointments = ({ appointments, details }) => {
  const ccjReport = detail => {
    if (detail?.details) {
      const baseUrl = window.location.origin;
      const address = encodeURIComponent(
        JSON.stringify(detail?.details?.address)
      );
      const intro = encodeURIComponent(JSON.stringify(detail?.intro));
      const newPath = '/ccj-dashboard/judgement-checker-person';
      const newUrl = `${baseUrl}${newPath}?intro=${intro}&address=${address}&auto-click=true`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <div className="corporate-explore-insight-appointments-container">
      <div className='corporate-explore-insight-appointments-header'>
        <h4 className="corporate-explore-insight-appointments-title">
          <img className="corporate-explore-insight-appointments-icon" src={appointmentIcon} alt="icon" />Appointments
        </h4>
        <button
          className="corporate-explore-insight-appointments-report-btn"
          onClick={() => ccjReport(details)}
        >
          <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />
          CCJ Report
        </button>
      </div>
      <div className="corporate-explore-insight-appointments-stats">
        <div className="corporate-explore-insight-appointments-stat">
          <span className='ce-stat-active'>Active</span>
          <h2>{appointments?.active}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className='ce-stat-resigned'>Resigned</span>
          <h2>{appointments?.resigned}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span className='ce-stat-dissolved'>Dissolved</span>
          <h2>{appointments?.dissolved}</h2>
        </div>
        {/* <div className="corporate-explore-insight-appointments-stat">
          <span>Other</span>
          <h2>{appointments?.other}</h2>
        </div>
        <div className="corporate-explore-insight-appointments-stat">
          <span>Total</span>
          <h2>{appointments?.total}</h2>
        </div> */}
      </div>
    </div>
  );
};

export default CEIPersonInsightsAppointments;
