import React, { useState } from 'react';
import './CompanyItem.css';
import {
  nationalityToFlag,
  statusDivCard,
  GradientIcon,
} from '../../../../utils/helper';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LanguageIcon from '@mui/icons-material/Language';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

const CompanyItem = ({ company, isCardView, isSelected, removeItem }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isNeedActive = !company.company_status && company.need >= 20;

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const formatOfficers = officers => {
    if (officers?.length === 0) return null;
    if (officers?.length === 1) {
      return officers[0]?.name;
    } else {
      return (
        <>
          {officers[0]?.name}
          &nbsp;
          <a href="#">& {officers?.length - 1} others </a>
        </>
      );
    }
  };

  return (
    <div
      className={`corporate-insights-result-card ${isSelected ? 'CompanyItem-selected-card' : ''}`}
    >
      <div className="corporate-insights-result-card-content">
        <div>
          <div className="corporate-insights-result-card-content-header">
            <div className="corporate-insights-result-card-header-container">
              <div className="corporate-insights-result-card-header-left">
                <div>
                  <GradientIcon IconComponent={ApartmentIcon} />
                  <span className="header-name">{company?.title}</span>
                </div>
              </div>
              {!removeItem && (
                <div className="corporate-insights-result-card-header-right">
                  <GradientIcon IconComponent={LinkedInIcon} />
                  <GradientIcon IconComponent={LanguageIcon} />
                </div>
              )}
              {removeItem && (
                <div className="corporate-insights-result-card-header-right">
                  <CloseIcon
                    onClick={() => removeItem(company?.title)}
                    style={{ fontSize: 18, color: 'black' }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="corporate-insights-result-card-body">
            {!isExpanded && (
              <div>
                <div className="corporate-insights-result-card-info-row">
                  <span className="corporate-insights-result-card-value">
                    {company?.address?.length > 50
                      ? `${company.address.slice(0, 50)}...`
                      : company?.address}
                  </span>
                </div>
                <div className="corporate-insights-result-card-info-row">
                  <span className="corporate-insights-result-card-value">
                    {company?.company_number}
                  </span>
                </div>
              </div>
            )}

            {isExpanded && (
              <div>
                {company.title && (
                  <div className="corporate-insights-result-card-row">
                    <span className="corporate-insights-result-card-info">
                      Company
                    </span>
                    <span className="corporate-insights-result-card-info">
                      {company.title}
                    </span>
                  </div>
                )}

                {company.company_number && (
                  <div className="corporate-insights-result-card-row">
                    <span className="corporate-insights-result-card-info">
                      Company Number
                    </span>
                    <span className="corporate-insights-result-card-info">
                      {company.company_number}
                    </span>
                  </div>
                )}

                {company.company_status && (
                  <div className="corporate-insights-result-card-row">
                    <span className="corporate-insights-result-card-info">
                      Company Status
                    </span>
                    <span className="corporate-insights-result-card-info">
                      {company.company_status}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="corporate-insights-result-card-footer">
          <div className="corporate-insights-result-card-info-row">
            <span
              className={`corporate-insights-result-card-value ${
                isNeedActive
                  ? 'corporate-insights-result-card-status-active'
                  : 'corporate-insights-result-card-status-inactive'
              }`}
            >
              {statusDivCard(company?.company_status)}
              {isNeedActive && (
                <span className="need-indicator"> (Need 20%)</span>
              )}
            </span>
          </div>
          <button
            className="corporate-insights-result-card-toggle-button"
            onClick={handleToggle}
          >
            {isExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyItem;
