import '../CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import '../JudgementChecker.css';
import '../CheckReviewPerson/CheckReviewPerson.css';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import headerLine from '../../../assets/images/icons/header-line.svg';
import editIcon from '../../../assets/images/ic_sharp-edit.svg';
import removeIcon from '../../../assets/images/fluent_delete-24-filled.svg';
import Registers from '../common/Registers';
import Payment from '../common/Payment';
import { AppContext } from '../../../context/Context';
import React, { useContext, useEffect } from 'react';
import { createCheckoutSession } from '../../../api/judgementChecker/judgementChecker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { constructSearchData, continueToCheckOut } from '../../../utils/helper';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import { useState } from 'react';
import OneFinalCheck from '../../../components/OneFinalCheck/OneFinalCheck';
import { triggerGoogleAnalyticsEvent } from '../../../utils/googleAnalyticsEvent';

function CheckReviewBusiness() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const { judgementChecker, setJudgementChecker, user } =
    useContext(AppContext);
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [linkedToSearch, setLinkedToSearch] = useState(true);
  const [avaibleViaTrust, setAvaibleViaTrust] = useState(true);

  const checkOutPayment = async () => {
    if (!privacyPolicy || !linkedToSearch || !avaibleViaTrust) return;
    await continueToCheckOut(
      setIsLoading,
      createCheckoutSession,
      judgementChecker,
      user
    );
  };

  // going to get your details.
  const continueToGetYourDetail = async () => {
    navigate('/ccj-dashboard/judgement-checker-your-detail');
  };

  const editReport = () => {
    navigate('/ccj-dashboard/judgement-checker-business');
  };

  const removeReport = () => {
    navigate('/ccj-dashboard/judgement-checker');
  };

  const checkoutButtonClicked = () => {
    debugger;
    triggerGoogleAnalyticsEvent('payments', 'checkout_button_clicked');
    const next =
      user && !user?.guest ? checkOutPayment : continueToGetYourDetail;
    next();
  };

  const autoComplete = () => {
    const autoClick = searchParams.get('auto-click') === 'true';
    return autoClick;
  };

  useEffect(() => {
    if (judgementChecker && judgementChecker.businessJudgementChecker) {
      const data = constructSearchData(
        judgementChecker.businessJudgementChecker
      );

      setJudgementChecker(prev => ({
        ...prev,
        businessJudgementChecker: {
          ...prev.businessJudgementChecker,
          JudgementChecker: data,
          type: 'BusinessSearch',
        },
      }));
    }
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="dashboard-content-div">
          <div className="first-parallel-div">
            <div className="cards-div card-1">
              {judgementChecker &&
                judgementChecker.businessJudgementChecker && (
                  <>
                    <div className="card-headers button-text">
                      <img src={headerLine} />
                      {judgementChecker.businessJudgementChecker.companyName} -
                      £8.50
                    </div>
                    <div className="content-div">
                      {judgementChecker.businessJudgementChecker
                        .companyName && (
                        <div className="mb-4">
                          <div className="button-text mb-2">
                            Business(es) to be searched
                          </div>
                          <div className="body-text-normal">
                            {
                              judgementChecker.businessJudgementChecker
                                .companyName
                            }
                          </div>
                        </div>
                      )}
                      {judgementChecker.businessJudgementChecker.address && (
                        <div className="mb-4">
                          <div className="button-text mb-2">
                            Address(es) to be searched
                          </div>
                          <div className="body-text-normal">
                            <div>100 KING STREET</div>
                            <div>MANCHESTER</div>
                            <div>M2 4WU</div>
                          </div>
                        </div>
                      )}
                      {/* Register(s) to be searched */}
                      {judgementChecker.businessJudgementChecker
                        .selectedRegisters && (
                        <Registers
                          probe={judgementChecker.businessJudgementChecker}
                        />
                      )}
                    </div>
                  </>
                )}

              {!autoComplete && (
                <div className="edit-remove-btn-div">
                  <div>
                    <button className="edit-btn" onClick={editReport}>
                      <img src={editIcon} className="mx-1" /> Edit
                    </button>
                  </div>
                  <div>
                    <button className="remove-btn" onClick={removeReport}>
                      <img src={removeIcon} className="mx-1" /> Remove
                    </button>
                  </div>
                </div>
              )}
            </div>

            {/* payment system */}
            <div>
              <Payment />
              {user && !user?.guest && (
                <div style={{ width: '100%', marginTop: '20px' }}>
                  <OneFinalCheck
                    setPrivacyPolicy={setPrivacyPolicy}
                    setAvaibleViaTrust={setAvaibleViaTrust}
                    setLinkedToSearch={setLinkedToSearch}
                    avaibleViaTrust={avaibleViaTrust}
                    linkedToSearch={linkedToSearch}
                    privacyPolicy={privacyPolicy}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <ProbeButton
              name={user && !user?.guest ? 'Checkout' : 'Continue'}
              backgroundColor={'#0466D1'}
              borderRadius={'10px'}
              borderColor={'#blue'}
              height={'60px'}
              width={'300px'}
              onClick={checkoutButtonClicked}
              fontColor="#FFF"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckReviewBusiness;
