import React, { useState, useContext, useEffect } from 'react';
import downloadPdfImage from '../../../assets/images/download_pdf.png';
import '../JudgmentCheckerReport/JudgmentCheckerReport';
import '../JudgementChecker.css';
import '../CheckReviewPerson/JudgementCheckerPerson/JudgementCheckerPerson.css';
import '../CheckReviewPerson/SearchOnPerson/SearchOnPerson.css';
import '../CheckReviewPerson/CheckReviewPerson.css';
import { AppContext } from '../../../context/Context';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import editIcon from '../../../assets/images/ic_sharp-edit.svg';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import JudgmentReport from './Report/JudgmentReport';
import { useNavigate } from 'react-router-dom';
import {
  convertBlobUrlToBase64,
  generateUniqueReferenceNumber,
  getGuestUserInfo,
} from '../../../utils/helper';
import {
  generatePdf,
  getPaymentSessionStatus,
  postJudgementCheckerInfo,
  postJudgementCheckerPdfUpload,
  processRefund,
  raiseNotification,
  sendEmail,
} from '../../../api/judgementChecker/judgementChecker';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import showToast from '../../../utils/showToast';
import { determineJudgmentCheckerType } from '../../../utils/helper';
import LocalStorageService from '../../../utils/LocalStorageServices';
import { firebaseAuth } from '../../../context/firebaseContext/firebase';

const JudgmentCheckerReport = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const [caseRecordCount, setCaseRecordCount] = useState(null);
  const [uniqueReferenceNumber, setUniqueReferenceNumber] = useState(null);
  const { user, setUser } = useContext(AppContext);
  const [saveJC, setJC] = useState(null);
  const [isSavedIntoDB, setIsSavedIntoDB] = useState(false);
  const [judgementCheckerType, setJudgementCheckerType] = useState('');
  const [paidJudgementChecker, setPaidJudgmenetChecker] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [saveSessionId, setSaveSessionId] = useState(null);
  const [guestUserInfo, setGuestUserInfo] = useState(null);

  const notificationStatuses = {
    SESSION_NOT_FOUND: {
      title: 'Session not found',
      detail: (sessionId, userId, accountId) =>
        `The requested session with ID ${sessionId} could not be located in the system for user ID ${userId} and account ID ${accountId}. Please check the session ID and try again.`,
    },
    TRUST_ONLINE_API_DOWN: {
      title: 'Trust Online API is down',
      detail: (sessionId, userId, accountId) =>
        `The Trust Online API is currently unavailable. This issue affects user ID ${userId}, session ID ${sessionId}, and account ID ${accountId}. Please try again later.`,
    },
    PDF_NOT_GENERATED: {
      title: 'PDF not generated',
      detail: (sessionId, userId, accountId) =>
        `There was an issue in generating the PDF document for session ID ${sessionId}, user ID ${userId}, and account ID ${accountId}. Please ensure all required data is correct and try again.`,
    },
    BLOB_URL_CONVERSION_FAILED: {
      title: 'Not able to convert Blob URL to Base64',
      detail: (sessionId, userId, accountId) =>
        `Failed to convert the Blob URL to Base64 format for session ID ${sessionId}, user ID ${userId}, and account ID ${accountId}. This may be due to an invalid URL or data format.`,
    },
    PDF_SAVE_FAILURE: {
      title: 'Not able to save PDF into database',
      detail: (sessionId, userId, accountId) =>
        `Encountered an issue while saving the PDF document into the database for session ID ${sessionId}, user ID ${userId}, and account ID ${accountId}. Please check the database connectivity and permissions.`,
    },
  };

  const errorNotificationNav = (toastMessage = 'Contect Support') => {
    setIsLoading(false);
    showToast('error', toastMessage);
    LocalStorageService.removeItem('payment_session_id');
    navigate('/ccj-dashboard/judgement-checker');
  };

  const generatePDF = async () => {
    // Create a temporary anchor element
    const link = document.createElement('a');
    // Set the href to the Blob URL
    link.href = pdfUrl;
    // Set the download attribute with a filename for the PDF
    link.download = 'report.pdf';
    // Append the link to the body
    document.body.appendChild(link);
    // Programmatically click the link to trigger the download
    link.click();
    // Remove the link from the body
    document.body.removeChild(link);
  };

  const handleRefundProcess = async (failureType, session_id, user, data) => {
    let refund = await processRefund(session_id, user?.FirebaseUser?.user_id);

    if (refund.success) {
      showToast('success', refund?.data?.message);
    } else {
      showToast('error', 'Refund was not successfull');
    }

    await raiseNotification({
      title: failureType.title,
      description: failureType.detail(
        session_id,
        user?.FirebaseUser?.user_id,
        user?.Account?.Uid,
        data && { detail: data }
      ),
      entityType: 1,
      userAccountId: user?.Account?.Uid,
      userId: user?.FirebaseUser?.user_id,
    });

    errorNotificationNav('Judgment Service is down');
    return;
  };

  const handlePaymentSessionStatus = async (session_id, user) => {
    let responseFromPaymentSessionStatusApi = await getPaymentSessionStatus(
      session_id,
      user?.FirebaseUser?.user_id
    );

    if (!responseFromPaymentSessionStatusApi.success) {
      // Raise notification
      await raiseNotification({
        title: notificationStatuses.SESSION_NOT_FOUND.title,
        description: notificationStatuses.SESSION_NOT_FOUND.detail(
          session_id,
          user?.FirebaseUser?.user_id,
          user?.Account?.Uid
        ),
        entityType: 1,
        userAccountId: user?.Account?.Uid,
        userId: user?.FirebaseUser?.user_id,
      });
      showToast('error', 'Session not found');
      LocalStorageService.removeItem('payment_session_id');
      navigate('/ccj-dashboard/judgement-checker');
      return;
    }
    return responseFromPaymentSessionStatusApi;
  };

  const handleSetupStateForPaidJudgmentChecker = (
    paidJudgementChecker,
    uniqueReferenceNumber
  ) => {
    try {
      if (paidJudgementChecker && uniqueReferenceNumber) {
        setJudgementCheckerType(paidJudgementChecker.type);
        let jtd = paidJudgementChecker?.JudgementChecker;
        setJC({
          judgmentCheckerType: paidJudgementChecker,
          uniqueReferenceNumber: uniqueReferenceNumber,
          ...(guestUserInfo && { guestUserInfo: guestUserInfo }),
        });
        setCaseRecordCount(jtd?.registrationCount);
      }
    } catch (error) {
      errorNotificationNav();
    }
  };

  const handlePdfGenerateFromBackend = async (saveJC, user, saveSessionId) => {
    let response = await generatePdf(saveJC);
    if (!response.success) {
      // Raise notification
      await handleRefundProcess(
        notificationStatuses.PDF_NOT_GENERATED,
        saveSessionId,
        user,
        saveJC
      );
      showToast('error', 'Pdf not generated');
      navigate('/ccj-dashboard/judgement-checker');
      return;
    }

    return response;
  };

  const handleConvertBlobUrlToBase64 = async (
    response,
    user,
    saveSessionId,
    saveJC
  ) => {
    let pdfBase64 = await convertBlobUrlToBase64(response.pdfUrl);
    if (!pdfBase64.success && !pdfBase64.data) {
      // Raise notification

      await handleRefundProcess(
        notificationStatuses.BLOB_URL_CONVERSION_FAILED,
        saveSessionId,
        user,
        saveJC
      );
      showToast('error', pdfBase64.message);
      LocalStorageService.removeItem('payment_session_id');
      navigate('/ccj-dashboard/judgement-checker');
      return;
    }
    return pdfBase64;
  };

  const sendEmailToGuestUser = async (info, pdfUrl, uniqueReferenceNumber) => {
    let response = await sendEmail(info, pdfUrl, uniqueReferenceNumber);

    if (!response.success) {
      showToast('error', 'Not able to send email, contact support');
      return;
    }
    showToast(
      'success',
      `Report sent to ${info?.guestUserInfo?.emailaddress} account`
    );
  };

  const handlePostJudgementCheckerPdfUpload = async (
    response,
    pdfBase64,
    saveJC,
    user,
    saveSessionId
  ) => {
    showToast('info', 'Saving PDF for you');

    let saveIntoDatabaseResponse = await postJudgementCheckerPdfUpload(
      user.FirebaseUser?.user_id,
      pdfBase64.data,
      saveJC
    );

    if (saveIntoDatabaseResponse.success) {
      if (user?.guest) {
        await sendEmailToGuestUser(
          saveJC,
          saveIntoDatabaseResponse?.data?.pdf_path,
          uniqueReferenceNumber
        );
      }

      setPdfUrl(response.pdfUrl);
      setIsSavedIntoDB(true);
      showToast('success', 'PDF Saved');
      LocalStorageService.removeItem('payment_session_id');
    } else {
      // Raise notification
      showToast('error', 'Not able to save PDF');
      LocalStorageService.removeItem('payment_session_id');
      setIsSavedIntoDB(false);
      setPdfUrl(null);
      // navigate("/ccj-orders-fines");
    }
  };

  const handlePostJudgmentCheckerInfo = async (jdgType, session_id, user) => {
    //call api judgement checker here
    const responseFromJudgmentCheckerOnline = await postJudgementCheckerInfo(
      jdgType?.JudgementChecker,
      showToast
    );
    if (!responseFromJudgmentCheckerOnline.success) {
      // Raise notification
      await handleRefundProcess(
        notificationStatuses.TRUST_ONLINE_API_DOWN,
        session_id,
        user
      );
    }

    return responseFromJudgmentCheckerOnline;
  };

  const setIfUserIsNullAssumeDummyUser = session_id => {
    if (session_id !== null && user === null) {
      setUser(getGuestUserInfo());
      return true;
    }
    return false;
  };

  useEffect(() => {
    try {
      const generatePdfFromApi = async () => {
        if (
          caseRecordCount !== null &&
          saveJC?.uniqueReferenceNumber !== null &&
          isSavedIntoDB !== true
        ) {
          if (saveJC.judgmentCheckerType.JudgementChecker) {
            let response = await handlePdfGenerateFromBackend(
              saveJC,
              user,
              saveSessionId
            );

            let pdfBase64 = await handleConvertBlobUrlToBase64(
              response,
              user,
              saveSessionId,
              saveJC
            );
            await handlePostJudgementCheckerPdfUpload(
              response,
              pdfBase64,
              saveJC,
              user,
              saveSessionId
            );
          }
        }
      };

      generatePdfFromApi();
    } catch (error) {
      errorNotificationNav();
    }
  }, [caseRecordCount, saveJC]);

  useEffect(() => {
    handleSetupStateForPaidJudgmentChecker(
      paidJudgementChecker,
      uniqueReferenceNumber
    );
  }, [paidJudgementChecker]);

  useEffect(() => {
    if (user) {
      try {
        const getSession = async () => {
          setIsLoading(true);
          let session_id = LocalStorageService.getItem('payment_session_id');
          setSaveSessionId(session_id);
          if (user && session_id) {
            showToast('info', 'Fetching infromation ...');

            let responseFromPaymentSessionStatusApi =
              await handlePaymentSessionStatus(session_id, user);

            if (responseFromPaymentSessionStatusApi?.data?.user_information) {
              setGuestUserInfo(
                responseFromPaymentSessionStatusApi?.data?.user_information
              );
            }

            let jdgType = determineJudgmentCheckerType(
              responseFromPaymentSessionStatusApi?.data
            );

            //call api judgement checker here
            const responseFromJudgmentCheckerOnline =
              await handlePostJudgmentCheckerInfo(jdgType, session_id, user);

            jdgType['JudgementChecker'] =
              responseFromJudgmentCheckerOnline.data;
            setPaidJudgmenetChecker(jdgType);
          } else {
            setIsLoading(false);
            showToast('error', 'Session not created');
            navigate('/ccj-dashboard/judgement-checker');
          }
        };
        getSession();
      } catch (error) {
        setIsLoading(false);
        showToast('error', 'Contect Support');
        navigate('/ccj-dashboard/judgement-checker');
      }
    }
  }, [user]);

  useEffect(() => {
    if (isSavedIntoDB) {
      setIsLoading(false);
    }
  }, [isSavedIntoDB]);

  useEffect(() => {
    try {
      let session_id = LocalStorageService.getItem('payment_session_id');
      if (session_id === null) {
        navigate('/ccj-dashboard/judgement-checker');
        return;
      }

      setUniqueReferenceNumber(generateUniqueReferenceNumber());
    } catch (error) {
      navigate('/ccj-dashboard/judgement-checker');
      return;
    }
  }, []);

  return (
    <div className="dashboard-section">
      <div className="main-div">
        <div className="dashboard-content-div" style={{ width: '100%' }}>
          <div className="first-parallel-div">
            <div
              className="cards-div"
              style={{
                width: '100%',
              }}
            >
              <>
                <div
                  className="card-headers button-text"
                  style={{ display: 'flex', width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      <div className="header-heading dark-heading-color">
                        You Results
                      </div>
                      <span
                        style={{
                          color: '#212529',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '22px',
                        }}
                      >
                        Thank you for completing searches on Judgement & Finding
                      </span>
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                      <img
                        src={downloadPdfImage}
                        alt="Download Pdf"
                        onClick={generatePDF}
                        height={50}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: '#FBF9F1',
                          padding: '5px',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-div"
                  style={{
                    height: '50vh',
                    overflow: 'auto',
                    backgroundColor: '#fbf8f3',
                  }}
                >
                  {pdfUrl !== null && <JudgmentReport pdfUrl={pdfUrl} />}
                </div>
              </>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <ProbeButton
              name={'Back to Home'}
              backgroundColor={'#0466D1'}
              borderRadius={'10px'}
              borderColor={'#blue'}
              height={'60px'}
              width={'237px'}
              onClick={() => navigate('/ccj-dashboard/judgement-checker')}
              fontColor="#FFF"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JudgmentCheckerReport;
