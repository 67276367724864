import { useContext, useEffect, useState } from 'react';
import './UserProfileUpdatePassword.css';
import EditIcon from '../../assets/images/icons/EditIcon.png';
import { AppContext } from '../../context/Context';
import { updatePassword } from 'firebase/auth';
import { loginWithEmail } from '../../api/login/loginWithEmail';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import showToast from '../../utils/showToast';

const UserProfileUpdatePassword = () => {
  const { user, setUser } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
    newPassword: '',
    confirmPassword: '',
  });

  const [initialFormData, setInitialFormData] = useState(formData);

  const handleChange = e => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
    setIsFormDirty(true);
  };

  const handleEdit = () => {
    setInitialFormData(formData); // Store current state before editing
    setIsEditing(true);
  };

  const handleCancel = () => {
    setFormData(initialFormData); // Reset form data to original state
    setIsEditing(false);
    setIsFormDirty(false); // Reset form dirty state
  };

  const updatePasswordForUser = async () => {
    setIsLoading(true);
    try {
      // Check if all fields are filled
      if (!formData.newPassword || !formData.confirmPassword) {
        showToast('error', 'Please fill in all fields');
        setIsLoading(false);
        return;
      }
      const userInfo = await loginWithEmail(user?.email, formData.password);

      if (!userInfo.success) {
        showToast('error', 'Old Password is incorrent');
        setIsLoading(false);
        return;
      }

      if (userInfo.success && formData.newPassword.length > 0 && firebaseAuth) {
        // Validate new password and confirm password
        if (formData.newPassword.length < 8) {
          showToast('error', 'New password must be at least 8 characters long');
          setIsLoading(false);
          return;
        }

        if (formData.newPassword !== formData.confirmPassword) {
          showToast('error', 'New password and confirm password do not match');
          setIsLoading(false);
          return;
        }

        // Check if all fields are filled
        if (!formData.newPassword || !formData.confirmPassword) {
          showToast('error', 'Please fill in all fields');
          setIsLoading(false);
          return;
        }

        // update password here
        await updatePassword(firebaseAuth.currentUser, formData.newPassword);
        showToast('success', 'password updated');
        setIsLoading(false);
      }
    } catch (error) {
      showToast('error', 'Password update service is down');
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData(prevData => ({
        ...prevData,
        username: user?.displayName,
      }));
    }
  }, [user]);

  return (
    <div className="up-update-password-container">
      <div className="up-header">
        <div>
          <h2 className="user-profile-dashboard-title">
            Username and Password
          </h2>
          <p style={{ fontWeight: 'normal', fontSize: '13px' }}>
            Please enter your current password to change your password.
          </p>
        </div>
        {!isEditing && (
          <button className="up-edit-button top" onClick={handleEdit}>
            <img style={{ marginRight: '8px' }} src={EditIcon} alt="Edit" />
            Edit
          </button>
        )}
      </div>
      <hr style={{ marginTop: '0' }} />

      <div className="up-input-group">
        <label>Current username</label>
        <input
          type="text"
          name="username"
          value={formData.username}
          onChange={handleChange}
          disabled={true}
        />
      </div>

      <div className="up-input-group">
        <label>Current password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          disabled={!isEditing}
          placeholder="Enter current password"
        />
      </div>

      {isEditing &&
        <>
          <hr />
          <div className="up-input-group">
            <label>New password</label>
            <div style={{ width: '100%' }}>
              <input
                type="password"
                name="newPassword"
                value={formData.newPassword}
                onChange={handleChange}
                placeholder="Enter new password"
              />
              <p style={{ margin: '0' }}>
                Your new password must be more than 8 characters.
              </p>
            </div>
          </div>

          <div className="up-input-group">
            <label>Confirm password</label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm new password"
            />
          </div>
          <hr />
        </>
      }

      <div className="up-button-group">
        {isEditing &&
          <button className="up-cancel-button" onClick={handleCancel}>
            Cancel
          </button>
        }
        {isEditing &&
          <button className={`up-save-button ${!isFormDirty || JSON.stringify(formData) === JSON.stringify(initialFormData) ? 'disabled' : ''}`}
            onClick={updatePasswordForUser}>
            Update Password
          </button>
        }
        {!isEditing && (
          <button className="up-edit-button bottom" onClick={handleEdit}>
            <img style={{ marginRight: '8px' }} src={EditIcon} alt="Edit" />
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default UserProfileUpdatePassword;
