import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CESearchEntities.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import saveIcon from '../../../assets/images/icons/SaveIcon.png';
import personBlack from '../../../assets/images/icons/PersonBlack.png';
import CompanyBlack from '../../../assets/images/icons/CompanyBlack.png';
import crossColor from '../../../assets/images/icons/crossColor.png';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import PeopleAndContactItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/PeopleAndContactItem';
import {
  calculateAge,
  calculateYearsUntilNow,
  debounce,
  isValidJSON,
  processRegisteredOfficeAddress,
  processRegisteredOfficeAddressObject,
} from '../../../utils/helper';
import {
  explorerFilter,
  explorerFilterOfficers,
  getNetworkGraphQueryByUserId,
  getRelationShips,
  saveNetworkGraphQueryByUserId,
} from '../../../api/corporateExplorer/corporateExplorer';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import ExploreLinksOfficerCard from '../../../components/ExploreLinksOfficerCard/ExploreLinksOfficerCard';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import loadingGif from '../../../assets/images/loading.gif';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/Context';

import CLGrapgLeftSidebarItemHolder from './../../../components/CorporateLinks/CLGraphLeftSidebar/CLGrapgLeftSidebarItemHolder/CLGrapgLeftSidebarItemHolder';
import GraphButton from '../../../components/GraphButton/GraphButton';
import RightSidebarMenu from '../../../components/GraphRightSideBar/GraphRightSideBar';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import CESearchEntitiesFlowSection from './CESearchEntitiesFlowSection';
import LocalStorageService from '../../../utils/LocalStorageServices';
import URLService from '../../../utils/URLService';
import NGTaskStatus from '../../../components/NGTaskStatus/NGTaskStatus';

import { apiConfig, buildUrl } from '../../../api/apiConfig';
import axios from 'axios';

const CESearchEntities = () => {
  const navigate = useNavigate();
  const {
    clGraph,
    setClGraph,
    user,
    corporateLinksAdvanceFilters,
    setCorporateLinksAdvanceFilters,
    setCorporateLinksDynamicFilters,
    setNgTaskId,
    setNgTasks,
  } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [defaultSuggestions, setDefaultSuggestions] = useState([]);
  const [selectedActualSuggestions, setSelectedActualSuggestions] = useState(
    []
  );
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const searchContainerRef = useRef(null);
  const [graphForTest, setGraphForTest] = useState(null);
  const [hideButtonsAndSearch, setHideButtonsAndSearch] = useState(false);
  const [isSectionVisible, setIsSectionVisible] = useState(true);
  const [queryHistory, setQueryHistory] = useState(null);
  const [isOpenSideBar, setIsOpenSidebar] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [totalOfficersAndCompanies, setTotalOfficersAndCompanies] =
    useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  // Function to handle selecting an item
  const setHistoricSuggestion = (historyItem, index) => {
    // Update the selected index on click
    setSelectedSuggestions(historyItem?.query || []);
    setSelectedActualSuggestions(historyItem?.query || []);
    // Perform your logic with the clicked item
  };
  const getCombinationTypeAndIds = selectedSuggestions => {
    if (!selectedSuggestions || selectedSuggestions.length === 0) {
      return { combinationType: null, ids: [] };
    }

    const types = selectedSuggestions.map(suggestion => suggestion.type);
    const ids = selectedSuggestions.map(suggestion => {
      if (suggestion.type === 'Company') {
        return { type: 'company', id: suggestion.item['Company Number'] };
      } else if (suggestion.type === 'Officer') {
        return { type: 'officer', id: suggestion.item['officer_id'] };
      }
      return null;
    });

    // Remove null or empty ids
    const validIds = ids.filter(idObj => idObj && idObj.id);

    let combinationType = null;

    if (types.length === 1) {
      combinationType = types[0].toLowerCase();
    } else if (types.includes('Officer') && types.includes('Company')) {
      combinationType = 'officer-to-company';
    } else if (types.every(type => type === 'Officer')) {
      combinationType = 'officer-to-officer';
    } else if (types.every(type => type === 'Company')) {
      combinationType = 'company-to-company';
    }

    return { combinationType, ids: validIds };
  };

  const handleButtonClick = type => {
    setSuggestions([]);
    setInputValue('');
    setSelectedType(type);
    setIsOpen(true);
    setIsLoadingPage(false);
    setTotalOfficersAndCompanies(null);
  };

  // this generate graphs and send ser to graph page
  const handleGraphPage = async () => {
    try {
      setIsLoading(true);
      let combination = getCombinationTypeAndIds(selectedSuggestions);
      if (user && user?.FirebaseUser && user?.FirebaseUser?.user_id) {
        combination['userId'] = user?.FirebaseUser?.user_id;
      }
      let nodeGraph = await getRelationShips(combination);
      if (!nodeGraph?.status) {
        setIsLoading(false);
        return;
      }

      setNgTaskId(nodeGraph?.taskId);

      setClGraph(previousState => ({
        ...previousState,
        selectedSuggestions: selectedSuggestions,
        selectedActualSuggestions: selectedActualSuggestions,
        graph: nodeGraph?.result?.graphs,
        combination: combination,
        isOpenRightSidebar: false,
        selectedGraph: null,
        shareholders: null,
      }));

      if (!user?.hasOwnProperty('guest')) {
        saveNetworkGraphQueryByUserId({
          userId: user?.FirebaseUser?.user_id,
          query: selectedActualSuggestions,
        });
      }

      setIsLoading(false);
      navigate(
        `/cl-dashboard/cl-graph?selectedActualSuggestions=${JSON.stringify(selectedActualSuggestions)}`
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInputFocus = () => {
    setIsOpen(true);
  };

  const handleClearAll = async () => {
    setIsLoading(true);

    const url = buildUrl(
      apiConfig.base,
      apiConfig.corporateLinks.endpoints.deleteAllNetworkGraphQueries,
      {},
      { userId: user?.FirebaseUser?.user_id }
    );

    try {
      const response = await axios.delete(url);
      setQueryHistory(null);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      console.error('Error clearing network graph queries:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setIsLoading(false);
    }
  };

  const handleDeleteHistoryItem = async queryId => {
    setIsLoading(true);

    const url = buildUrl(
      apiConfig.base,
      apiConfig.corporateLinks.endpoints.deleteNetworkGraphQueryById,
      {},
      {
        userId: user?.FirebaseUser?.user_id,
        queryId: queryId,
      }
    );

    try {
      const response = await axios.delete(url);
      setQueryHistory(prevHistory =>
        prevHistory.filter(item => item.id !== queryId)
      );

      setIsLoading(false);
      return response.data;
    } catch (error) {
      console.error('Error deleting network graph query:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      setIsLoading(false);
    }
  };

  const handleInputBlur = () => {
    // Allow the dropdown to remain open when focusing on buttons
    setTimeout(() => {
      if (
        !document.activeElement.classList.contains(
          'cesearch-entites-dropdown-button'
        )
      ) {
        // setIsOpen(false);
      }
    }, 300);
  };

  const handleChange = event => {
    let { value } = event.target;
    setInputValue(value);
    value = value.trim().toLowerCase(); // Convert the value to lowercase and trim spaces
  };

  const handleConfirm = () => {
    // Perform the search only if there's input
    if (isSearching) {
      if (inputValue === '') {
        setSuggestions([]);
        setTotalOfficersAndCompanies(null);
        setIsSearching(true);
        return;
      }

      if (selectedType === 'Person') {
        debouncedSearchOfficerByName(inputValue);
      } else if (selectedType === 'Company') {
        debouncedSearchCompanyByName(inputValue);
      }
    } else {
      setSelectedActualSuggestions(selectedSuggestions);
      setInputValue('');
      setSuggestions([]);
      setIsSearching(true);
      setSelectedType(null);
    }
  };

  // const handleCardClick = () => {
  //   setIsSectionVisible(false);
  // };
  const itemClicked = (type, selectedItem) => {
    setIsSectionVisible(false);
    if (selectedSuggestions?.length >= 2) {
      setIsLimitReached(true);
      return;
    }
    if (type === 'Company') {
      let companyObj = mapCompanyObject(selectedItem);
      updateSuggestions(companyObj);
    } else {
      let officerObj = mapOfficerObject(selectedItem);
      updateSuggestions(officerObj);
    }
    setHideButtonsAndSearch(true);
  };

  const mapCompanyObject = original => {
    return {
      type: 'Company',
      // subType: `Company House ${original?.title} informaton`,
      // title: original?.title,
      // address: `${original?.addressObj.addressOne}, ${original?.addressObj?.locality}, ${original?.addressObj?.country}`,
      item: {
        Status:
          original?.company_status?.charAt(0)?.toUpperCase() +
          original?.company_status?.slice(1),
        Type: 'LTD',
        Incorporated: {
          main: original?.date_of_creation,
          sub: calculateYearsUntilNow(original?.date_of_creation),
        },
        'Company Number': original?.company_number,
      },
      ...original,
    };
  };

  const mapOfficerObject = original => {
    const formatDOB = dob => {
      try {
        const { year, month, day } = dob;
        return `${year}-${month.toString().padStart(2, '0')}-${day
          .toString()
          .padStart(2, '0')}`;
      } catch (e) {
        return '';
      }
    };

    return {
      type: 'Officer',
      DOB: `${calculateAge(original?.date_of_birth)} ${formatDOB(original?.date_of_birth)}`,
      // subType: `Company house ${original.name} information`,
      title: original?.name,
      // address: `${original.address.address_line_1}, ${original.address.postal_code}, ${original.address.country}`,
      item: {
        Status: original?.resigned_on ? 'Resigned' : 'Active',
        Occupation: original?.occupation,
        DOB: {
          main: formatDOB(original?.date_of_birth),
          sub: calculateAge(original?.date_of_birth),
        }, // Adjust "sub" as needed
        Nationality:
          original?.nationality?.charAt(0)?.toUpperCase() +
          original?.nationality?.slice(1)?.toLowerCase(),
        Role: original?.officer_role,
        officer_id: original?.person_number,
      },
      ...original,
    };
  };

  const updateSuggestions = newObject => {
    setSelectedSuggestions(prevSuggestions => {
      // Check if an object of the same type already exists
      const exists = prevSuggestions.some(
        suggestion => suggestion.title === newObject.title
      );

      if (exists) {
        // Replace the existing object with the new object
        return prevSuggestions.map(suggestion =>
          suggestion.title === newObject.title ? newObject : suggestion
        );
      } else {
        // Add the new object
        return [...prevSuggestions, newObject];
      }
    });
  };

  const deleteByName = title => {
    setSelectedSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.title !== title)
    );
    setSelectedActualSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.title !== title)
    );
  };

  const deleteByType = type => {
    setSelectedSuggestions(prevSuggestions =>
      prevSuggestions.filter(suggestion => suggestion.type !== type)
    );
  };

  const removeItem = title => {
    setIsLimitReached(false);
    deleteByName(title);
    URLService.removeParam('selectedSuggestions');
  };

  // Function to toggle sidebar visibility
  const toggleSidebar = () => {
    setIsOpenSidebar(!isOpenSideBar);
  };

  const handleItemClick = (historyItem, index) => {
    setHistoricSuggestion(historyItem, index);
  };

  const jaccardSimilarity = (str1, str2) => {
    const set1 = new Set(str1.toLowerCase().split(/\W+/)); // Split by spaces, remove punctuation
    const set2 = new Set(str2.toLowerCase().split(/\W+/));

    const intersection = new Set([...set1].filter(word => set2.has(word)));
    const union = new Set([...set1, ...set2]);

    return ((intersection.size / union.size) * 100).toFixed(2);
  };

  const filterOnSearchedOfficers = (data, filters) => {
    let filteredResults = data.filter(item => {
      if (item?.nationality && filters.nationality) {
        if (
          filters.nationality &&
          item.nationality?.toLowerCase() !== filters.nationality.toLowerCase()
        ) {
          return false;
        }
      } else {
        if (filters.nationality !== '' && !item.nationality) {
          return false;
        }
      }

      if (item?.occupation && filters.occupation) {
        if (
          filters.occupation &&
          item.occupation?.toLowerCase() !== filters.occupation.toLowerCase()
        ) {
          return false;
        }
      } else {
        if (filters.occupation !== '' && !item.occupation) {
          return false;
        }
      }

      if (item.officer_role && filters.positionRole) {
        if (
          filters.positionRole &&
          item.officer_role?.toLowerCase() !==
            filters.positionRole.toLowerCase()
        ) {
          return false;
        }
      } else {
        if (filters.positionRole !== '' && !item.officer_role) {
          return false;
        }
      }

      if (item?.address?.postal_code && filters.postal_code) {
        if (
          filters.postal_code &&
          !item.address?.postal_code
            ?.toLowerCase()
            .includes(filters.postal_code.toLowerCase())
        ) {
          return false;
        }
      } else {
        if (filters.postal_code !== '' && !item?.address?.postal_code) {
          return false;
        }
      }

      if (filters.age && item?.date_of_birth?.year) {
        const birthYear = item?.date_of_birth?.year;
        if (
          !birthYear ||
          birthYear < filters.age[0] ||
          birthYear > filters.age[1]
        ) {
          return false;
        }
      } else {
        if (filters.age.length > 0 && !item?.date_of_birth) {
          return false;
        }
      }

      return true;
    });

    if (filters.address) {
      filteredResults = filteredResults
        .map(item => {
          const addressValues = Object.values(item.address || {})
            .filter(val => val !== '') // Remove empty values
            .map(val => String(val).toLowerCase())
            .join(' '); // Convert to a single string

          const filterAddress = filters.address.toLowerCase();
          const similarityScore = jaccardSimilarity(
            filterAddress,
            addressValues
          );

          return { item, similarityScore };
        })
        .sort((a, b) => b.similarityScore - a.similarityScore) // Sort descending by similarity
        .map(entry => entry.item); // Remove similarity scores before returning
    }

    return filteredResults;
  };

  const filterOnSearchedCompanies = (data, filters) => {
    return data.filter(item => {
      if (
        filters.company_status &&
        item.company_status?.toLowerCase() !==
          filters.company_status.toLowerCase()
      ) {
        return false;
      }

      if (
        filters.company_type &&
        item.company_type?.toLowerCase() !== filters.company_type.toLowerCase()
      ) {
        return false;
      }

      if (filters.creationDateRange && item.date_of_creation) {
        if (
          filters.creationDateRange &&
          ((item.date_of_creation &&
            new Date(item.date_of_creation).getFullYear() <
              filters.creationDateRange[0]) ||
            (item.date_of_creation &&
              new Date(item.date_of_creation).getFullYear() >
                filters.creationDateRange[1]))
        ) {
          return false;
        }
      } else {
        if (
          filters.creationDateRange.length > 0 &&
          filters.creationDateRange[0] !== 1800 &&
          filters.creationDateRange[1] !== new Date().getFullYear() + 5 &&
          !item?.date_of_creation
        ) {
          return false;
        }
      }

      if (filters.cessationDateRange && item.date_of_cessation) {
        const cessationYear = new Date(item.date_of_cessation).getFullYear();
        if (
          !cessationYear ||
          cessationYear < filters.cessationDateRange[0] ||
          cessationYear > filters.cessationDateRange[1]
        ) {
          return false;
        }
      } else {
        if (
          filters.cessationDateRange.length > 0 &&
          filters.cessationDateRange[0] !== 1800 &&
          filters.cessationDateRange[1] !== new Date().getFullYear() + 5 &&
          !item?.date_of_cessation
        ) {
          return false;
        }
      }

      if (
        filters.company_number &&
        !item?.company_number
          ?.toLowerCase()
          .includes(filters.company_number.toLowerCase())
      ) {
        return false;
      }

      if (
        filters.postal_code &&
        !item?.postal_code
          ?.toLowerCase()
          .includes(filters.postal_code.toLowerCase())
      ) {
        return false;
      }

      return true;
    });
  };

  const resetAdvanceFilters = () => {
    if (suggestions.length > 0 || defaultSuggestions.length > 0) {
      setSuggestions(defaultSuggestions);
      if (selectedType === 'Person') {
        // updateOfficerDynamicFilters({ officers: defaultSuggestions });
      }
    }
  };

  const updateOfficerDynamicFilters = officersRes => {
    let occupationListForDynamicFilters = [];
    let positionListForDynamicFilters = [];
    let nationalityListForDynamicFilters = [];
    let ageListForDynamicFilters = [];

    officersRes?.officers?.map(officer => {
      occupationListForDynamicFilters.push(officer?.occupation?.toLowerCase());
      positionListForDynamicFilters.push(officer?.officer_role?.toLowerCase());
      nationalityListForDynamicFilters.push(
        officer?.nationality?.toLowerCase()
      );
      ageListForDynamicFilters.push(officer?.date_of_birth?.year);
    });
    let cleanAge = ageListForDynamicFilters.filter(Number.isInteger);
    setCorporateLinksDynamicFilters({
      officer: {
        occupation: [
          ...new Set(occupationListForDynamicFilters.filter(Boolean)),
        ],
        positionRole: [
          ...new Set(positionListForDynamicFilters.filter(Boolean)),
        ],
        nationality: [
          ...new Set(nationalityListForDynamicFilters.filter(Boolean)),
        ],
        age: ageListForDynamicFilters?.length
          ? [Math.min(...cleanAge), Math.max(...cleanAge)]
          : [],
      },
    });
  };

  const debouncedSearchCompanyByName = async companyHouseSearchName => {
    if (!companyHouseSearchName) {
      setSuggestions([]);
      return;
    }

    let searchQuery = [
      { header: 'company_name', filter: ['+' + companyHouseSearchName] },
    ];

    try {
      setIsLoadingPage(true);
      setIsLoading(true);
      let companyStatusForDynamicFilter = new Set();
      let dateOfCreationYearForDynamicFilter = new Set();
      let dateOfCessationYearForDynamicFilter = new Set();
      let typeForDynamicFilter = new Set();

      const entitiesWithOptions = await explorerFilter(searchQuery, 0, 90);

      const mapResults = entitiesWithOptions?.result?.map(company => {
        const companyStatus = company?._source?.company_status;
        const dateOfCreation = company?._source?.date_of_creation;
        const dateOfCessation = company?._source?.date_of_cessation;
        const type = company?._source?.type;

        const dateOfCreationYear = dateOfCreation
          ? new Date(dateOfCreation).getFullYear()
          : null;
        const dateOfCessationYear = dateOfCessation
          ? new Date(dateOfCessation).getFullYear()
          : null;

        if (companyStatus) companyStatusForDynamicFilter.add(companyStatus);
        if (dateOfCreationYear)
          dateOfCreationYearForDynamicFilter.add(dateOfCreationYear);
        if (dateOfCessationYear)
          dateOfCessationYearForDynamicFilter.add(dateOfCessationYear);
        if (type) typeForDynamicFilter.add(type);

        return {
          id: company?._id,
          title: company?._source?.company_name,
          address: processRegisteredOfficeAddress(company?._source),
          addressObj: processRegisteredOfficeAddressObject(company?._source),
          date_of_creation: dateOfCreation,
          date_of_cessation: dateOfCessation,
          company_status: companyStatus,
          company_number: company?._source?.company_number,
          confirmation_statement: company?._source?.confirmation_statement,
          accounts: company?._source?.accounts,
          sic_codes: company?._source?.sic_codes,
          pofficers_list: company?._source?.pofficers_list,
          company_type: type,
          postal_code: company?._source?.registered_office_address?.postal_code,
        };
      });

      companyStatusForDynamicFilter = [...companyStatusForDynamicFilter];
      dateOfCreationYearForDynamicFilter = [
        ...dateOfCreationYearForDynamicFilter,
      ];
      dateOfCessationYearForDynamicFilter = [
        ...dateOfCessationYearForDynamicFilter,
      ];
      typeForDynamicFilter = [...typeForDynamicFilter];

      setCorporateLinksDynamicFilters({
        company: {
          companyStatus: companyStatusForDynamicFilter,
          companyType: typeForDynamicFilter,
          creationPeriod: dateOfCreationYearForDynamicFilter?.length
            ? [
                Math.min(...dateOfCreationYearForDynamicFilter),
                Math.max(...dateOfCreationYearForDynamicFilter),
              ]
            : [],
          cessationPeriod: dateOfCessationYearForDynamicFilter?.length
            ? [
                Math.min(...dateOfCessationYearForDynamicFilter),
                Math.max(...dateOfCessationYearForDynamicFilter),
              ]
            : [],
        },
      });

      setSuggestions(mapResults || []);
      setDefaultSuggestions(mapResults || []);
      setTotalOfficersAndCompanies(
        entitiesWithOptions?.pagination?.totalCompanies
      );
      setIsLoadingPage(false);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Company:', error);
    }
  };

  const debouncedSearchOfficerByName = async officerName => {
    if (!officerName) {
      setSuggestions([]);
      return;
    }
    setIsLoading(true);
    setIsLoadingPage(true);

    let queryBody = {
      name: officerName,
    };

    try {
      const officersRes = await explorerFilterOfficers(queryBody, 90);
      updateOfficerDynamicFilters(officersRes);

      setSuggestions(officersRes?.officers || []);
      setDefaultSuggestions(officersRes?.officers || []);
      setTotalOfficersAndCompanies(officersRes?.total);
      setIsLoadingPage(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoadingPage(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (user && !user?.hasOwnProperty('guest')) {
      const getAllQuriesByUserId = async () => {
        try {
          let networksQuries = await getNetworkGraphQueryByUserId(
            user?.FirebaseUser?.user_id
          );
          setQueryHistory(networksQuries);
        } catch (error) {}
      };
      getAllQuriesByUserId();
    }
  }, [user]);

  useEffect(() => {
    setSuggestions([]);
    setDefaultSuggestions([]);
    if (inputValue.length > 0) {
      if (selectedType === 'Person') {
        debouncedSearchOfficerByName(inputValue);
      } else if (selectedType === 'Company') {
        debouncedSearchCompanyByName(inputValue);
      }
    }
  }, [selectedType]);

  useEffect(() => {
    setCurrentPage(1);
    if (selectedType === 'Person') {
      let filterOfficersData = filterOnSearchedOfficers(
        suggestions.length > defaultSuggestions.length
          ? suggestions
          : defaultSuggestions,
        corporateLinksAdvanceFilters.officerFilters
      );
      // updateOfficerDynamicFilters({ officers: filterOfficersData });
      setSuggestions(filterOfficersData);
    }

    if (selectedType === 'Company') {
      let filterCompaniesData = filterOnSearchedCompanies(
        suggestions.length > defaultSuggestions.length
          ? suggestions
          : defaultSuggestions,
        corporateLinksAdvanceFilters.companyFilters
      );
      setSuggestions(filterCompaniesData);
    }
  }, [corporateLinksAdvanceFilters]);

  useEffect(() => {
    if (suggestions.length > 0) {
      if (selectedSuggestions.length < 1) {
        setIsSearching(true);
      } else {
        setIsSearching(false);
      }
    } else {
      if (selectedSuggestions.length < 2) {
        setIsSearching(true);
      } else {
        setIsSearching(false);
      }
    }
  }, [selectedSuggestions]);

  useEffect(() => {
    if (suggestions.length < 0) {
      setSelectedActualSuggestions(selectedSuggestions);
    }
  }, [selectedSuggestions, selectedActualSuggestions]);

  useEffect(() => {
    let paramSS = URLService.getParam('selectedSuggestions');
    if (paramSS && isValidJSON(paramSS)) {
      setSelectedSuggestions(JSON.parse(paramSS));
    }
    setNgTaskId(null);
    setClGraph(null);
    setNgTasks([]);
  }, []);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="cesearch-entites-dashboard-content">
            {!user?.hasOwnProperty('guest') && !isOpenSideBar && (
              <div className="toggle-button" onClick={toggleSidebar}>
                <img src={saveIcon} alt="saved" />
                <span className="button-text">My Saved Searches</span>
              </div>
            )}

            {!user?.hasOwnProperty('guest') && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                {/* Sidebar */}
                <div
                  className="sidebar-recent-search"
                  style={{
                    transform: isOpenSideBar
                      ? 'translateX(0)'
                      : 'translateX(-250px)',
                  }}
                >
                  <div
                    className="history-item close-icon-recent-search"
                    style={{
                      transform: isOpenSideBar
                        ? 'translateX(0)'
                        : 'translateX(-270px)',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={saveIcon}
                        alt="save"
                        style={{ height: '16px', width: '12px' }}
                      />
                      <span
                        className="button-text"
                        style={{ color: 'rgb(2, 156, 189)', marginLeft: '7px' }}
                      >
                        My Saved Searches
                      </span>
                    </div>
                    <img src={crossColor} alt="" onClick={toggleSidebar} />
                  </div>

                  <div className="sidebar-search-item-container">
                    {queryHistory &&
                      Array.isArray(queryHistory) &&
                      queryHistory?.map((historyItem, index) => (
                        <div
                          key={index}
                          className="history-item"
                          onClick={() => handleItemClick(historyItem, index)}
                          style={{
                            cursor: 'pointer',
                            padding: '10px',
                            margin: '5px 0',
                            borderRadius: '4px',
                            transition: 'background-color 0.3s ease',
                            position: 'relative', // Added to position the cross icon
                          }}
                          onMouseEnter={e =>
                            (e.currentTarget.querySelector(
                              '.delete-icon'
                            ).style.display = 'block')
                          }
                          onMouseLeave={e =>
                            (e.currentTarget.querySelector(
                              '.delete-icon'
                            ).style.display = 'none')
                          }
                        >
                          {/* Cross icon - hidden by default, shown on hover */}
                          <div
                            className="delete-icon"
                            style={{
                              position: 'absolute',
                              top: '2px',
                              right: '7px',
                              display: 'none',
                              cursor: 'pointer',
                              zIndex: 10,
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              handleDeleteHistoryItem(historyItem.id);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              style={{
                                color: '#999',
                                fontSize: '14px',
                              }}
                            />
                          </div>

                          {Array.isArray(historyItem?.query) ? (
                            historyItem.query.map((item, idx) => (
                              <div
                                key={idx}
                                style={{
                                  color: '#333',
                                  fontSize: '14px',
                                  paddingLeft: '20%',
                                }}
                              >
                                <img
                                  src={
                                    item.type === 'Officer'
                                      ? personBlack
                                      : CompanyBlack
                                  }
                                  alt="icon"
                                  style={{ marginRight: '10px' }}
                                />
                                <span>{item.title}</span>
                                {historyItem.query.length > 1 &&
                                idx < historyItem.query.length - 1 ? (
                                  <span
                                    style={{
                                      display: 'block',
                                      paddingLeft: '30%',
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      style={{
                                        color: 'black',
                                        width: '25px',
                                        borderRadius: '5px',
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ''
                                )}
                                {item.company}
                              </div>
                            ))
                          ) : (
                            <div style={{ color: '#333', fontSize: '14px' }}>
                              No query found
                            </div>
                          )}
                        </div>
                      ))}
                  </div>

                  <div className="sidebar-recent-search-footer">
                    <button onClick={handleClearAll}>Clear All</button>
                  </div>
                </div>

                {/* Toggle Button Icon outside Sidebar */}
              </div>
            )}
            <CESearchEntitiesFlowSection
              searchContainerRef={searchContainerRef}
              inputValue={inputValue}
              setInputValue={setInputValue}
              handleInputFocus={handleInputFocus}
              handleInputBlur={handleInputBlur}
              handleChange={handleChange}
              selectedSuggestions={selectedSuggestions}
              handleGraphPage={handleGraphPage}
              isOpen={isOpen}
              selectedType={selectedType}
              handleButtonClick={handleButtonClick}
              isLoadingPage={isLoadingPage}
              loadingGif={loadingGif}
              suggestions={suggestions}
              removeItem={removeItem}
              itemClicked={itemClicked}
              setSelectedType={setSelectedType}
              hideButtonsAndSearch={hideButtonsAndSearch}
              setHideButtonsAndSearch={setHideButtonsAndSearch}
              setSuggestions={setSuggestions}
              setIsOpen={setIsOpen}
              totalOfficersAndCompanies={totalOfficersAndCompanies}
              handleConfirm={handleConfirm}
              setIsSearching={setIsSearching}
              isSearching={isSearching}
              isLimitReached={isLimitReached}
              setSelectedSuggestions={setSelectedSuggestions}
              selectedActualSuggestions={selectedActualSuggestions}
              setIsLimitReached={setIsLimitReached}
              resetAdvanceFilters={resetAdvanceFilters}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CESearchEntities;
