import React, { useContext, useEffect, useState } from 'react';
import './CEIWatchList.css';
import { useNavigate } from 'react-router';
import { AppContext } from '../../../context/Context';
import {
  getWatchListByCompanyNumberAndUserId,
  setWatchListForCompanyByUserId,
} from '../../../api/watchList/watchList';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import bellIconBlue from '../../../assets/images/icons/BellIconBlue.png'
import MobileWatchList from '../../../assets/images/MobileWatchList.png'

const WatchList = ({ selectedCompany }) => {
  const navigate = useNavigate();
  const { user, setIsBeingWatched, isBeingWatched } = useContext(AppContext);
  const { setIsLoading } = useLoading();

  const watchCheck = async () => {
    let isWatch = await getWatchListByCompanyNumberAndUserId(
      user?.uid,
      selectedCompany?.company_number
    );
    if (typeof isWatch === 'boolean') {
      setIsBeingWatched(isWatch);
    }
  };

  const onClickAddToWatchList = async () => {
    try {
      if (selectedCompany) {
        setIsLoading(true);
        let companyNumber = selectedCompany?.company_number;
        let userId = user?.uid;
        let relationship = 'Owner';

        let addRespons = await setWatchListForCompanyByUserId(
          userId,
          companyNumber,
          relationship
        );

        if (addRespons?.success) {
          await watchCheck();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedCompany) {
      const fetchData = async () => {
        await watchCheck();
      };
      fetchData();
    }
  }, [selectedCompany]);
  return (
    <div className="watch-list-container">
      <h2 className="financials-title"><img style={{ marginRight: '10px' }} src={bellIconBlue} alt="icon" />Watch List</h2>
      {/* <span className="title">Watch List</span> */}
      <div className="watch-list-card">
        <img src={MobileWatchList} alt="mobile" />
        {/* <div className="icon">
          <span role="img" aria-label="bell">
            🔔
          </span>
        </div> */}
        <div className="content">
          <p className="title">Stay updated with {selectedCompany?.title}</p>
          <p className="description">
            Monitor changes to this company's activity with instant email
            notifications.
          </p>
          {isBeingWatched === true ? (
            <button
              className="watch-list-button"
              onClick={() => navigate('/watchlist')}
            >
              Watching
            </button>
          ) : (
            <button
              className="watch-list-button"
              onClick={() => onClickAddToWatchList()}
            >
              ADD TO WATCH LIST
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WatchList;
