import React, { useContext } from 'react';
import { AppContext } from '../../context/Context';
import { useLoading } from '../../context/LoadingContext/LoadingContext';
import { stripePromise } from '../Login/Signup';
import {
  cancelSubscriptionAtPeriodEnd,
  createCheckoutSessionForSubscriptionPlan,
} from '../../api/subscription/subscription';
import showToast from '../../utils/showToast';
import { useNavigate } from 'react-router';
import checkIcon from '../../assets/images/icons/Check icon.png';

const Pricing = () => {
  const { user } = useContext(AppContext);
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();

  const stripePaymentHandler = async (email, selectedPlan) => {
    setIsLoading(true);

    const stripe = await stripePromise;
    // Request a Checkout session from the backend
    const response = await createCheckoutSessionForSubscriptionPlan({
      email,
      plan: selectedPlan,
    });

    const session = response;

    if (session.error) {
      setIsLoading(false);
      return;
    }

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const freemiumPlan = async () => {
    setIsLoading(true);
    await cancelSubscriptionAtPeriodEnd({
      user_id: user?.uid,
      current_plan: user?.FirebaseUser?.plan,
      new_plan: 'Freemium',
    });
    setIsLoading(false);
    showToast(
      'success',
      `Subscription ${user?.FirebaseUser?.plan} downgraded to Freemium successfully and will remain active until the end of your current billing cycle.`
    );

    return;
  };

  const professionalPlan = async () => {
    setIsLoading(true);
    const userEmail = user?.email;
    await stripePaymentHandler(userEmail, 'Professional');
    setIsLoading(false);
    return;
  };

  return (
    <div className="pricing-container">
      {/* Freemium plan */}
      <div
        className={`pricing-box ${user?.FirebaseUser?.plan === 'Freemium' ? 'pricing-plan-selected' : ''}`}
      >
        <div>
          <h2>Freemium</h2>
          <p className="price">
            <div className='price-span'>$</div> 0 <span>/ per month</span>
          </p>
          <hr style={{ marginTop: '0' }} />
          <h6 style={{ textAlign: 'start', paddingLeft: '20px' }}>FEATURES</h6>
          <p style={{ textAlign: 'start', paddingLeft: '20px', fontWeight: '400' }}>Everything in our free plan plus....</p>
          <ul>
            <li><img src={checkIcon} alt="" />Unlimited profiles</li>
            <li><img src={checkIcon} alt="" />Unlimited company profiles</li>
            <li><img src={checkIcon} alt="" />3 Years Financial Data</li>
            <li><img src={checkIcon} alt="" />Save Searches</li>
            <li><img src={checkIcon} alt="" />Land & Buildings</li>
            <li><img src={checkIcon} alt="" />All name matching</li>
            <li><img src={checkIcon} alt="" />Corporate Networks (Unlimited)</li>
            <li><img src={checkIcon} alt="" />Save searches Filter</li>
            <li><img src={checkIcon} alt="" />+ many more...</li>
          </ul>
        </div>
        <div className={`${user?.FirebaseUser?.plan === 'Freemium' ? 'btn-container' : ''}`}>
          <hr style={{ marginTop: '0' }} />
          <button
            className={`${user?.FirebaseUser?.plan === 'Freemium' ? 'pricing-btn-selected' : 'btn'}`}
            disabled={user?.FirebaseUser?.plan === 'Freemium' ? true : false}
            onClick={freemiumPlan}
          >
            {user?.FirebaseUser?.plan === 'Freemium'
              ? 'Active'
              : 'Get started'}
          </button>
        </div>
      </div>
      <div
        className={`pricing-box premium ${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Professional' ? 'pricing-plan-selected' : ''}`}
      >
        <div>
          <h2>Premium</h2>
          <p className="price">
            <div className='price-span'>$</div> 79 <span>/ per month</span>
          </p>
          <hr style={{ marginTop: '0' }} />
          <h6 style={{ textAlign: 'start', paddingLeft: '20px' }}>FEATURES</h6>
          <p style={{ textAlign: 'start', paddingLeft: '20px', fontWeight: '400' }}>Everything in our free plan plus....</p>
          <ul>
            <li><img src={checkIcon} alt="" />Shareholders</li>
            <li><img src={checkIcon} alt="" />Charges (Overview)</li>
            <li><img src={checkIcon} alt="" />Persons of Significant Control</li>
            <li><img src={checkIcon} alt="" />Export Data</li>
            <li><img src={checkIcon} alt="" />Save Results</li>
            <li><img src={checkIcon} alt="" />Financial Data</li>
            <li><img src={checkIcon} alt="" />SIC Codes</li>
            <li><img src={checkIcon} alt="" />Export networks and entities</li>
            <li><img src={checkIcon} alt="" />Save searches (unlimited)</li>
            <li><img src={checkIcon} alt="" />+ many more...</li>
          </ul>
        </div>
        <div className={`${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Professional' ? 'btn-container' : ''}`}        >
          <hr style={{ marginTop: '0' }} />
          <button
            className={`btn ${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Professional' ? 'pricing-btn-selected' : ''}`}
            disabled={
              user?.FirebaseUser?.plan === 'Professional' ||
                user?.FirebaseUser?.plan === 'Premium'
                ? true
                : false
            }
            onClick={professionalPlan}
          >
            {user?.FirebaseUser?.plan === 'Professional' ||
              user?.FirebaseUser?.plan === 'Premium'
              ? 'Current Plan'
              : 'Get started'}
          </button>
        </div>
      </div>
      {/* Enterprise plan */}
      <div
        className={`pricing-box enterprise ${user?.FirebaseUser?.plan === 'Enterprise' ? 'pricing-plan-selected' : ''}`}
      >
        <div>

          <h2>Enterprise</h2>
          <p className="price">
            <div className='price-span'>$</div> ? <span>/ per month</span>
          </p>
          <hr style={{ marginTop: '0' }} />
          <h6 style={{ textAlign: 'start', paddingLeft: '20px' }}>Advanced features and reporting.</h6>
          <p style={{ textAlign: 'start', paddingLeft: '20px', fontWeight: '400' }}>Everything in our free plan plus....</p>
          <ul>
            <li><img src={checkIcon} alt="" />Teams Access</li>
            <li><img src={checkIcon} alt="" />Additional Financial Metrics</li>
            <li><img src={checkIcon} alt="" />Deep Email Search</li>
            <li><img src={checkIcon} alt="" />Trademarks</li>
            <li><img src={checkIcon} alt="" />Deep search connections</li>
            <li><img src={checkIcon} alt="" />Deep Email Search</li>
            <li><img src={checkIcon} alt="" />Tax Defaulters Companies</li>
            <li><img src={checkIcon} alt="" />Tax Defaulters Persons</li>
            <li><img src={checkIcon} alt="" />Annotate Network Graph with notes</li>
            <li><img src={checkIcon} alt="" />+ many more...</li>
          </ul>
        </div>
        <div className={`${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Enterprise' ? 'btn-container' : ''}`}        >
          <hr style={{ marginTop: '0' }} />
          <button
            className={`btn ${user?.FirebaseUser?.plan === 'Premium' || user?.FirebaseUser?.plan === 'Enterprise' ? 'pricing-btn-selected' : ''}`}
            disabled={user?.FirebaseUser?.plan === 'Enterprise' ? true : false}
            onClick={() => { navigate('/contact-us '); }}
          >
            {user?.FirebaseUser?.plan === 'Enterprise'
              ? 'Current Plan'
              : 'Contact Us'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
