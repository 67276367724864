import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './CLGraph.css';
import CLSearchInput from '../CLSearchInput/CLSearchInput';
import { AppContext } from '../../../context/Context';
import CLGrapgLeftSidebarItemHolder from '../CLGraphLeftSidebar/CLGrapgLeftSidebarItemHolder/CLGrapgLeftSidebarItemHolder';
import CLGraphTotalDropdown from '../CLGraphTotalDropdown/CLGraphTotalDropdown';
import ProbeButton from '../../ProbeButton/ProbeButton';
import { useNavigate } from 'react-router';
import {
  capitalizeFirstLetter,
  convertGraphToCSV,
  getCompanyStatusNetworkGraph,
  getNodeNameById,
  getOfficerStatusNetworkGraph,
  getStatusStringFromChar,
  nationalityToFlag,
} from '../../../utils/helper';
import URLService from '../../../utils/URLService';
import backArrow from '../../../assets/images/icons/backArrow.png';
import arrow from '../../../assets/images/dashboard/arrow.png';
import person from '../../../assets/images/icons/person.png';
import company from '../../../assets/images/icons/company.png';
import ToolBarIcon from '../../../assets/images/icons/ToolbarIcon.png';

import shareholderPersonIcon from '../../../assets/images/shareholder-person.png';
import shareholderCompanyIcon from '../../../assets/images/shareholder-company.png';
import BottomMenu from '../../GraphRightSideBar/GraphRightSideBar';
import BackIcon from '../../../assets/images/icons/BackIcon.png';
import ExportIcon from '../../../assets/images/icons/ExportIcon.png';
import arrowDown from '../../../assets/images/icons/arrowDown.png';
import fluentIcon from '../../../assets/images/icons/fluentIcon1.png';
import mdiIcon2 from '../../../assets/images/icons/mdiIcon2.png';
import stackIcon3 from '../../../assets/images/icons/stackIcon3.png';
import relationshipIcon4 from '../../../assets/images/icons/relationshipIcon4.png';
import worldIcon5 from '../../../assets/images/icons/worldIcon5.png';
import targetIcon6 from '../../../assets/images/icons/targetIcon6.png';
import fluentHighlighted from '../../../assets/images/icons/fluentHighlighted.png';
import mdiIHighlighted from '../../../assets/images/icons/mdiHighlighted.png';
import stackHighlighted from '../../../assets/images/icons/stackHighlighted.png';
import relationHighlighted from '../../../assets/images/icons/relationshipHighlighted.png';
import worldHighlighted from '../../../assets/images/icons/worldHighlighted.png';
import targetHighlighted from '../../../assets/images/icons/targetHighlighted.png';

const CLGraph = ({
  graph,
  setLeftSideButtonType,
  leftSideButtonType,
  selectedGraph,
  setSelectedGraph,
  isLoadingShareholders,
  selectedEntityType,
  setSelectedEntityType,
  rightSidebarOpen,
  searchedEntitiesInGraph,
  showNationalityINNetwork,
  showOnlyRelationships,
  accountsInformationForNG,
  showLiabilitiesINNetworkGraph,
  showDebthRatioINNetworkGraph,
  showShowShareholderINNetwork,
  showStatusINNetworkGraph,
  activeIndex,
  setActiveIndex,
  toggleNGGraph,
  ngTasks,
}) => {
  const navigate = useNavigate();
  const svgRef = useRef();
  // const zoomResetRef = useRef();
  const zoomRef = useRef();
  const svgGroupRef = useRef();
  const { clGraph, setClGraph } = useContext(AppContext);
  const [searchProbe, setSearchProbe] = useState('');
  const [isNodeSearching, setIsNodeSearching] = useState(false);
  const { setFinancialAccountsDoc } = useContext(AppContext);
  const [currentZoomTransform, setCurrentZoomTransform] = useState(null);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [showSlider, setShowSlider] = useState(false);
  const [zoomValue, setZoomValue] = useState(66); // Initialize zoomValue state

  const toggleSlider = () => {
    setShowSlider(prev => !prev);
  };

  const exportSelectedGraph = () => {
    try {
      let graphcsvfile = convertGraphToCSV(selectedGraph);

      // Create a Blob from the CSV content
      const blob = new Blob([graphcsvfile], {
        type: 'text/csv;charset=utf-8;',
      });

      // Create a download link
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'graph.csv');

      // Append link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link after the download starts
      document.body.removeChild(link);
    } catch (error) {}
  };

  const onSuggestionSelect = node => {
    let searchQuery = node?.id ? node?.id : node?.entityName;
    setSearchProbe(searchQuery);
  };

  const goBackToSearchEntitiesPage = () => {
    const paramSS = URLService.getParam('selectedSuggestions');
    if (paramSS) {
      navigate(`/cl-dashboard/search-entites?selectedSuggestions=${paramSS}`);
    } else {
      navigate('/cl-dashboard/search-entites');
    }
  };

  const zoomIn = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 2);
    setZoomValue(prevZoom => Math.round(Math.min(prevZoom * 2, 200))); // Round to nearest integer
  };

  const zoomOut = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 0.5);
    setZoomValue(prevZoom => Math.round(Math.max(prevZoom / 2, 10))); // Round to nearest integer
  };

  useEffect(() => {
    if (!rightSidebarOpen && svgRef?.current && zoomRef?.current?.scaleBy) {
      d3.select(svgRef.current)
        .transition()
        .call(zoomRef.current.scaleBy, 0.35);
    }
    setZoomValue(prevZoom => Math.round(Math.max(prevZoom * 0.35, 10))); // Round to nearest integer
  }, [rightSidebarOpen]);

  const filterOptions = [
    {
      highlightedIcon: fluentHighlighted,
      text: 'All Shareholders',
      icon: fluentIcon,
    },
    { highlightedIcon: mdiIHighlighted, text: 'Debt Ratio', icon: mdiIcon2 },
    {
      highlightedIcon: stackHighlighted,
      text: 'Total Liabilities',
      icon: stackIcon3,
    },
    {
      highlightedIcon: relationHighlighted,
      text: 'Show Entity Link',
      icon: relationshipIcon4,
    },
    {
      highlightedIcon: worldHighlighted,
      text: 'Nationality',
      icon: worldIcon5,
    },
    { highlightedIcon: targetHighlighted, text: 'Status', icon: targetIcon6 },
  ];

  const handleFilterButtonClick = () => {
    if (selectedFilter) {
      setLeftSideButtonType(selectedFilter);
      setIsFilterOpened(false);
    } else {
      console.log('No filter selected');
    }
  };

  const handleSelectItem = index => {
    setLeftSideButtonType('default');
    setActiveIndex(index); // Set the active index
    setSelectedGraph(graph[index]);
    setClGraph(previousState => ({
      ...previousState,
      selectedGraph: graph[index],
    }));
  };

  useEffect(() => {
    if (!selectedGraph) return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const spaceBetweenNodes = 70; // Adjust this value to increase or decrease spacing

    const zoom = d3.zoom().on('zoom', event => {
      svgGroupRef.current.attr('transform', event.transform);
      setCurrentZoomTransform(event.transform);
    });

    zoomRef.current = zoom;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .call(zoom);

    svgGroupRef.current = svg.append('g');

    if (currentZoomTransform) {
      svg.call(zoom.transform, currentZoomTransform);
    } else {
      // Calculate the center of the graph
      const nodes = selectedGraph.nodes;
      const xValues = nodes.map(node => node.x || 0);
      const yValues = nodes.map(node => node.y || 0);
      const graphCenterX = Math.max(...xValues) + Math.min(...xValues);
      const graphCenterY = Math.max(...yValues) + Math.min(...yValues);

      // Calculate the translation to center the graph in the viewport
      const initialZoomLevel = 0.35; // Initial zoom scale
      const translateX = width / 2 - graphCenterX - 500 * initialZoomLevel;
      const translateY = height / 2 - graphCenterY - 500 * initialZoomLevel;

      // Set the initial zoom transform
      const initialTransform = d3.zoomIdentity
        .translate(translateX, translateY)
        .scale(initialZoomLevel);

      svg.call(zoom.transform, initialTransform);
    }

    const graphData = structuredClone(selectedGraph);

    const relationshipEdgesIds = graphData.meta?.relationshipEdgesIds || [];

    const simulation = d3
      .forceSimulation(graphData.nodes)
      .alpha(4) // Start with a lower alpha
      .alphaDecay(0.09) // Default is 0.0228, adjust if needed for quicker stabilization
      .force(
        'link',
        d3
          .forceLink(graphData.edges)
          .id(d => d.id)
          .distance(150)
      )
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'collision',
        d3.forceCollide().radius(d => spaceBetweenNodes)
      );

    const link = svgGroupRef.current
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graphData.edges)
      .enter()
      .append('line')
      .attr('stroke-width', 2)
      .attr('stroke', d =>
        relationshipEdgesIds.includes(d.edgeId) ? 'red' : '#999'
      );

    const label = svgGroupRef.current
      .append('g')
      .attr('class', 'labels')
      .selectAll('text')
      .data(graphData.nodes)
      .enter()
      .append('text')
      .attr('dx', d => {
        if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
          return 30;
        }
        return 16;
      })
      .attr('dy', '.30em')
      .attr('font-weight', d => {
        if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
          return 'bold';
        }
        return 'normal';
      }) // Make the text bold
      .attr('font-size', d => {
        if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
          return '15px';
        }
        return '12px';
      })
      .text(d => {
        if (d.entityType === 'company' && d.entityData && d.entityData.name) {
          return d.entityData.name;
        }
        return d.entityName;
      });

    const additionalLabel = svgGroupRef.current
      .append('g')
      .attr('class', 'additional-labels')
      .selectAll('text')
      .data(graphData.nodes)
      .enter()
      .append('text')
      .attr('dx', d => {
        if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
          return 30;
        }
        return 16;
      })
      .attr('dy', '1.5em') // Adjust the dy value to position the text below the first label
      .text(d => {
        const getValue = (obj, key) => obj[key] ?? '';

        if (showLiabilitiesINNetworkGraph) {
          const accountsObj = getValue(accountsInformationForNG, d?.id);

          return accountsObj?.total_liabilities;
        }

        if (showDebthRatioINNetworkGraph) {
          const accountsObj = getValue(accountsInformationForNG, d?.id);

          return accountsObj?.depth_ratio;
        }
        return '';
      }) // Set the text to "(90%)" or any other dynamic value you need
      .style('fill', 'red'); // Change the color of the text to blue

    const node = svgGroupRef.current
      .append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(graphData.nodes)
      .enter()
      .append('g')
      .each(function (d) {
        const nodeGroup = d3.select(this);

        // Append the main node icon
        nodeGroup
          .append('image')
          .attr('xlink:href', d => {
            if (d?.isShareholder === true) {
              return d?.entityType === 'person'
                ? shareholderPersonIcon
                : shareholderCompanyIcon;
            }
            return d.entityType === 'person' ? person : company;
          })
          .attr('width', d => {
            if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
              return 35;
            }
            return 24;
          })
          .attr('height', d => {
            if (selectedGraph?.meta?.selectedEntity?.ids.includes(d.id)) {
              return 35;
            }
            return 34;
          })
          .attr('x', d =>
            selectedEntityType && d.entityType === selectedEntityType
              ? -16
              : -12
          )
          .attr('y', d =>
            selectedEntityType && d.entityType === selectedEntityType
              ? -16
              : -12
          )
          .style('opacity', d =>
            selectedEntityType && d.entityType !== selectedEntityType ? 0.2 : 1
          )
          .on('mouseover', handleMouseOver)
          .on('mouseout', handleMouseOut)
          .on('click', nodeClicked);

        // Append a small icon at the bottom right of the node
        if (showNationalityINNetwork) {
          nodeGroup
            .append('defs')
            .append('clipPath')
            .attr('id', 'circle-clip')
            .append('circle')
            .attr('cx', 10) // Half of width
            .attr('cy', 8) // Half of height
            .attr('r', 5); // Radius to create a circle

          nodeGroup
            .append('image')
            .attr('xlink:href', d =>
              d.entityType === 'person'
                ? nationalityToFlag[
                    d?.entityData?.officer?.nationality
                      ?.toUpperCase()
                      .replace(' ', '')
                  ]
                : ''
            )
            .attr('width', 15) // Adjust the size of the small icon
            .attr('height', 10) // Adjust the size of the small icon
            .attr('x', d => {
              const mainIconWidth =
                selectedEntityType && d.entityType === selectedEntityType
                  ? 32
                  : 30;
              return mainIconWidth / 2 - 12; // Adjust the position as needed
            })
            .attr('y', d => {
              const mainIconHeight =
                selectedEntityType && d.entityType === selectedEntityType
                  ? 32
                  : 35;
              return mainIconHeight / 2 - 15; // Adjust the position as needed
            })
            .attr('clip-path', 'url(#circle-clip)') // Apply circular mask
            .style('opacity', d =>
              selectedEntityType && d.entityType !== selectedEntityType
                ? 0.2
                : 1
            );
        }

        if (showStatusINNetworkGraph) {
          const bbox = nodeGroup.node().getBBox(); // Get node's bounding box
          const statusGroup = nodeGroup.append('g').attr('class', d => {
            if (d?.entityType === 'company') {
              let status = getCompanyStatusNetworkGraph(
                d?.entityData?.company?.company_status
              );

              return status === 'pink'
                ? 'status-label-if-no-label'
                : 'status-label';
            }

            if (d?.entityType === 'person') {
              return d?.entityData?.officer === undefined
                ? 'status-label-if-no-label'
                : 'status-label';
            }
          });

          // Append text to measure width
          const textElement = statusGroup
            .append('text')
            .attr('font-size', '10px')
            .attr('font-weight', 'bold')
            .attr('fill', d => {
              if (d?.entityType === 'company') {
                return getCompanyStatusNetworkGraph(
                  d?.entityData?.company?.company_status
                );
              }
              if (d?.entityType === 'person') {
                return getOfficerStatusNetworkGraph(
                  d?.entityData?.officer?.resigned_on
                );
              }
            })
            .text(d => {
              if (d?.entityType === 'company') {
                return capitalizeFirstLetter(
                  getStatusStringFromChar(
                    d?.entityData?.company?.company_status
                  )
                );
              }
              if (d?.entityType === 'person') {
                return d?.entityData?.officer?.resigned_on
                  ? 'Active'
                  : 'Resigned';
              }
              return '';
            })
            .attr('y', 4)
            .node();

          const textWidth = textElement.getComputedTextLength() + 30; // Extra padding

          // Calculate the center position based on the node
          const capsuleX = bbox.x + bbox.width / 2 - textWidth / 2;
          const capsuleY =
            bbox.y + (bbox.height > 30 ? bbox.height + 15 : bbox.height); // Place below the node

          // Set the transform for proper alignment
          statusGroup.attr('transform', `translate(${capsuleX}, ${capsuleY})`);

          // Append Capsule Background (Centered)
          statusGroup
            .insert('rect', ':first-child')
            .attr('x', 0) // Align at the group's origin
            .attr('y', -10)
            .attr('width', textWidth)
            .attr('height', 20)
            .attr('rx', 12)
            .attr('ry', 12)
            .attr('fill', d => (d.isActive ? '#E6F4EA' : '#F4F4F4'))
            .attr('stroke', d => {
              if (d?.entityType === 'company') {
                return getCompanyStatusNetworkGraph(
                  d?.entityData?.company?.company_status
                );
              }
              if (d?.entityType === 'person') {
                return getOfficerStatusNetworkGraph(
                  d?.entityData?.officer?.resigned_on
                );
              }
            })
            .attr('stroke-width', 1.5);

          // Append Status Circle (Corrected Position)
          statusGroup
            .append('circle')
            .attr('r', 5)
            .attr('fill', d => {
              if (d?.entityType === 'company') {
                return getCompanyStatusNetworkGraph(
                  d?.entityData?.company?.company_status
                );
              }
              if (d?.entityType === 'person') {
                return getOfficerStatusNetworkGraph(
                  d?.entityData?.officer?.resigned_on
                );
              }
            })
            .attr('cx', 10) // Adjusted for better alignment inside the capsule
            .attr('cy', 0);

          // Center the text inside the capsule
          d3.select(textElement).attr('x', 20); // Adjust text placement
        }
      })
      .call(
        d3
          .drag()
          .on('start', dragStarted)
          .on('drag', dragged)
          .on('end', dragEnded)
      );

    node
      .filter(d => searchedEntitiesInGraph.includes(d.id))
      .attr('class', 'cl-graph-highlighted-node');

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
      node.attr('transform', d => `translate(${d.x},${d.y})`);
      label.attr('x', d => d.x).attr('y', d => d.y);
      additionalLabel.attr('x', d => d.x).attr('y', d => d.y);
    });

    function getConnectedNodesAndEdges(
      graphData,
      relationshipEdgesIds,
      isShareholders = false
    ) {
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      // Find all edges that match relationshipEdgesIds
      graphData.edges.forEach(edge => {
        if (
          relationshipEdgesIds?.includes(edge?.edgeId) &&
          isShareholders === false
        ) {
          connectedEdges.add(edge.edgeId);
          connectedNodes.add(edge.source.id);
          connectedNodes.add(edge.target.id);
        }

        if (isShareholders === true) {
          if (edge?.source?.isShareholder || edge?.target?.isShareholder) {
            connectedEdges.add(edge.edgeId);
            connectedNodes.add(edge.source.id);
            connectedNodes.add(edge.target.id);
          }
        }
      });

      return { connectedNodes, connectedEdges };
    }

    function highlightOnlyRelationshipNodes(
      link,
      node,
      connectedNodes,
      connectedEdges
    ) {
      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('image')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));
    }

    function dragStarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart(); // Keep simulation active but reduce movement

      // Fix the positions of all nodes except the dragged node
      simulation.nodes().forEach(node => {
        if (node.id !== d.id) {
          node.fx = node.x; // Fix other nodes in place
          node.fy = node.y;
        }
      });

      // Allow the dragged node to move
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      // Update the position of the dragged node
      d.fx = event.x;
      d.fy = event.y;
    }

    function dragEnded(event, d) {
      if (!event.active) simulation.alphaTarget(0); // Re-enable simulation after drag

      // Fix the dragged node's position at its final location
      d.fx = event.x;
      d.fy = event.y;
    }

    function nodeClickedHellper(event, d) {
      setFinancialAccountsDoc([]);
      // Stop event propagation so the SVG click handler doesn't fire
      event.stopPropagation();

      // Fix the position of the clicked node (optional)
      d.fx = d.x;
      d.fy = d.y;

      // Highlight the clicked node and its connections
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      function findDirectConnections(nodeId) {
        link.each(function (l) {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            connectedEdges.add(l.edgeId);
            connectedNodes.add(l.source.id);
            connectedNodes.add(l.target.id);
          }
        });
      }

      function findConnectedNodesThroughRelationship(nodeId) {
        link.each(function (l) {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            if (relationshipEdgesIds.includes(l.edgeId)) {
              connectedEdges.add(l.edgeId);
              if (!connectedNodes.has(l.source.id)) {
                connectedNodes.add(l.source.id);
                findConnectedNodesThroughRelationship(l.source.id);
              }
              if (!connectedNodes.has(l.target.id)) {
                connectedNodes.add(l.target.id);
                findConnectedNodesThroughRelationship(l.target.id);
              }
            }
          }
        });
      }

      connectedNodes.add(d.id);

      let isConnectedWithRelationship = false;
      link.each(function (l) {
        if (
          (l.source.id === d.id || l.target.id === d.id) &&
          relationshipEdgesIds.includes(l.edgeId)
        ) {
          isConnectedWithRelationship = true;
        }
      });

      if (isConnectedWithRelationship) {
        findConnectedNodesThroughRelationship(d.id);
      } else {
        findDirectConnections(d.id);
      }

      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('circle')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      // Update the state with the clicked node details
      setClGraph(previousState => ({
        ...previousState,
        clickedNode: d,
        isOpenRightSidebar: true,
      }));

      // Zoom to focus on the clicked node
      const zoomLevel = 2;
      const x = d.x;
      const y = d.y;
      const translateX = window.innerWidth / 2 - x * zoomLevel;
      const translateY = window.innerHeight / 2 - y * zoomLevel;

      const transform = d3.zoomIdentity
        .translate(translateX - 200, translateY)
        .scale(zoomLevel);

      svg.transition().duration(600).call(zoom.transform, transform);

      // Optionally, reset the fixed positions if you don't want to freeze the node
      // d.fx = null;
      // d.fy = null;
    }

    // Handle node click event
    function nodeClicked(event, d) {
      const nodesAndEdgesOnlyForRelashinships = getConnectedNodesAndEdges(
        graphData,
        relationshipEdgesIds
      );

      let b = nodesAndEdgesOnlyForRelashinships.connectedNodes;
      const relationshipNodesList = [...b];

      if (showOnlyRelationships) {
        if (!relationshipNodesList.includes(d?.id)) {
          return;
        }
      }

      if (showShowShareholderINNetwork) {
        if (!d?.isShareholder) {
          return;
        }
      }

      nodeClickedHellper(event, d);
    }

    // show only imortant highlited connected nodes and edges.

    // Handle mouse over event to highlight connections
    function handleMouseOver(event, d) {
      if (!showOnlyRelationships && !showShowShareholderINNetwork) {
        const connectedNodes = new Set();
        const connectedEdges = new Set();

        function findDirectConnections(nodeId) {
          link.each(l => {
            if (
              (l.source.id === nodeId || l.target.id === nodeId) &&
              relationshipEdgesIds.includes(l.edgeId)
            ) {
              connectedEdges.add(l.edgeId);
              connectedNodes.add(l.source.id);
              connectedNodes.add(l.target.id);
            }
          });
        }

        function findConnectedNodesThroughRelationship(nodeId) {
          link.each(l => {
            if (l.source.id === nodeId || l.target.id === nodeId) {
              if (relationshipEdgesIds.includes(l.edgeId)) {
                connectedEdges.add(l.edgeId);
                if (!connectedNodes.has(l.source.id)) {
                  connectedNodes.add(l.source.id);
                  findConnectedNodesThroughRelationship(l.source.id);
                }
                if (!connectedNodes.has(l.target.id)) {
                  connectedNodes.add(l.target.id);
                  findConnectedNodesThroughRelationship(l.target.id);
                }
              }
            }
          });
        }

        let isConnectedWithRelationship = false;
        if (relationshipEdgesIds.length > 0) {
          link.each(l => {
            if (
              (l.source.id === d.id || l.target.id === d.id) &&
              relationshipEdgesIds.includes(l.edgeId)
            ) {
              isConnectedWithRelationship = true;
            }
          });
        }

        if (isConnectedWithRelationship) {
          findConnectedNodesThroughRelationship(d.id);
        } else {
          findDirectConnections(d.id);
        }

        if (connectedNodes?.size > 0) {
          link
            .style('stroke-opacity', l =>
              connectedEdges.has(l.edgeId) ? 1 : 0.1
            )
            .style('stroke-width', l =>
              connectedEdges.has(l.edgeId) ? '3px' : '1px'
            );

          node.selectAll('image').style('opacity', n => {
            return connectedNodes.has(n.id) ? 1 : 0.1;
          });

          node.selectAll('text').style('opacity', n => {
            return connectedNodes.has(n.id) ? 1 : 0.1;
          });
        }
      }
    }

    // Handle mouse out event to reset the graph
    function handleMouseOut() {
      if (!showOnlyRelationships && !showShowShareholderINNetwork) {
        link.style('stroke-opacity', 1).style('stroke-width', '1px');
        node.selectAll('image').style('opacity', 1);
        node.selectAll('text').style('opacity', 1);
      }
    }

    // Reset the graph to its default state
    const resetGraph = () => {
      // Reset all nodes, labels, and links to default opacity and size
      d3.selectAll('circle').style('opacity', 1);
      d3.selectAll('text').style('opacity', 1);
      d3.selectAll('line').style('opacity', 1).style('stroke-width', '2px');

      // Reset zoom and pan (optional if you want to reset the zoom as well)
      svg
        .transition()
        .duration(300)
        .call(zoom.transform, d3.zoomIdentity.scale(1));

      // Clear the searchProbe state to prevent refocusing on the searched node
      setSearchProbe('');

      setCurrentZoomTransform(null);
    };

    // Focus on node if searchProbe matches id or entityName
    if (searchProbe) {
      const matchedNode = graphData.nodes.find(
        node =>
          node.id === searchProbe ||
          (node.entityName &&
            node.entityName.toLowerCase() === searchProbe.toLowerCase())
      );

      if (matchedNode) {
        setIsNodeSearching(true);
        // Trigger the simulation with increased alpha to ensure node positions update
        simulation.alpha(1).restart();

        // Listen for the end of simulation to ensure all nodes are positioned correctly
        simulation.on('end', () => {
          // Highlight the matched node
          node.style('opacity', n => (n.id === matchedNode.id ? 1 : 0.1));
          label.style('display', n => (n.id === matchedNode.id ? 1 : 'none'));
          link.style('opacity', 0.1);

          // Zoom in and center the matched node
          const zoomLevel = 2;
          const x = matchedNode.x;
          const y = matchedNode.y;
          const translateX = width / 2 - x * zoomLevel;
          const translateY = height / 2 - y * zoomLevel;

          const transform = d3.zoomIdentity
            .translate(translateX, translateY)
            .scale(zoomLevel);

          svg.transition().duration(600).call(zoom.transform, transform);

          // Reset the simulation alpha target
          simulation.alphaTarget(0);
          setIsNodeSearching(false);
        });
      }
    }

    if (showOnlyRelationships) {
      const { connectedNodes, connectedEdges } = getConnectedNodesAndEdges(
        graphData,
        relationshipEdgesIds
      );
      highlightOnlyRelationshipNodes(
        link,
        node,
        connectedNodes,
        connectedEdges
      );
    }

    if (showShowShareholderINNetwork) {
      const { connectedNodes, connectedEdges } = getConnectedNodesAndEdges(
        graphData,
        null,
        true
      );

      console.log('here shareholders', connectedNodes);
      console.log('here shareholders', connectedEdges);

      highlightOnlyRelationshipNodes(
        link,
        node,
        connectedNodes,
        connectedEdges
      );
    }

    // Add a click listener to the SVG to reset the graph when clicking on empty space
    svg.on('click', () => {
      if (searchProbe) {
        resetGraph(); // Reset graph when clicking on the SVG (empty space)
      }
    });

    return () => {
      svg.selectAll('*').remove();
    };
  }, [
    selectedGraph,
    searchProbe,
    selectedEntityType,
    searchedEntitiesInGraph,
    showNationalityINNetwork,
    showOnlyRelationships,
    showLiabilitiesINNetworkGraph,
    showDebthRatioINNetworkGraph,
    showShowShareholderINNetwork,
    showStatusINNetworkGraph,
  ]);

  return (
    <div>
      <div className="cl-graph-searchinput-main">
        <div className="cl-graph-LeftContainer">
          <div
            onClick={goBackToSearchEntitiesPage}
            className="cl-graph-LeftButton-main"
          >
            <img
              src={BackIcon}
              alt="Icon"
              className="cl-graph-LeftButton-main-icon"
            />
            New Network
          </div>
          {clGraph?.graph?.map((graph, index) => (
            <div
              key={index}
              className={`cl-graph-LeftButton-main${
                index === activeIndex ? '-active' : ''
              }`}
              onClick={() => handleSelectItem(index)}
            >
              {getNodeNameById(graph?.nodes, graph?.meta?.selectedEntity?.ids)}
            </div>
          ))}
          <div
            onClick={exportSelectedGraph}
            className="cl-graph-LeftButton-main"
          >
            <img
              src={ExportIcon}
              alt="Icon"
              className="cl-graph-LeftButton-export-icon"
            />
            Export
          </div>
          <div
            onClick={toggleNGGraph}
            className={`cl-graph-LeftButton-main ${
              ngTasks[0]?.result?.graphs?.length > 0
                ? 'cl-graph-LeftButton-main-bg-light-green'
                : 'cl-graph-LeftButton-main-bg-red'
            }`}
            title={
              ngTasks[0]?.status === 'queued'
                ? 'Finding Relationships using Deep AI Search'
                : ngTasks[0]?.result?.graphs?.length > 0
                  ? 'Relation Found Through AI'
                  : 'No Relation Found Through AI'
            }
          >
            <img
              src={ToolBarIcon}
              alt="Icon"
              className={`cl-graph-LeftButton-export-icon ${
                ngTasks[0]?.status === 'queued'
                  ? 'cl-graph-LeftButton-spinning-blink'
                  : ''
              }`}
            />
            <span>AI Graph</span>
          </div>
        </div>
        <div className="cl-graph-RightContainer">
          <div
            onClick={goBackToSearchEntitiesPage}
            className="cl-graph-RightContainer-back-button"
          >
            <img src={backArrow} alt="" />
          </div>

          <CLSearchInput
            nodes={selectedGraph && selectedGraph?.nodes}
            onSuggestionSelect={onSuggestionSelect}
            isNodeSearching={isNodeSearching}
          />
          {/* <div className="connection-level-container">
            <div className="connection-level-header" onClick={toggleSlider}>
              Connections Level
              <img
                src={arrowDown}
                alt="Icon"
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </div>
            {showSlider && (
              <div className="connection-level-slider-container">
                Level 1
                <input
                  type="range"
                  min="1"
                  max="3"
                  defaultValue="1"
                  className="connection-level-slider-vertical"
                  orient="vertical" // Key for vertical slider in some browsers
                />
              </div>
            )}
          </div> */}
        </div>
        <div className="cl-graph-selected-entities-mobile-view">
          {clGraph?.graph?.map((graph, index) => (
            <div
              key={index}
              className={`cl-graph-selected-entity-mobile-view ${
                index === activeIndex ? 'entity-active' : ''
              }`}
              onClick={() => handleSelectItem(index)}
            >
              {getNodeNameById(graph?.nodes, graph?.meta?.selectedEntity?.ids)}
            </div>
          ))}
        </div>
      </div>
      <span></span>
      <svg ref={svgRef} className="cl-graph-d3-svg"></svg>
      {graph && (
        <div>
          <div className="cl-graph-rightsidebarmenu-main">
            <BottomMenu
              zoomIn={zoomIn}
              zoomValue={zoomValue}
              zoomOut={zoomOut}
              isSidebarHidden={isSidebarHidden}
              setIsFilterOpened={setIsFilterOpened}
              exportSelectedGraph={exportSelectedGraph}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: 20,
            }}
            className="cl-graph-leftsidebar-itemholder"
          >
            <CLGrapgLeftSidebarItemHolder
              setLeftSideButtonType={setLeftSideButtonType}
              leftSideButtonType={leftSideButtonType}
              isLoadingShareholders={isLoadingShareholders}
              setIsSidebarHidden={setIsSidebarHidden}
            />
          </div>
          <div
            style={{
              display: 'none',
            }}
            className="cl-graph-mobile-view cl-graph-total-dropdown"
          >
            <CLGraphTotalDropdown
              graphList={graph}
              setSelectedGraph={setSelectedGraph}
            />
          </div>
          {/* <div
            style={{
              position: 'absolute',
              bottom: 90,
              right: 10,
            }}
          >
            <div className="cl-graph-mobile-view">
              <ProbeButton
                width={50}
                height={50}
                iconSrc={<ArrowBackIcon />}
                iconType={'svg'}
                borderRadius={50}
                onClick={goBackToSearchEntitiesPage}
              />
            </div>
          </div> */}
        </div>
      )}
      {isFilterOpened && (
        <div className="cl-graph-modal-container">
          <div className="cl-graph-modal-header">
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
              Filters
            </span>
            <span
              style={{ fontSize: '18px', fontWeight: 'bold' }}
              onClick={() => setIsFilterOpened(false)}
            >
              x
            </span>
          </div>
          {/* Filters */}
          <div className="cl-graph-modal-filters">
            {filterOptions.map((filter, index) => (
              <label
                className={`cl-graph-modal-filter ${selectedFilter === filter.text ? 'selected' : ''}`}
                key={index}
                onClick={() => setSelectedFilter(filter.text)}
              >
                <img
                  src={
                    selectedFilter === filter.text
                      ? filter.highlightedIcon
                      : filter.icon
                  }
                  alt="icon"
                  className="filter-icon"
                />
                {filter.text}
              </label>
            ))}
          </div>

          {/* Filter Button */}
          <button
            className="cl-graph-modal-filter-button"
            style={{
              background: selectedFilter
                ? 'linear-gradient(to bottom right, #0466D1, #00D1A9)'
                : '#CFCFCF',
              cursor: selectedFilter ? 'pointer' : 'not-allowed',
              transition: 'background 0.3s ease',
            }}
            disabled={!selectedFilter} // Disable if no filter is selected
            onClick={handleFilterButtonClick}
          >
            Filter
          </button>
        </div>
      )}
    </div>
  );
};

export default CLGraph;
