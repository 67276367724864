import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';
import {
  calculateAge,
  getOfficerLocationString,
  processRegisteredOfficeAddress,
  processRegisteredOfficeAddressObject,
} from '../../utils/helper';

export const getAutoCompleteOptions = async searchQuery => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.autoComplete
      ),
      { params: { limit: 20, search: searchQuery } }
    );
    const updatedData = response.data.map(item => {
      return { label: item.company_name, value: item };
    });
    return updatedData;
  } catch (error) {
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};

export const explorerFilter = async (
  requestBody,
  pageNumber = 0,
  size = 20,
  searchAfter = null,
  totalRecords
) => {
  try {
    if (!requestBody) return [];

    const response = await axios.post(
      `${apiConfig.base}${apiConfig.corporateExplorer.endpoints.explorerFilter}`,
      requestBody,
      {
        params: {
          page_number: pageNumber,
          size: size,
          search_after: JSON.stringify(searchAfter),
          total_records: totalRecords,
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const explorerFilterOfficers = async (requestBody, size = 20) => {
  try {
    if (!requestBody) return [];

    const response = await axios.post(
      `${apiConfig.base}${apiConfig.corporateExplorer.endpoints.explorerFilterOfficers}`,
      requestBody,
      {
        params: {
          size: size,
        },
      }
    );

    return response?.data;
  } catch (error) {}
};

export const getTotalDocumentsOfIndex = async indexName => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getTotalDocumentsOfIndex
      )
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getCompanyInfo = async companyNumber => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.corporateExplorerCompanyInfo
      ),
      { params: { company_number: companyNumber } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const corporateExplorerOfficerInfo = async companyNumber => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.corporateExplorerOfficerInfo
      ),
      { params: { company_number: companyNumber } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const companiesRegisteredLocationAutoSearch = async query => {
  if (!query?.length > 0) return;
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints
          .companiesRegisteredLocationAutoSearch
      ),
      { params: { searchQuery: query, limit: 10 } }
    );

    return { success: true, data: response?.data };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const corporateExplorerShareholders = async companyNumber => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getCompanyShareholders
      ),
      {
        params: { company_number: companyNumber },
        headers: {
          'API-KEY': process.env.REACT_APP_DUMMY_API_KEY,
        },
      }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const corporateExplorerGetFinancialDocuments = async companyNumber => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints
          .corporateExplorerGetFinancialDocuments
      ),
      {
        params: { company_number: companyNumber },
      }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getUseableDocumentUrl = async (documentUrl, docType = null) => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getUseableDocumentUrl
      ),
      {
        documentUrl: documentUrl,
        docType: docType,
      }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getCompanyTradeMarkByName = async name => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getCompanyTradeMarkByName
      ),
      { params: { name: name } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getCompanyProperty = async (name, size) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getCompanyProperty
      ),
      { params: { name: name, size: size } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getCompanyInfoByCompanyNumber = async company_number => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getCompanyInfoByCompanyNumber
      ),
      { params: { company_number: company_number } }
    );
    let mappedCompanyData = {};
    mappedCompanyData = {
      id: response?.data?.companyInfo?._id,
      title: response?.data?.companyInfo?.company_name,
      address: processRegisteredOfficeAddress(response?.data?.companyInfo),
      addressObj: processRegisteredOfficeAddressObject(
        response?.data?.companyInfo
      ),
      date_of_creation: response?.data?.companyInfo?.date_of_creation,
      company_status: response?.data?.companyInfo?.company_status,
      company_number: response?.data?.companyInfo?.company_number,
      confirmation_statement:
        response?.data?.companyInfo?.confirmation_statement,
      accounts: response?.data?.companyInfo?.accounts,
      sic_codes: response?.data?.companyInfo?.sic_codes,
      sic_codes_object: response?.data?.companyInfo?.sic_codes_object,
      type: response?.data?.companyInfo?.type,
      foreign_company_details:
        response?.data?.companyInfo?.foreign_company_details,
      external_registration_number:
        response?.data?.companyInfo?.external_registration_number,
    };

    return {
      companyInfo: mappedCompanyData,
      accountInfo: response?.data?.accountsInfo,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getOfficerInfoByCompanyNumber = async officer_number => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getOfficerInfoByCompanyNumber
      ),
      { params: { officer_number: officer_number } }
    );

    let mappedResponse = {};

    let resOne = response?.data[0];

    const getValidValue = value => (value?.trim() !== '' ? value : '-');

    mappedResponse = {
      bio: {
        url: 'https://via.placeholder.com/40',
        name: getValidValue(resOne?.name),
        occupation: getValidValue(resOne?.occupation),
        location: getValidValue(getOfficerLocationString(resOne?.address)),
      },
      insights: [
        {
          header: 'Personal information',
          items: [
            { key: 'First Name', value: getValidValue(resOne?.name) },
            { key: 'Officer Role', value: getValidValue(resOne?.officer_role) },
            {
              key: 'Date Of Birth',
              value: getValidValue(calculateAge(resOne?.date_of_birth)),
            },
            {
              key: 'Nationality',
              value: getValidValue(resOne?.nationality),
            },
          ].filter(item => item.value !== '-'),
        },
        {
          header: 'Address',
          items: [
            { key: 'Country', value: getValidValue(resOne?.address?.country) },
            {
              key: 'Address Line 1',
              value: getValidValue(resOne?.address?.address_line_1),
            },
            {
              key: 'Address Line 2',
              value: getValidValue(resOne?.address?.address_line_2),
            },
            {
              key: 'Postal Code',
              value: getValidValue(resOne?.address?.postal_code),
            },
          ].filter(item => item.value !== '-'),
        },
      ],
    };

    return mappedResponse;
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getRelationShips = async payLoad => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_NG_SERVICE,
      payLoad
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getPersonInsights = async payLoad => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getPersonInsights
      ),
      payLoad
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getNetworkGraphByUserId = async (userId, pageNumber, pageSize) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getNetworkGraphByUserId
      ),
      { params: { userId: userId, pageNumber: pageNumber, pageSize: pageSize } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const deleteNetworkGraphByUserIdAndDocumentId = async (
  userId,
  documentId
) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints
          .deleteNetworkGraphByUserIdAndDocumentId
      ),
      { params: { user_id: userId, document_id: documentId } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getCompaniesOrOfficersByName = async name => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getCompaniesOrOfficersByName
      ),
      { params: { name: name } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const saveNetworkGraphQueryByUserId = async payLoad => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.saveNetworkGraphQueryByUserId
      ),
      payLoad
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getNetworkGraphQueryByUserId = async (
  userId,
  pageNumber,
  pageSize
) => {
  try {
    const response = await axios.get(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints.getNetworkGraphQueryByUserId
      ),
      { params: { userId: userId } }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getShareholdersOfCompaniesInNetworkGraph = async payLoad => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints
          .getShareholdersOfCompaniesInNetworkGraph
      ),
      { graph: payLoad }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getCompaniesFinancialsByCompanyNumbers = async payLoad => {
  try {
    const response = await axios.post(
      buildUrl(
        apiConfig.base,
        apiConfig.corporateExplorer.endpoints
          .getCompaniesFinancialsByCompanyNumbers
      ),
      { company_numbers: payLoad }
    );

    return response?.data;
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getProcessedNetworkgraphByCombination =
  async combinationPayload => {
    try {
      const response = await axios.post(
        buildUrl(
          apiConfig.base,
          apiConfig.corporateExplorer.endpoints
            .getProcessedNetworkgraphByCombination
        ),
        combinationPayload
      );

      return response?.data;
    } catch (error) {
      return [];
    }
  };
