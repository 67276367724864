import React, { useState, useContext, useEffect, useMemo } from 'react';
import './CESAdvancedFilterPerson.css';
import DateRangeSlider from '../CESAdvancedFilter/CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilterPerson = ({
  inputValue,
  setInputValue,
  tempFilterPerson,
  setTempFilterPerson,
  suggestions,
  minDate,
  maxDate,
}) => {
  const {
    corporateLinksAdvanceFilters,
    setCorporateLinksAdvanceFilters,
    corporateLinksDynamicFilters,
    setCorporateLinksDynamicFilters,
  } = useContext(AppContext);

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const [advanceFilterPerson, setAdvanceFilterPerson] = useState({
    nationality: '',
    occupation: '',
    officer_role: '',
    address: '',
    postal_code: '',
    age: [minDate, maxDate],
  });

  // Get filtered suggestions based on selected values
  const filteredSuggestions = useMemo(() => {
    if (!suggestions || !Array.isArray(suggestions)) {
      return [];
    }

    let filtered = [...suggestions];

    // Filter based on selected occupation
    if (tempFilterPerson.occupation && tempFilterPerson.occupation !== 'Search Occupation here') {
      filtered = filtered.filter(item => 
        item.occupation === tempFilterPerson.occupation
      );
    }

    // Filter based on selected position role
    if (tempFilterPerson.officer_role && tempFilterPerson.officer_role !== 'Current Position') {
      filtered = filtered.filter(item => 
        item.officer_role === tempFilterPerson.officer_role
      );
    }

    // Filter based on selected nationality
    if (tempFilterPerson.nationality && tempFilterPerson.nationality !== 'Nationality') {
      filtered = filtered.filter(item => 
        item.nationality === tempFilterPerson.nationality
      );
    }

    return filtered;
  }, [suggestions, tempFilterPerson.occupation, tempFilterPerson.officer_role, tempFilterPerson.nationality]);

  // Extract unique dropdown options based on filtered suggestions
  const dropdownOptions = useMemo(() => {
    // Handle initial full list of options when no filters are applied
    const allSuggestions = suggestions || [];
    
    // Get all unique values for each dropdown
    const allOccupations = new Set(
      allSuggestions
        .filter(item => item && item.occupation)
        .map(item => item.occupation)
    );
    
    const allofficer_roles = new Set(
      allSuggestions
        .filter(item => item && item.officer_role)
        .map(item => item.officer_role)
    );
    
    const allNationalities = new Set(
      allSuggestions
        .filter(item => item && item.nationality)
        .map(item => item.nationality)
    );

    // Get filtered values based on current selections
    const filteredOccupations = new Set(
      filteredSuggestions
        .filter(item => item && item.occupation)
        .map(item => item.occupation)
    );
    
    const filteredofficer_roles = new Set(
      filteredSuggestions
        .filter(item => item && item.officer_role)
        .map(item => item.officer_role)
    );
    
    const filteredNationalities = new Set(
      filteredSuggestions
        .filter(item => item && item.nationality)
        .map(item => item.nationality)
    );

    // Determine which options to show for each dropdown
    const occupations = tempFilterPerson.occupation && 
                      tempFilterPerson.occupation !== 'Search Occupation here' ? 
                      Array.from(allOccupations) : 
                      Array.from(filteredOccupations);
    
    const officer_roles = tempFilterPerson.officer_role && 
                        tempFilterPerson.officer_role !== 'Current Position' ? 
                        Array.from(allofficer_roles) : 
                        Array.from(filteredofficer_roles);
    
    const nationalities = tempFilterPerson.nationality && 
                        tempFilterPerson.nationality !== 'Nationality' ? 
                        Array.from(allNationalities) : 
                        Array.from(filteredNationalities);
    
    return {
      occupations,
      officer_roles,
      nationalities
    };
  }, [suggestions, filteredSuggestions, tempFilterPerson.occupation, tempFilterPerson.officer_role, tempFilterPerson.nationality]);

  // Update temp filters
  const handleTempChange = e => {
    const { name, value } = e.target;

    // Update the tempFilterPerson state
    setTempFilterPerson(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [name]: value,
      };
      
      // Reset other filters if the default option is selected
      if (
        (name === 'occupation' && value === 'Search Occupation here') ||
        (name === 'officer_role' && value === 'Current Position') ||
        (name === 'nationality' && value === 'Nationality')
      ) {
        updatedFilters[name] = value;
      }
      
      return updatedFilters;
    });

    // If the input is for the "name" field, update the inputValue state
    if (name === 'name') {
      setInputValue(value);
    }
  };

  const handleTempDateRangeChange = newRange => {
    setTempFilterPerson({
      ...tempFilterPerson,
      age: newRange,
    });
  };

  const applyFilters = () => {
    const filteredTempFilterPerson = {
      ...tempFilterPerson,
      occupation:
        tempFilterPerson.occupation === 'Search Occupation here'
          ? ''
          : tempFilterPerson.occupation,
      nationality:
        tempFilterPerson.nationality === 'Nationality'
          ? ''
          : tempFilterPerson.nationality,
      officer_role:
        tempFilterPerson.officer_role === 'Current Position'
          ? ''
          : tempFilterPerson.officer_role,
      address: tempFilterPerson.address || '',
      postal_code: tempFilterPerson.postal_code || '',
    };

    // Apply the filtered values
    setAdvanceFilterPerson(filteredTempFilterPerson);
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: filteredTempFilterPerson,
    });
  };

  useEffect(() => {
    const isAnyFieldFilled =
      tempFilterPerson.name ||
      (tempFilterPerson.nationality && tempFilterPerson.nationality !== 'Nationality') ||
      (tempFilterPerson.occupation && tempFilterPerson.occupation !== 'Search Occupation here') ||
      (tempFilterPerson.officer_role && tempFilterPerson.officer_role !== 'Current Position') ||
      tempFilterPerson.address ||
      tempFilterPerson.postal_code ||
      tempFilterPerson.age[0] !== minDate ||
      tempFilterPerson.age[1] !== maxDate;

    setIsSearchEnabled(isAnyFieldFilled);
  }, [tempFilterPerson, minDate, maxDate]);

  return (
    <div>
      <div className="company-person-advance-filter-row">
        <div className="company-person-advance-filter-col">
          <label htmlFor="name" className="form-label filter-label">
            Person Name
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="name"
            placeholder="Fullname"
            name="name"
            value={inputValue}
            onChange={handleTempChange}
            disabled
          />
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="occupation" className="form-label filter-label">
            Occupation
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="occupation"
            name="occupation"
            value={tempFilterPerson.occupation}
            onChange={handleTempChange}
          >
            <option>Search Occupation here</option>
            
            {/* Show filtered occupations */}
            {dropdownOptions.occupations.map((occupation, index) => (
              <option key={`occupation-${index}`}>{occupation}</option>
            ))}
          </select>
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="officer_role" className="form-label filter-label">
            Officer Role
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="officer_role"
            name="officer_role"
            value={tempFilterPerson.officer_role}
            onChange={handleTempChange}
          >
            <option>Current Position</option>

            {/* Show filtered position roles */}
            {dropdownOptions.officer_roles.map((officer_role, index) => (
              <option key={`position-${index}`}>{officer_role}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="company-person-advance-filter-row">
        <div className="company-person-advance-filter-col">
          <label htmlFor="address" className="form-label filter-label">
            Address
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="address"
            placeholder="Address"
            name="address"
            value={tempFilterPerson.address}
            onChange={handleTempChange}
          />
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="nationality" className="form-label filter-label">
            Nationality:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="nationality"
            name="nationality"
            value={tempFilterPerson.nationality}
            onChange={handleTempChange}
          >
            <option>Nationality</option>
            
            {/* Show filtered nationalities */}
            {dropdownOptions.nationalities.map((nationality, index) => (
              <option key={`nationality-${index}`}>{nationality}</option>
            ))}
          </select>
        </div>
        <div className="company-person-advance-filter-col">
          <label htmlFor="postal_code" className="form-label filter-label">
            Postal Code
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postal_code"
            placeholder="Post Code"
            name="postal_code"
            value={tempFilterPerson.postal_code}
            onChange={handleTempChange}
          />
        </div>
      </div>
      <div className="company-person-advance-filter-col">
        <div>
          <DateRangeSlider
            labelName={'Age'}
            values={tempFilterPerson.age}
            setValues={handleTempDateRangeChange}
            minDate={minDate}
            maxDate={maxDate}
          />
        </div>
      </div>

      <div className="col-md-12 mt-3">
        <div className="person-filter-search">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={
              isSearchEnabled
                ? 'linear-gradient(to right, #0466D1, #00D1A9)'
                : '#8A8A8A'
            }
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={isSearchEnabled ? false : true}
            onClick={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilterPerson;