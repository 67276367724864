import { Outlet } from 'react-router';
import UserProfileSideMenu from '../../components/UserProfile/SideMenu/UserProfileSideMenu';
import './UserProfileLayout.css';

const UserProfileLayout = () => {
  return (
    <div className="side-menu-layout">
      <UserProfileSideMenu />
      <div className="side-menu-content">
        <Outlet />
      </div>
    </div>
  );
};

export default UserProfileLayout;
