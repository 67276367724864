import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilter.css';
import DateRangeSlider from './CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilter = ({
  inputValue,
  setInputValue,
  tempFilterCompany,
  setTempFilterCompany,
  minCessationDate,
  maxCessationDate,
  minCreationDate,
  maxCreationDate,
  currentItems,
}) => {
  const {
    corporateLinksAdvanceFilters,
    setCorporateLinksAdvanceFilters,
    corporateLinksDynamicFilters,
  } = useContext(AppContext);

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [availableCompanyTypes, setAvailableCompanyTypes] = useState([]);
  const [availableCompanyStatuses, setAvailableCompanyStatuses] = useState([]);
  
  // Extract unique company statuses and types from currentItems
  useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      // Get unique statuses
      const uniqueStatuses = [...new Set(currentItems
        .map(item => item.company_status)
        .filter(status => status))];
        
      // Get unique company types
      const uniqueTypes = [...new Set(currentItems
        .map(item => item.company_type)
        .filter(type => type))];
        
      setAvailableCompanyStatuses(uniqueStatuses);
      setAvailableCompanyTypes(uniqueTypes);
    }
  }, [currentItems]);
  
  // Filter options based on dependent selections
  useEffect(() => {
    if (currentItems && currentItems.length > 0) {
      let filteredItems = [...currentItems];
      
      // Filter items based on selected company status
      if (tempFilterCompany?.company_status && tempFilterCompany.company_status !== 'Status') {
        filteredItems = filteredItems.filter(
          item => item.company_status?.toLowerCase() === tempFilterCompany.company_status.toLowerCase()
        );
      }
      
      // Filter items based on selected company type
      if (tempFilterCompany?.company_type && tempFilterCompany.company_type !== 'Type') {
        filteredItems = filteredItems.filter(
          item => item.company_type === tempFilterCompany.company_type
        );
      }
      
      // Update available options based on filtered items
      if (tempFilterCompany?.company_status && tempFilterCompany.company_status !== 'Status') {
        // If status is selected, filter available types
        const filteredTypes = [...new Set(filteredItems
          .map(item => item.company_type)
          .filter(type => type))];
        setAvailableCompanyTypes(filteredTypes);
      } else {
        // Reset to all available types
        const allTypes = [...new Set(currentItems
          .map(item => item.company_type)
          .filter(type => type))];
        setAvailableCompanyTypes(allTypes);
      }
      
      if (tempFilterCompany?.company_type && tempFilterCompany.company_type !== 'Type') {
        // If type is selected, filter available statuses
        const filteredStatuses = [...new Set(filteredItems
          .map(item => item.company_status)
          .filter(status => status))];
        setAvailableCompanyStatuses(filteredStatuses);
      } else {
        // Reset to all available statuses
        const allStatuses = [...new Set(currentItems
          .map(item => item.company_status)
          .filter(status => status))];
        setAvailableCompanyStatuses(allStatuses);
      }
    }
  }, [currentItems, tempFilterCompany?.company_status, tempFilterCompany?.company_type]);

  const handleSelectChange = e => {
    const { name, value } = e.target;
    if (name === 'title') {
      setInputValue(value);
    }

    // Convert value to lowercase if the field is 'company_status'
    const updatedValue =
      name === 'company_status' ? value.toLowerCase() : value;

    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      [name]: updatedValue,
    }));
  };

  const handleCreationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      creationDateRange: newRange,
    }));
  };

  const handleCessationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      cessationDateRange: newRange,
    }));
  };

  useEffect(() => {
    const isAnyFieldSelected =
      (tempFilterCompany?.company_status && tempFilterCompany?.company_status !== 'Status') ||
      tempFilterCompany?.company_number !== '' ||
      tempFilterCompany?.title !== '' ||
      tempFilterCompany?.nationality !== '' ||
      tempFilterCompany?.postal_code !== '' ||
      tempFilterCompany?.creationDateRange[0] !== minCreationDate ||
      tempFilterCompany?.creationDateRange[1] !== maxCreationDate ||
      tempFilterCompany?.cessationDateRange[0] !== minCessationDate ||
      tempFilterCompany?.cessationDateRange[1] !== maxCessationDate ||
      (tempFilterCompany?.company_type && tempFilterCompany?.company_type !== 'Type');

    // Set the search enabled state
    setIsSearchEnabled(isAnyFieldSelected);
  }, [tempFilterCompany, minCreationDate, maxCreationDate, minCessationDate, maxCessationDate]);

  const applyFilters = () => {
    // Create a new filters object excluding default values
    const filtersToApply = {
      ...tempFilterCompany,
      company_status:
        tempFilterCompany.company_status === 'Status'
          ? undefined
          : tempFilterCompany.company_status,
      company_type:
        tempFilterCompany.company_type === 'Type'
          ? undefined
          : tempFilterCompany.company_type,
      nationality:
        tempFilterCompany.nationality === ''
          ? undefined
          : tempFilterCompany.nationality,
      company_number: tempFilterCompany.company_number || '',
      postal_code: tempFilterCompany.postal_code || '',
    };

    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      companyFilters: filtersToApply,
    });
  };

  const resetFilters = () => {
    setTempFilterCompany({
      company_status: 'Status',
      company_type: 'Type',
      company_number: '',
      postal_code: '',
      nationality: '',
      creationDateRange: [minCreationDate, maxCreationDate],
      cessationDateRange: [minCessationDate, maxCessationDate]
    });
    setInputValue('');
  };

  return (
    <div className="company-advance-filter-container">
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label htmlFor="company_number" className="form-label filter-label">
            Company Number
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="company_number"
            placeholder="Company Number"
            name="company_number"
            value={tempFilterCompany?.company_number}
            onChange={handleSelectChange}
          />
        </div>
        <div className="company-advance-filter-col">
          <label htmlFor="postal_code" className="form-label filter-label">
            Postal Code
          </label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postal_code"
            placeholder="Post Code"
            name="postal_code"
            value={tempFilterCompany?.postal_code}
            onChange={handleSelectChange}
          />
        </div>
      </div>

      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label htmlFor="company_status" className="form-label filter-label">
            Company Status:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_status"
            name="company_status"
            value={tempFilterCompany?.company_status}
            onChange={handleSelectChange}
          >
            <option>Status</option>
            {availableCompanyStatuses.map((companyStatus, index) => (
              <option key={`status-${index}`} value={companyStatus}>
                {companyStatus.charAt(0).toUpperCase() + companyStatus.slice(1)}
              </option>
            ))}
          </select>
        </div>
        <div className="company-advance-filter-col">
          <label htmlFor="company_type" className="form-label filter-label">
            Company Type:
          </label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_type"
            name="company_type"
            value={tempFilterCompany?.company_type}
            onChange={handleSelectChange}
          >
            <option>Type</option>
            {availableCompanyTypes.map((companyType, index) => (
              <option key={`type-${index}`}>{companyType}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Creation Period'}
            values={tempFilterCompany.creationDateRange}
            setValues={handleCreationDateRangeChange}
            minDate={minCreationDate}
            maxDate={maxCreationDate}
          />
        </div>
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Cessation Period'}
            values={tempFilterCompany.cessationDateRange}
            setValues={handleCessationDateRangeChange}
            minDate={minCessationDate}
            maxDate={maxCessationDate}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-center gap-3">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={
              isSearchEnabled
                ? 'linear-gradient(to right, #0466D1, #00D1A9)'
                : '#8A8A8A'
            }
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={!isSearchEnabled}
            onClick={applyFilters}
          />
          <ProbeButton
            width="120px"
            height="40px"
            name="Reset"
            borderRadius="24px"
            backgroundColor="transparent"
            fontColor="#8A8A8A"
            borderColor="#8A8A8A"
            onClick={resetFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilter;