import React, { useEffect } from 'react';
import './CEIPersonInsightsBio.css'; // Import the CSS file
import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonImg from '../../../../assets/images/icons/PersonImg.png'

const CEIPersonInsightsBio = ({ details }) => {
  const ccjReport = detail => {
    if (detail?.details) {
      const baseUrl = window.location.origin;
      const address = encodeURIComponent(
        JSON.stringify(detail?.details?.address)
      );
      const intro = encodeURIComponent(JSON.stringify(detail?.intro));
      const newPath = '/ccj-dashboard/judgement-checker-person';
      const newUrl = `${baseUrl}${newPath}?intro=${intro}&address=${address}&auto-click=true`;
      window.open(newUrl, '_blank');
    }
  };

  return (
    <div className="corporate-explore-person-insights-bio">
      <div>
        <div className="corporate-explore-insights-person-icon ">
          <img src={PersonImg} alt="person" />
        </div>
      </div>

      <h2 className="corporate-explore-insights-person-name">
        {details?.intro?.name}
      </h2>
      {details?.details?.occupation && (
        <p className="corporate-explore-insights-person-details">
          {details.details.occupation}
        </p>
      )}

      {(details?.details?.country_of_residence || details?.details?.date_of_birth) && (
        <p className="corporate-explore-insights-person-details">
          {details.details.country_of_residence && `Resides in ${details.details.country_of_residence}`}
          {details.details.country_of_residence && details.details.date_of_birth && " | "}
          {details.details.date_of_birth && `DOB: ${details.details.date_of_birth}`}
        </p>
      )}
      <button
        className="corporate-explore-insights-person-report-btn"
        onClick={() => ccjReport(details)}
        style={{ marginLeft: '0px' }}
      >
        <FontAwesomeIcon icon={faFileAlt} style={{ marginRight: '5px' }} />
        CCJ Report
      </button>
    </div>
  );
};

export default CEIPersonInsightsBio;
