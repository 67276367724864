import './CorporateExplorerFilter.css';
import React, { useEffect, useState } from 'react';
import FilterModal from './FilterModal';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ProbeTooltip from '../ProbeToolTip/ProbeToolTip';

function CorporateExplorerFilter({
  totalCompaniesDocument,
  applyFilters,
  toggleModal,
  showModal,
  setShowModal,
  locationSearchInput,
  locationSearchResults,
  setFilterReadyToApply,
  filterReadyToApply,
}) {
  return (
    <div>
      <div className="corporate_explorer_filter_button" onClick={toggleModal}>
        <div className="corporate_explorer_filter_button_text">
          <FilterListIcon />
          <div>Apply filters</div>
        </div>
      </div>

      {showModal && (
        <FilterModal
          showModal={showModal}
          setShowModal={setShowModal}
          filterReadyToApply={filterReadyToApply}
          setFilterReadyToApply={setFilterReadyToApply}
          totalCompaniesDocument={totalCompaniesDocument}
          applyFilters={applyFilters}
          locationSearchInput={locationSearchInput}
          locationSearchResults={locationSearchResults}
        />
      )}
    </div>
  );
}

export default CorporateExplorerFilter;
