import React, { useEffect, useState } from 'react';
import './CEIDisableAndSignificantControl.css'; // Import your CSS file for styling
import { getPscByCompanyNumber } from '../../../api/psc/financialAccounts';
import PscItem from '../../CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/PscItem';
import peoplesIcon from '../../../assets/images/icons/PeoplesIcon.png'

const CEIDisableAndSignificantControl = ({
  title,
  message,
  icon,
  people,
  buttonText,
  selectedCompany = null,
}) => {
  const [pscData, setPscData] = useState([]);
  useEffect(() => {
    const fetchPscData = async () => {
      if (selectedCompany?.company_number) {
        try {
          const response = await getPscByCompanyNumber(
            selectedCompany.company_number
          );
          if (response.success !== false) {
            const filteredData = response.filter(item =>
              item.hasOwnProperty('name')
            );

            setPscData(filteredData);
          } else {
            console.error('Failed to fetch PSC data:', response);
            setPscData([]);
          }
        } catch (error) {
          console.error('Error in fetchPscData:', error);
        }
      }
    };

    fetchPscData();
  }, [selectedCompany]);

  return (
    <div className="content-People" id="tiles_People">
      <div className="tiles-x">
        <span className="people-close-icon"></span>
      </div>
      {/* <div className="title"> {people}</div> */}
      <h2 className="contact-card-title"><img style={{ marginRight: '10px' }} src={peoplesIcon} alt="icon" />{people}</h2>
      <div className="upgrade-notice">
        <div className="upgrade-box">
          {!pscData && (
            <div
              className="icon"
              style={{ backgroundImage: `url(${icon})` }}
            ></div>
          )}
          {/* <div className="icon"></div> */}
          {pscData && <PscItem pscData={pscData} />}
          {pscData?.length === 0 && (
            <div className="message">
              <strong>{title}</strong>
              <p>{message}</p>
              <button className="plans-button" disabled={true}>
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CEIDisableAndSignificantControl;
