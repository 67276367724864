import React from 'react';
import './CIEDocument.css';
import { getUseableDocumentUrl } from '../../../api/corporateExplorer/corporateExplorer';
import peoplesIcon from '../../../assets/images/icons/docIconPerson.png';
import documentIcon from '../../../assets/images/icons/docIconDoc.png';
import fileCheck from '../../../assets/images/icons/docIconDocCheck.png';
import personActivity from '../../../assets/images/icons/docIconProfile.png';
import resolutionIcon from '../../../assets/images/icons/resolutionIcon.png';
import MutualPeople from '../../../assets/images/icons/docIconPersons.png';

const categoryIcons = {
  resolution: resolutionIcon,
  capital: documentIcon,
  "confirmation-statement": fileCheck, // Use quotes for keys with hyphens
  accounts: personActivity,
  officers: MutualPeople,
  default: peoplesIcon
};


const CIEDocRow = ({
  doc,
  setIsDocumentUrlGenerating,
  isDocumentUrlGenerating,
  setShowLoadingForDocument
}) => {
  const viewFinancialDocument = async doc => {
    setShowLoadingForDocument(true);
    try {
      setIsDocumentUrlGenerating(true);
      let documenturl = await getUseableDocumentUrl(doc?.links?.document_metadata);
      window.open(documenturl, '_blank');
      setIsDocumentUrlGenerating(false);
    } catch (error) {
      setIsDocumentUrlGenerating(false);
    }
    setShowLoadingForDocument(false);
  };


  const category = doc?.category?.toLowerCase();
  const iconSrc = categoryIcons[category] || categoryIcons.default;

  return (
    <div className="coporate-Insights-Document-incorporation-container">
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <span className="file-icon">
          <img src={iconSrc} alt="icon" />
        </span>
        <div>
          <b className="coporate-Insights-Document-incorporation">
            {category ? category.charAt(0).toUpperCase() + category.slice(1) : ''}
          </b>
          <div className="desc">{doc?.date}</div>
        </div>
      </div>
      <div
        className="coporate-Insights-plan-btn"
        onClick={() => {
          if (!isDocumentUrlGenerating) {
            viewFinancialDocument(doc);
          }
        }}
      >
        VIEW PDF
      </div>
    </div>
  );
};

export default CIEDocRow;
