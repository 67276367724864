import React from 'react';
import TableLoadingScreen from '../../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import mutualPeople from '../../../../assets/images/icons/MutualPeople.png'
import '../CompanyInfoModal.css';
import PeopleAndContactItem from './PeopleAndContactItem';

function PeopleAndContacts({
  companyOfficersInfo,
  isLoading,
  showCard = true,
}) {
  return (
    <div>
      {Array.isArray(companyOfficersInfo) && (
        <div className={showCard && 'company-info-modal-people-contant-main'}>
          <div
            className="company-info-modal-overview-main"
            style={{ padding: !showCard && '0px', gap: !showCard && '2px' }}
          >
            <h2 className="contact-card-title"><img style={{ marginRight: '10px' }} src={mutualPeople} alt="icon" />Directors</h2>
            {isLoading === false ? (
              <div>
                {companyOfficersInfo &&
                  companyOfficersInfo?.map(officer => (
                    <PeopleAndContactItem officer={officer} />
                  ))}
              </div>
            ) : (
              <TableLoadingScreen />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default PeopleAndContacts;
