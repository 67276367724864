import React, { useContext, useEffect, useState, useMemo } from 'react';
import TableLoadingScreen from '../../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import NumberDisplay from '../../../NumberDisplay/NumberDisplay';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import '../CompanyInfoModal.css';
import { AppContext } from '../../../../context/Context';
import { getNodeNameById, nameCleanUp } from '../../../../utils/helper';
import shareholderIcon from '../../../../assets/images/icons/ShareHolderIcon.png'

function Shareholders({
  debouncedSearch,
  isLoading,
  shareholders,
  smallIcons = false,
  selectedCompany,
  forceMobileView = false,
}) {
  const { trackShareholder, setTrackShareholder, clGraph } =
    useContext(AppContext);

  const [isTrackingOn, setIsTrackingOn] = useState(false);

  // Calculate total shares using useMemo to update dynamically
  const totalShares = useMemo(() => {
    if (Array.isArray(shareholders) && shareholders.length >= 2) {
      return (shareholders[0]?.total_shares || 0) + (shareholders[1]?.total_shares || 0);
    }
    return 0;
  }, [shareholders]);

  const trackShareholdersInGraph = () => {
    const nameKey = getNodeNameById(
      clGraph?.selectedGraph?.nodes,
      clGraph?.selectedGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');

    const newItem = {
      companyNumber: selectedCompany.company_number,
      shareholders: shareholders,
    };

    setTrackShareholder(prev => {
      const existingArray = prev[nameKey] || [];
      const updatedArray = [...existingArray, newItem];
      return {
        ...prev,
        [nameKey]: updatedArray,
      };
    });
  };

  const removeTrackShareholdersInGraph = () => {
    let companyNumber = selectedCompany?.company_number;
    setTrackShareholder(prev => {
      // Make a shallow copy of the entire object
      const newState = { ...prev };

      // For each nameKey in trackShareholder,
      // filter out the item(s) whose companyNumber matches
      for (const nameKey in newState) {
        newState[nameKey] = newState[nameKey].filter(
          item => item.companyNumber !== companyNumber
        );
      }

      return newState;
    });
  };

  const isCompanyPartOfTrackingShareholders = (records, companyNumber) => {
    // .some() returns true if at least one item satisfies the condition
    return records?.some(record => record?.companyNumber === companyNumber);
  };

  useEffect(() => {
    const nameKey = getNodeNameById(
      clGraph?.selectedGraph?.nodes,
      clGraph?.selectedGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');

    setIsTrackingOn(
      isCompanyPartOfTrackingShareholders(
        trackShareholder[nameKey],
        selectedCompany?.company_number
      )
    );
  }, [trackShareholder]);

  return (
    <div>
      <div
        className={`company-info-body-scroller ${smallIcons ? 'company-info-body-scroller-insight' : ''
          }`}
      >
        {forceMobileView === true ? (
          <div>
            {isTrackingOn === false || isTrackingOn === undefined ? (
              <div>
                <button
                  style={{
                    backgroundColor: '#0380C7',
                    border: 'none',
                    color: 'white',
                    padding: '5px 5px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    marginBottom: '5px',
                  }}
                  onClick={() => trackShareholdersInGraph()}
                >
                  Track in Graph
                </button>
              </div>
            ) : (
              <div>
                <button
                  style={{
                    backgroundColor: '#0380C7',
                    border: 'none',
                    color: 'white',
                    padding: '5px 5px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                    marginBottom: '5px',
                  }}
                  onClick={() => removeTrackShareholdersInGraph()}
                >
                  Remove Tracking in Graph
                </button>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className="company-info-modal-people-contant-main">
          <div className="company-info-modal-overview-main">
            <div className="contact-card-title" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0' }}>
              <div>
                <img style={{ marginRight: '10px' }} src={shareholderIcon} alt="icon" />Shareholders
              </div>
              {(totalShares > 0) &&
                <div className="company-info-modal-total-shares" style={{ fontSize: '12px' }}>
                  <span
                    style={{
                      marginRight: '5px',
                      fontWeight: '600px',
                      color: '#757575'
                    }}
                  >
                    Total Shares:
                  </span>
                  <div style={{ color: '#00D1A9' }}>
                    <NumberDisplay
                      animatedValue={totalShares}
                    />
                  </div>
                </div>
              }
            </div>
            <div
              className="company-info-modal-overview-title"
              style={{
                display: 'flex',
                gap: '5px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                fontSize: '0.9rem'
              }}
            >
              <div>
                <input
                  className="form-control"
                  type="search"
                  placeholder="Search Shareholder ..."
                  onChange={e => {
                    const value = e.target.value;
                    debouncedSearch(value);
                  }}
                />
              </div>
            </div>
            {isLoading === false ? (
              <div className="company-info-modal-Table-container">
                {shareholders && Array.isArray(shareholders) && (
                  <>
                    {shareholders
                      ?.filter(shareType => shareType.type === 'Preferred')
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{' '}
                              <span style={{ marginLeft: '5px' }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '600px',
                                }}
                              >
                                Total Shares:
                              </span>
                              <div style={{ color: '#00D1A9' }}>
                                <NumberDisplay
                                  animatedValue={shareType?.total_shares}
                                />
                              </div>
                            </div>
                          </div>
                          {shareType?.total_shares > 0 &&
                            <table className="company-info-modal-shareholders-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Amount</th>
                                  <th>
                                    %
                                  </th>
                                  <th>Class</th>
                                </tr>
                              </thead>
                              <tbody>
                                {shareType.people.map((person, idx) => (
                                  <tr
                                    key={idx}
                                    className="company-info-modal-shareholder"
                                  >
                                    <td className="company-info-modal-shareholders-name">
                                      {person.name}
                                    </td>
                                    <td>
                                      <NumberDisplay
                                        animatedValue={person.number_of_shares}
                                      />
                                    </td>
                                    <td>
                                      {person.shares_percentage}
                                    </td>
                                    <td>
                                      <div
                                        className={`company-info-modal-shareholder-socialIcons ${smallIcons
                                          ? 'company-info-modal-shareholder-socialIcons-insights'
                                          : ''
                                          }`}
                                      >
                                        <a
                                          href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(person?.name)}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <LinkedInIcon
                                            className={
                                              smallIcons &&
                                              'company-info-modal-shareholder-socialIcons-size'
                                            }
                                          />
                                        </a>
                                        <a
                                          href={`https://www.google.com/search?q=${person?.name}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <LanguageIcon
                                            className={
                                              smallIcons &&
                                              'company-info-modal-shareholder-socialIcons-size'
                                            }
                                          />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>}
                        </div>
                      ))}

                    {shareholders
                      .filter(shareType => shareType.type !== 'Preferred')
                      .map((shareType, index) => (
                        <div
                          key={index}
                          className="company-info-modal-share-type"
                        >
                          <div className="company-info-modal-header">
                            <div className="company-info-modal-shares-type">
                              {shareType?.type}{' '}
                              <span style={{ marginLeft: '5px' }}>Shares</span>
                            </div>
                            <div className="company-info-modal-total-shares">
                              <span
                                style={{
                                  marginRight: '5px',
                                  fontWeight: '600px',
                                }}
                              >
                                Total Shares:
                              </span>
                              <div style={{ color: '#00D1A9' }}>
                                <NumberDisplay
                                  animatedValue={shareType?.total_shares}
                                />
                              </div>
                            </div>
                          </div>

                          {shareType?.total_shares > 0 &&
                            <table className="company-info-modal-shareholders-table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Amount</th>
                                  <th>
                                    %
                                  </th>
                                  <th>Class</th>
                                </tr>
                              </thead>
                              <tbody>
                                {shareType.people.map((person, idx) => (
                                  <tr
                                    key={idx}
                                    className="company-info-modal-shareholder"
                                  >
                                    <td className="company-info-modal-shareholders-name">
                                      {person.name}
                                    </td>
                                    <td>
                                      <NumberDisplay
                                        animatedValue={person.number_of_shares}
                                      />
                                    </td>
                                    <td>
                                      {person.shares_percentage}
                                    </td>
                                    <td>
                                      <div
                                        className={`company-info-modal-shareholder-socialIcons ${smallIcons
                                          ? 'company-info-modal-shareholder-socialIcons-insights'
                                          : ''
                                          }`}
                                      >
                                        <a
                                          href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(person?.name)}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <LinkedInIcon
                                            className={
                                              smallIcons &&
                                              'company-info-modal-shareholder-socialIcons-size'
                                            }
                                          />
                                        </a>
                                        <a
                                          href={`https://www.google.com/search?q=${person?.name}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <LanguageIcon
                                            className={
                                              smallIcons &&
                                              'company-info-modal-shareholder-socialIcons-size'
                                            }
                                          />
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          }
                        </div>
                      ))}
                  </>
                )}
              </div>
            ) : (
              <TableLoadingScreen />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shareholders;