import React, { useContext, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import './Signup.css'; // Import the CSS for styling
import { createCheckoutSessionForSubscriptionPlan } from '../../api/subscription/subscription';
import { signupWithEmail } from '../../api/login/signupWithEmail';
import { AppContext } from '../../context/Context';
import { loginWithGoogle } from '../../api/login/loginWIthGoogle';
import showToast from '../../utils/showToast';
import { useAuth } from '../../context/AuthProvider';
import { getUserById } from '../../api/outseta/outseta';
import fingerPrintLogo from '../../assets/images/icons/fingerPrintLogo.png';
import googleIcon from '../../assets/images/icons/googleIcon.png';
import { useLoading } from '../../context/LoadingContext/LoadingContext';

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Signup = ({ selectedPlan }) => {
  const { setIsLoading } = useLoading();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({ name: '', email: '', password: '' }); // State for validation errors
  const { user, setUser } = useContext(AppContext);
  const { logout } = useAuth();

  // Validation function
  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: '', email: '', password: '' };

    // Name validation
    if (!name) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Email validation
    if (!email) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email address is invalid';
      isValid = false;
    }

    // Password validation
    if (!password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  // need to find better solution for this. so that we can reuse this
  const stripePaymentHandler = async (email, selectedPlan) => {
    setIsLoading(true);

    const stripe = await stripePromise;
    // Request a Checkout session from the backend
    const response = await createCheckoutSessionForSubscriptionPlan({
      email,
      plan: selectedPlan,
    });

    const session = response;

    if (session.error) {
      setIsLoading(false);
      return;
    }

    // Redirect to Stripe Checkout
    const { error } = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error('Stripe Checkout Error:', error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handlePaymentAndSignup = async () => {
    if (!validateForm()) {
      return; // Stop if form is invalid
    }

    if (!selectedPlan) {
      showToast('warning', 'No subscription plan selected');
      return;
    }

    if (selectedPlan === 'Freemium') {
      setIsLoading(true);
      let userSignupWithEmail = await signupWithEmail(
        email,
        password,
        name,
        selectedPlan
      );

      if (userSignupWithEmail?.success === false) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email:
            userSignupWithEmail?.code === 'auth/email-already-in-use'
              ? 'Email is already in use'
              : prevErrors.email,
          password:
            userSignupWithEmail?.code === 'auth/wrong-password'
              ? 'Incorrect password'
              : prevErrors.password,
        }));

        showToast('error', userSignupWithEmail?.error);
        setIsLoading(false);
        return;
      }

      let updatedUser = {
        ...userSignupWithEmail?.user,
        FirebaseUser: userSignupWithEmail?.firebaseUser,
      };

      setUser(updatedUser);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      let userSignupWithEmail = await signupWithEmail(
        email,
        password,
        name,
        selectedPlan
      );

      if (userSignupWithEmail?.success === false) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email:
            userSignupWithEmail?.code === 'auth/email-already-in-use'
              ? 'This email is already in use'
              : prevErrors.email,
          password:
            userSignupWithEmail?.code === 'auth/wrong-password'
              ? 'Incorrect password'
              : prevErrors.password,
        }));

        showToast('error', userSignupWithEmail?.error);
        setIsLoading(false);
        return;
      }

      // Proceed with Stripe payment
      await stripePaymentHandler(email, selectedPlan);
    } catch (error) {
      console.error('Error during payment process:', error);
      setIsLoading(false);
    }
  };

  const handleGmailSignup = async () => {
    try {
      setIsLoading(true);
      if (!selectedPlan) {
        setIsLoading(false);
        return;
      }

      const userRes = await loginWithGoogle(false, selectedPlan);

      if (userRes?.success && userRes?.firebaseUser?.is_payment_done) {
        let updatedUser = {
          ...userRes?.user,
          FirebaseUser: userRes.firebaseUser,
        };
        setUser(updatedUser);

        const message =
          userRes.firebaseUser.plan === selectedPlan
            ? 'Welcome'
            : `User already exists with ${userRes.firebaseUser.plan} Plan`;

        showToast('success', message);
        setIsLoading(false);
        return;
      }

      if (userRes?.success && !userRes?.firebaseUser?.is_payment_done) {
        if (selectedPlan !== 'Freemium') {
          try {
            await stripePaymentHandler(
              userRes?.firebaseUser?.user_email,
              selectedPlan
            );
          } catch (error) {
            console.error('Error during payment process:', error);
            setIsLoading(false);
          }
        } else {
          let updatedUser = {
            ...userRes?.user,
            FirebaseUser: userRes.firebaseUser,
          };
          setUser(updatedUser);
          setIsLoading(false);
        }
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          email:
            userRes?.code === 'auth/email-already-in-use'
              ? 'Email is already in use'
              : '',
          password:
            userRes?.code === 'auth/weak-password' ? 'Weak password' : '',
        }));
        showToast('error', userRes?.error);
        await logout(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handlePaymentAndSignup();
    }
  };


  return (
    <div className="probe-signup-container">
      <div className="probe-login-container-logo-div">
        <img
          className="probe-login-container-logo"
          src={fingerPrintLogo}
          alt="Logo"
        />
      </div>
      <h2 className="probe-signup-title">Sign Up</h2>

      {/* Name Input */}
      <label
        style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
        htmlFor="name"
      >
        Name*
      </label>
      <input
        type="text"
        className="probe-signup-input"
        placeholder="Name"
        value={name}
        onChange={e => setName(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {errors.name && <p className="probe-signup-error">{errors.name}</p>}

      {/* Email Input */}
      <label
        style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
        htmlFor="email"
      >
        Email*
      </label>
      <input
        type="email"
        className="probe-signup-input"
        placeholder="Email"
        value={email}
        onChange={e => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {errors.email && <p className="probe-signup-error">{errors.email}</p>}

      {/* Password Input */}
      <label
        style={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}
        htmlFor="email"
      >
        Password*
      </label>
      <input
        type="password"
        className="probe-signup-input"
        placeholder="Password"
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyDown={handleKeyDown}
      />
      {errors.password && (
        <p className="probe-signup-error">{errors.password}</p>
      )}

      <button className="probe-signup-button" onClick={handlePaymentAndSignup}>
        {selectedPlan === 'Freemium' ? 'Signup' : 'Proceed to Payment'}
      </button>

      {/* Google Login */}
      <div className="google-auth-button" onClick={handleGmailSignup}>
        <img
          src={googleIcon}
          alt="logo"
          style={{ height: '22px', width: '22px', marginRight: '8px' }}
        />
        Sign up with Google
      </div>
    </div>
  );
};

export default Signup;
