import React, { useContext, useEffect, useState } from 'react';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import './CoporateLinksGraph.css';
import CorporateLinksGraphRightSidebar from '../../../components/CorporateLinks/CorporateLinksGraphRightSidebar/CorporateLinksGraphRightSidebar';
import CLGraph from './../../../components/CorporateLinks/CLGraph/CLGraph';
import { AppContext } from '../../../context/Context';
import CorporateLinksGraphLeftSidebar from './../../../components/CorporateLinks/CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';

import {
  getCompaniesFinancialsByCompanyNumbers,
  getCompanyInfoByCompanyNumber,
  getOfficerInfoByCompanyNumber,
  getPersonInsights,
  getRelationShips,
  getShareholdersOfCompaniesInNetworkGraph,
} from '../../../api/corporateExplorer/corporateExplorer';
import CONSTANTS from '../../../utils/constants';
import { useAuth } from '../../../context/AuthProvider';
import LocalStorageService from '../../../utils/LocalStorageServices';
import { isArray } from 'lodash';
import { addUniqueNode, getNodeNameById } from '../../../utils/helper';

const CorporateLinksGraph = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const {
    clGraph,
    setClGraph,
    user,
    trackShareholder,
    setTrackShareholder,
    ngTasks,
  } = useContext(AppContext);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedOfficer, setSelectedOfficer] = useState(null);
  const [accountInfo, setAccountsInfo] = useState([]);
  const [graphFromState, setGraphFromState] = useState(null);
  const [localTrackingShareholder, setLocalTrackingShareholder] = useState([]); // for on off shareholder for left side buttons
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [isLoadingShareholders, setIsLoadingShareholders] = useState(false);

  const { logout, openLogin, openProfile } = useAuth();
  const [leftSideButtonType, setLeftSideButtonType] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [selectedEntityType, setSelectedEntityType] = useState(null);
  const [searchedEntitiesInGraph, setSearchedEntitiesInGraph] = useState([]);
  const [showNationalityINNetwork, setShowNationalityINNetwork] =
    useState(false);
  const [showShowShareholderINNetwork, setShowShareholderINNetwork] =
    useState(false);
  const [showOnlyRelationships, setShowOnlyRelationships] = useState(false);
  const [accountsInformationForNG, setAccountsInformationForNG] = useState({});
  const [showLiabilitiesINNetworkGraph, setShowLiabilitiesINNetworkGraph] =
    useState(false);
  const [showDebthRatioINNetworkGraph, setShowDebthRatioINNetworkGraph] =
    useState(false);

  const [showStatusINNetworkGraph, setShowStatusINNetworkGraph] =
    useState(false);

  const [companyNumbersForFinancialInfo, setCompanyNumbersForFinancialInfo] =
    useState([]);

  const [toggleAdvanceGraph, setToggleAdvanceGraph] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const resetState = () => {
    setSelectedCompany(null);
    setSelectedOfficer(null);
    setRightSidebarOpen(false);
    setAccountsInfo(null);
  };

  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
    if (rightSidebarOpen === true) {
      setClGraph(previousState => ({
        ...previousState,
        isOpenRightSidebar: false,
      }));
    }
  };

  const trackingShareholderAction = trackShareholder => {
    // if (trackShareholder.length === 0) return;
    const newGraph = structuredClone(clGraph?.selectedGraph);
    let edgeId = newGraph?.edges[newGraph?.edges.length - 1]?.edgeId;

    let name = getNodeNameById(
      newGraph?.nodes,
      newGraph?.meta?.selectedEntity?.ids
    )?.replaceAll(' ', '_');
    trackShareholder[name]?.map(shareholder => {
      shareholder?.shareholders?.map(item => {
        item?.people?.map(person => {
          const nodeId =
            `${person?.name}_${person?.type}_${person?.share_type}_${person?.number_of_shares}`
              ?.toLowerCase()
              ?.replaceAll(' ', '_');

          addUniqueNode(newGraph, nodeId, person);

          newGraph?.edges?.push({
            edgeId: edgeId++,
            source: nodeId,
            target: shareholder?.companyNumber,
            probability: '1',
            edgeInfo: '',
          });
        });
      });
    });

    setSelectedGraph(newGraph);
  };

  // Function to toggle between old & new graphs
  const toggleNGGraph = () => {
    setToggleAdvanceGraph(!toggleAdvanceGraph);
  };

  useEffect(() => {
    console.log('ngTasks.length', ngTasks.length);
    if (ngTasks.length === 0) return;
    if (toggleAdvanceGraph === true && ngTasks[0]?.result?.graphs?.length > 0) {
      setClGraph(prevState => ({
        ...prevState,
        graph: ngTasks[0]?.result?.graphs,
        selectedGraph: ngTasks[0]?.result?.graphs[0],
      }));
      setSelectedGraph(ngTasks[0]?.result?.graphs[0]);
      setActiveIndex(0);
    }
    if (toggleAdvanceGraph === false) {
      setClGraph(prevState => ({
        ...prevState,
        graph: ngTasks[0]?.graph?.result?.graphs,
        selectedGraph: ngTasks[0]?.graph?.result?.graphs[0],
      }));
      setSelectedGraph(ngTasks[0]?.graph?.result?.graphs[0]);
      setActiveIndex(0);
    }
  }, [toggleAdvanceGraph]);

  useEffect(() => {
    resetState();
    if (clGraph?.isOpenRightSidebar) {
      const getCompanyInfo = async () => {
        setRightSidebarOpen(clGraph?.isOpenRightSidebar);

        if (!clGraph?.clickedNode) return;

        try {
          if (clGraph?.clickedNode?.entityType === 'company') {
            setIsInfoLoading(true);

            const data = await getCompanyInfoByCompanyNumber(
              clGraph?.clickedNode?.id
            );
            setIsInfoLoading(false);
            setSelectedCompany(data?.companyInfo);
            setAccountsInfo(data?.accountInfo);
          }

          if (clGraph?.clickedNode?.entityType === 'person') {
            setIsInfoLoading(true);

            let preparePayLoad = {
              combinationType: 'officer',
              ids: [
                {
                  type: 'officer',
                  id: clGraph?.clickedNode?.id,
                },
              ],
            };
            preparePayLoad['graphs'] = [clGraph?.selectedGraph];

            const data = await getPersonInsights(preparePayLoad);
            setIsInfoLoading(false);
            setSelectedOfficer(data);
          }
        } catch (error) {
          setIsInfoLoading(false);
        }
      };

      getCompanyInfo();
    }
  }, [clGraph, toggleAdvanceGraph]);

  useEffect(() => {
    if (leftSideButtonType === 'All Shareholders') {
      trackingShareholderAction(trackShareholder);
      setShowNationalityINNetwork(false);
      setShowShareholderINNetwork(true);
      setShowOnlyRelationships(false);
      setShowDebthRatioINNetworkGraph(false);
      setShowLiabilitiesINNetworkGraph(false);
      setShowStatusINNetworkGraph(false);
    }

    if (leftSideButtonType === 'Show Entity Link') {
      setShowOnlyRelationships(true);
      setShowNationalityINNetwork(false);
      setShowDebthRatioINNetworkGraph(false);
      setShowLiabilitiesINNetworkGraph(false);
      setShowShareholderINNetwork(false);
      trackingShareholderAction([]);
      setShowStatusINNetworkGraph(false);
    }

    if (leftSideButtonType === 'Nationality') {
      setShowNationalityINNetwork(true);
      setShowOnlyRelationships(false);
      setShowDebthRatioINNetworkGraph(false);
      setShowLiabilitiesINNetworkGraph(false);
      setShowShareholderINNetwork(false);
      trackingShareholderAction([]);
      setShowStatusINNetworkGraph(false);
    }
    if (leftSideButtonType === 'Debt Ratio') {
      setShowDebthRatioINNetworkGraph(true);
      setShowNationalityINNetwork(false);
      setShowOnlyRelationships(false);
      setShowLiabilitiesINNetworkGraph(false);
      setShowShareholderINNetwork(false);
      trackingShareholderAction([]);
      setShowStatusINNetworkGraph(false);
    }

    if (leftSideButtonType === 'Total Liabilities') {
      setShowLiabilitiesINNetworkGraph(true);
      setShowNationalityINNetwork(false);
      setShowOnlyRelationships(false);
      setShowShareholderINNetwork(false);
      trackingShareholderAction([]);
      setShowStatusINNetworkGraph(false);
    }

    if (leftSideButtonType === 'Status') {
      setShowStatusINNetworkGraph(true);
      setShowLiabilitiesINNetworkGraph(false);
      setShowNationalityINNetwork(false);
      setShowOnlyRelationships(false);
      setShowShareholderINNetwork(false);
      trackingShareholderAction([]);
    }

    if (leftSideButtonType === 'default') {
      trackingShareholderAction([]);
      setShowNationalityINNetwork(false);
      setShowOnlyRelationships(false);
      setShowDebthRatioINNetworkGraph(false);
      setShowLiabilitiesINNetworkGraph(false);
      setShowShareholderINNetwork(false);
      setShowStatusINNetworkGraph(false);
    }
  }, [leftSideButtonType, trackShareholder]);

  useEffect(() => {
    console.log('selectedGraph', selectedGraph);
    if (clGraph?.selectedGraph?.meta?.selectedEntity?.ids) {
      setSearchedEntitiesInGraph(
        clGraph?.selectedGraph?.meta?.selectedEntity?.ids
      );
    }
  }, [selectedGraph, toggleAdvanceGraph]);

  useEffect(() => {
    if (companyNumbersForFinancialInfo?.length > 0) {
      const fetchFinancialsInBatches = async () => {
        const batchSize = 5;
        let allData = {};

        for (
          let i = 0;
          i < companyNumbersForFinancialInfo.length;
          i += batchSize
        ) {
          const batch = companyNumbersForFinancialInfo.slice(i, i + batchSize);

          try {
            const response =
              await getCompaniesFinancialsByCompanyNumbers(batch);
            allData = { ...allData, ...response }; // Merge new data into the existing object
            setAccountsInformationForNG(allData); // Update state progressively
          } catch (error) {
            console.error('Error fetching financials:', error);
          }
        }
      };

      fetchFinancialsInBatches();
    }
  }, [companyNumbersForFinancialInfo]);

  useEffect(() => {
    if (ngTasks?.length > 0) {
      console.log('ngTaskskkx', ngTasks);
    }
  }, [ngTasks]);

  useEffect(() => {
    let graph = clGraph?.graph;
    if (graph) {
      const companyNumbers = graph?.flatMap(value =>
        value?.nodes
          ?.filter(vl => vl?.entityType === 'company')
          ?.map(vl => vl?.id)
      );
      setCompanyNumbersForFinancialInfo(companyNumbers);
    }
    setGraphFromState(graph);
    setTrackShareholder([]);
    setSelectedGraph(null);
    setToggleAdvanceGraph(false);
    resetState();
  }, []);

  return (
    <div>
      <div className="dashboard-section">
        <div className="main-div">
          <div className="corporate-links-graph-main-content">
            <div>
              <CLGraph
                graph={graphFromState}
                setLeftSideButtonType={setLeftSideButtonType}
                leftSideButtonType={leftSideButtonType}
                setSelectedGraph={setSelectedGraph}
                selectedGraph={selectedGraph}
                isLoadingShareholders={isLoadingShareholders}
                setSelectedEntityType={setSelectedEntityType}
                selectedEntityType={selectedEntityType}
                rightSidebarOpen={rightSidebarOpen}
                searchedEntitiesInGraph={searchedEntitiesInGraph}
                showNationalityINNetwork={showNationalityINNetwork}
                showOnlyRelationships={showOnlyRelationships}
                accountsInformationForNG={accountsInformationForNG}
                showDebthRatioINNetworkGraph={showDebthRatioINNetworkGraph}
                showLiabilitiesINNetworkGraph={showLiabilitiesINNetworkGraph}
                showShowShareholderINNetwork={showShowShareholderINNetwork}
                showStatusINNetworkGraph={showStatusINNetworkGraph}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                toggleNGGraph={toggleNGGraph}
                ngTasks={ngTasks}
              />
            </div>

            {/* Right sidebar */}
            <div
              className={`corporate-links-graph-right-sidebar ${rightSidebarOpen ? 'open' : ''}`}
            >
              <CorporateLinksGraphRightSidebar
                rightSidebarOpen={rightSidebarOpen}
                toggleRightSidebar={toggleRightSidebar}
                selectedCompany={selectedCompany}
                accountInfo={accountInfo}
                selectedOfficer={selectedOfficer}
                isInfoLoading={isInfoLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateLinksGraph;
