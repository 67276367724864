import { Routes, Route } from 'react-router-dom';
import HeaderDropdown from './components/HeaderDropdownNative';
import Sidebar from './components/Sidebar';
import { routesConfig } from './routeConfig.js';
import usePageTitle from './context/PageContext/PageContext.js';
import ToolTips from './components/ToolTips/ToolTips.js';
import { AppContext } from './context/Context';
import { useContext, useEffect } from 'react';
import ToolBarIcon from './assets/images/icons/ToolbarIcon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const MainLayout = () => {
  const pageTitle = usePageTitle();
  const { setToggleSidebar, toggleSidebar, user } = useContext(AppContext);

  const toggleSidebarHandler = () => {
    setToggleSidebar(!toggleSidebar);
  };

  const renderRoutes = routes => {
    return routes.map((route, index) => {
      if (route.children) {
        return (
          <Route key={index} path={route.path} element={route.element}>
            {renderRoutes(route.children)}
          </Route>
        );
      }
      return <Route key={index} path={route.path} element={route.element} />;
    });
  };

  useEffect(() => {
    console.log('useruser', user);
  }, [user]);

  return (
    <div style={{ width: '100%' }}>
      <div className="main-toolbar">
        <div className="main-toolbar-left-section">
          {user?.guest === true ? (
            ''
          ) : (
            <div className="burger-main" onClick={toggleSidebarHandler}>
              <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
          )}

          <h2 className="main-toolbar-page-title">{pageTitle}</h2>
          <div className="toolbar-icon">
            <img src={ToolBarIcon} alt="Toolbar Icon" />
          </div>
        </div>
        <HeaderDropdown />
      </div>
      <Sidebar />
      <Routes>{renderRoutes(routesConfig)}</Routes>
      <ToolTips />
    </div>
  );
};

export default MainLayout;
