import React, { useState, useEffect } from 'react';
import './CEIPersonInsightsCompanies.css';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import {
  getStatusStringFromChar,
  getStatusStyleCard,
  nameCleanUp,
} from '../../../../utils/helper';
import CompaniesLogo from '../../../../assets/images/icons/CompaniesLogo.png';
import CompaniesIcon from '../../../../assets/images/icons/CompaniesIcon.png';
import { COMPANIES_HOUSE_DETAIL } from '../../../../utils/constants';

const CEIPersonInsightsCompanies = ({ companies }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onClickCompaniesLogo = selectedCompany => {
    if (!selectedCompany?.company_number) {
      return;
    }
    const url = COMPANIES_HOUSE_DETAIL(selectedCompany?.company_number);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="corporate-explore-insight-companies-container">
      <h2 className="corporate-explore-insight-companies-title">
        <img className="corporate-explore-insight-appointments-icon" src={CompaniesIcon} alt="icon" />Companies
      </h2>
      {!isMobileView && (
        <div style={{ borderBottom: '1px solid #EAECF0' }} className="corporate-explore-insight-person-insights-container-topbar">
          <h6>Name</h6>
          <h6>Appointed</h6>
          <h6>Explore</h6>
        </div>
      )}
      {companies?.length > 0 ? (
        companies?.map((company, index) => (
          isMobileView ? (
            <div className="corporate-explore-insight-person-container-mobile-view" key={index}>
              <div className="corporate-explore-insight-person-row">
                <p className='corporate-explore-insight-company-name' style={{ width: '50%' }}>{company?.name}</p>
                <p>Status: <span style={getStatusStyleCard(company?.status?.toLowerCase())}>{getStatusStringFromChar(company?.status)}</span></p>
              </div>
              <div className="corporate-explore-insight-person-row">
                <p>Role</p>
                <p>{company?.position}</p>
              </div>
              <div className="corporate-explore-insight-person-row">
                <p className='corporate-explore-insight-company-sub-heading'>Appointed</p>
                <p>{company?.appointed}</p>
              </div>
              <div className="corporate-explore-insight-person-row">
                <p className='corporate-explore-insight-company-sub-heading'>Explore</p>
                <div className="corporate-explore-insight-companies-network-buttons">
                  <a href={`https://www.google.com/search?q=${company?.name}`} target="_blank" rel="noopener noreferrer">
                    <LanguageIcon style={{ width: '20px', color: '#8A8A8A' }} />
                  </a>
                  <a href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(company?.name)}`} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon style={{ width: '20px', color: '#8A8A8A' }} />
                  </a>
                  <img src={CompaniesLogo} alt="Companies Logo" onClick={() => onClickCompaniesLogo(company)} />
                </div>
              </div>
            </div>
          ) : (
            <div style={{ borderBottom: '1px solid #EAECF0' }} className="corporate-explore-insight-person-container">
              <div className="corporate-explore-insight-person-row corporate-explore-person-row-first">
                <p className='corporate-explore-insight-company-name'>{company?.name}</p>
                <p>{company?.appointed}</p>
                <div className="corporate-explore-insight-companies-network-buttons">
                  <a href={`https://www.google.com/search?q=${company?.name}`} target="_blank" rel="noopener noreferrer">
                    <LanguageIcon style={{ width: '20px', color: '#8A8A8A' }} />
                  </a>
                  <a href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(company?.name)}`} target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon style={{ width: '20px', color: '#8A8A8A' }} />
                  </a>
                  <img style={{ width: '20px',height:'20px' }} src={CompaniesLogo} alt="Companies Logo" onClick={() => onClickCompaniesLogo(company)} />
                </div>
              </div>
              <p>Role {company?.position}</p>
              <p>Status <span style={getStatusStyleCard(company?.status?.toLowerCase())}>{getStatusStringFromChar(company?.status)}</span></p>
            </div>
            // <div className="corporate-explore-insight-person-insights-topbar" key={index}>
            //   <div className="corporate-explore-insight-companies-name">
            //     <p>{company?.name}</p>
            //     <p>Role {company?.position}</p>
            //     <p>Status <span style={getStatusStyleCard(company?.status?.toLowerCase())}>{getStatusStringFromChar(company?.status)}</span></p>
            //   </div>
            //   <div style={{ flex: 1 }}>{company?.appointed}</div>
            //   <div style={{ flex: 1 }}>
            //     <div className="corporate-explore-insight-companies-network-buttons">
            //       <a href={`https://www.google.com/search?q=${company?.name}`} target="_blank" rel="noopener noreferrer">
            //         <LanguageIcon style={{ width: '20px', color: '#8A8A8A' }} />
            //       </a>
            //       <a href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(company?.name)}`} target="_blank" rel="noopener noreferrer">
            //         <LinkedInIcon style={{ width: '20px', color: '#8A8A8A' }} />
            //       </a>
            //       <img src={CompaniesLogo} alt="Companies Logo" onClick={() => onClickCompaniesLogo(company)} />
            //     </div>
            //   </div>
            // </div>
          )
        ))
      ) : (
        <p>No companies available</p>
      )}
      {/* <a href="/" className="corporate-explore-insight-view-all-companies">
        VIEW ALL {companies?.length} COMPANIES
      </a> */}
    </div>
  );
};

export default CEIPersonInsightsCompanies;
