import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import { getMonthName } from '../../../../utils/common';
import {
  nameCleanUp,
  nationalityToFlag,
  statusDiv,
} from '../../../../utils/helper';
const PscItem = ({ pscData, isCardView = false }) => {

  const formatControlText = (text) => {
    if (!text) return <></>;

    // Extract the percentage values
    const formattedText = text
      .replace(/ownership-of-shares-/, 'Shareholder of ')
      .replace(/-/g, ' ')
      .replace(/(\d+)\spercent/g, '$1%') 

    // Find numbers in the string
    const match = formattedText.match(/(\d+ to \d+%)/);

    if (match) {
      const [highlightedPart] = match;
      const parts = formattedText.split(highlightedPart);

      return (
        <p style={{ textAlign: 'left', fontSize: '14px' }}>
          {parts[0]}
          <span style={{ color: '#0380C7' }}>{highlightedPart}</span>
          {parts[1]}
        </p>
      );
    }

    return <p style={{ textAlign: 'left', fontSize: '14px' }}>{formattedText}</p>;
  };

  return (
    <>
      {pscData &&
        pscData?.map(item => (
          <div
            className={` ${isCardView
              ? 'company-info-modal-person-list-is-card-view'
              : 'company-info-modal-person-list'
              }`}
          >
            <div className="company-info-modal-person-list-info">
              <div style={{ display: 'flex' }}>
                <div style={{ width: '100%', marginLeft: '10px' }}>
                  <div
                    className="company-info-modal-overview-key"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '2px',
                      fontSize: '16px'
                    }}
                  >
                    {item?.name}
                  </div>
                </div>
              </div>
              <p style={{ textAlign: 'left', marginLeft: '10px', fontSize: '14px', marginBottom: '0px',display:'flex' }}>
                {formatControlText(item?.natures_of_control[0])} of the company
              </p>
            </div>
          </div>
        ))}
    </>
  );
};

export default PscItem;
