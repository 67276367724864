import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderDropdown from '../../../components/HeaderDropdownNative';
import { useForm } from 'react-hook-form';
import '../JudgementChecker.css';
import ProbeInputUseForm from '../../../components/ProbeInput/ProbeInputUseForm';
import ProbeButton from '../../../components/ProbeButton/ProbeButton';
import OneFinalCheck from '../../../components/OneFinalCheck/OneFinalCheck';
import './JudgementCheckerYourDetail.css';
import { AppContext } from '../../../context/Context';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import { getBaseUrl } from '../../../utils/helper';
import { createCheckoutSession } from '../../../api/judgementChecker/judgementChecker';
import LocalStorageService from '../../../utils/LocalStorageServices';
import { triggerGoogleAnalyticsEvent } from '../../../utils/googleAnalyticsEvent';

function JudgementCheckerYourDetail() {
  const [privacyPolicy, setPrivacyPolicy] = useState(true);
  const [linkedToSearch, setLinkedToSearch] = useState(true);
  const [avaibleViaTrust, setAvaibleViaTrust] = useState(true);

  const { setIsLoading } = useLoading();
  const { judgementChecker, setJudgementChecker, user } =
    useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getTrimObj = obj => {
    try {
      for (let key in obj) {
        if (typeof obj[key] === 'string') {
          obj[key] = obj[key].trim();
        }
      }
      return obj;
    } catch (error) {
      return obj;
    }
  };

  const onSubmit = async data => {
    if (!privacyPolicy || !linkedToSearch || !avaibleViaTrust) return;
    debugger;
    try {
      await triggerGoogleAnalyticsEvent('payments', 'checkout_button_clicked');
      setIsLoading(true);
      let modified_judgmenet_checker = {
        ...judgementChecker,
        user_information: getTrimObj(data),
      };
      const successUrl = `${getBaseUrl()}/ccj-dashboard/report`;
      const cancelUrl = `${getBaseUrl()}/payment-cancel`;

      const response = await createCheckoutSession(
        successUrl,
        cancelUrl,
        modified_judgmenet_checker,
        process.env.REACT_APP_DUMMY_USER_ID
      );

      if (!response.success) {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      LocalStorageService.setItem('payment_session_id', response.session?.id);
      window.location.href = response.url;
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="dashboard-section dashboard-content-div-overflow"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className="main-div"></div>
      <div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="judgement_check_your_dtail_form_style"
        >
          <span className="judgement_check_your_dtail_main_div_header">
            Your Personal Detail
          </span>
          <div className="judgement_check_your_dtail_main_div">
            <div className="judgement_check_your_dtail_main_div_title">
              <ProbeInputUseForm
                height="50px"
                width="100%"
                placeholder="Title"
                registerConfig={register('title', {
                  pattern: {
                    value: /^[A-Za-z\s]+$/i,
                    message: 'Title must contain only letters',
                  },
                  required: 'Title is required',
                })}
                errors={errors}
              />
            </div>
            <ProbeInputUseForm
              height="50px"
              width="100%"
              placeholder="Forename"
              registerConfig={register('forename', {
                pattern: {
                  value: /^[A-Za-z\s]+$/i,
                  message: 'Forename must contain only letters',
                },
                required: 'Forename must contain only letters',
              })}
              errors={errors}
            />
            <ProbeInputUseForm
              height="50px"
              width="100%"
              placeholder="Surname"
              registerConfig={register('surname', {
                pattern: {
                  value: /^[A-Za-z\s]+$/i,
                  message: 'Surname must contain only letters',
                },
                required: 'Surname must contain only letters',
              })}
              errors={errors}
            />
          </div>

          <ProbeInputUseForm
            height="50px"
            width="100%"
            placeholder="Email Address"
            registerConfig={register('emailaddress', {
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Invalid Email Address',
              },
              required: 'Email ddress is required',
            })}
            errors={errors}
          />

          <ProbeInputUseForm
            height="50px"
            width="100%"
            placeholder="Confirm Email Address"
            registerConfig={register('confirmemailaddress', {
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: 'Invalid Email Address',
              },
              required: 'Address must contain only letters',
            })}
            errors={errors}
          />

          <OneFinalCheck
            setPrivacyPolicy={setPrivacyPolicy}
            setAvaibleViaTrust={setAvaibleViaTrust}
            setLinkedToSearch={setLinkedToSearch}
            avaibleViaTrust={avaibleViaTrust}
            linkedToSearch={linkedToSearch}
            privacyPolicy={privacyPolicy}
          />

          <div
            style={{
              display: 'flex',

              justifyContent: 'flex-start',

              width: '100%',

              marginTop: '20px',
            }}
          >
            <ProbeButton
              type="submit"
              name={'Checkout'}
              backgroundColor={'#0466D1'}
              borderRadius={'10px'}
              borderColor={'#blue'}
              height={'60px'}
              width={'300px'}
              fontColor="#FFF"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default JudgementCheckerYourDetail;
