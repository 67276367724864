import { useEffect, useState, createContext, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { firebaseAuth } from '../context/firebaseContext/firebase';
import LocalStorageService from '../utils/LocalStorageServices';
import { AppContext } from './Context';
import { useLoading } from './LoadingContext/LoadingContext';
import { getUserById } from '../api/outseta/outseta';
import { getGuestUserInfo } from '../utils/helper';
import { object } from 'prop-types';

const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children, unprotectedRoutes }) {
  const { user, setUser, userCurrentLocation } = useContext(AppContext);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoading();
  const location = useLocation();

  const logout = async (isNavigate = true) => {
    try {
      // Firebase sign out
      await signOut(firebaseAuth);
      // Clear user from context and local storage
      setUser(null);
      LocalStorageService.clearLocalStorage();
      if (isNavigate) {
        navigate('/auth');
      }
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      const unsubscribe = onAuthStateChanged(
        firebaseAuth,
        async currentUser => {
          if (currentUser) {
            try {
              const user = await getUserById(currentUser.uid);

              setUser({
                uid: currentUser.uid,
                email: currentUser.email,
                displayName: currentUser.displayName,
                photoURL: currentUser.photoURL,
                FirebaseUser: user?.data,
                authProvider:
                  firebaseAuth.currentUser.providerData[0]?.providerId,
              });
            } catch (error) {
              setUser(null);
            }
          } else {
            setUser(getGuestUserInfo());
          }
        }
      );

      return () => unsubscribe();
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (
      user?.guest === true &&
      location &&
      !unprotectedRoutes?.includes(location?.pathname)
    ) {
      navigate('/auth');
    }
  }, [user, location?.pathname]);

  return (
    <AuthContext.Provider
      value={{
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
