import React from 'react';
import './RightSideMenu.css';
import CorporateExploreInsightOverview from '../../../pages/CorporateExploreInsight/CorporateExploreInsightOverview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const RightSideMenu = ({ isOpen, onClose, selectedCompany, accountInfo }) => {
    return (
        <div className={`right-side-menu ${isOpen ? 'open' : ''}`}>
            <div className="right-side-menu-content">
                <div className="right-side-menu-header">
                    <button className="right-side-menu-close" onClick={onClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                </div>
                <div className="right-side-menu-body">
                    {selectedCompany && (
                        <CorporateExploreInsightOverview
                            selectedCompany={selectedCompany}
                            accountInfo={accountInfo}
                            forceMobileView={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default RightSideMenu; 