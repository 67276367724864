import React, { useContext, useEffect, useState } from 'react';
import './CEIFinancials.css';
import {
  calculateDebtRatio,
  calculateNetAssets,
  formatCurrency,
  formatDifference,
  forMateDateIntoEnglish,
  formatNumber,
} from '../../../utils/helper';
import AreaChart from '../../AreaChart/AreaChart';
import { AppContext } from '../../../context/Context';
import TableLoadingScreen from '../../LoadingScreen/TableLoadingScreen/TableLoadingScreen';
import { getUseableDocumentUrl } from '../../../api/corporateExplorer/corporateExplorer';
import {
  financialDocumentParser,
  getCompaniesFinancialsByCompanyNumber,
  multiFinancialDocumentParser,
} from '../../../api/financialAccounts/financialAccounts';
import assetFolderIcon from '../../../assets/images/icons/assetFolderIcon.png'
import bankIcon from '../../../assets/images/icons/bankIcon.png'
import ratioIcon from '../../../assets/images/icons/ratioIcon.png'
import employeesIcon from '../../../assets/images/icons/employeesIcon.png'
import turnoverIcon from '../../../assets/images/icons/turnoverIcon.png'
import briefcaseIcon from '../../../assets/images/icons/briefcaseIcon.png'
import stackDollarIcon from '../../../assets/images/icons/stackDollarIcon.png'
import FinancialIcon from '../../../assets/images/icons/FinancialIcon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CEIFinancials = ({ selectedCompany, accountInfo }) => {
  let defaultGraphData = {
    labels: ['1', '1'],
    datasets: [
      {
        label: 'Net Assets',
        data: ['1', '1'],
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        fill: true,
      },
      {
        label: 'Total Assets',
        data: ['1', '1'],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        fill: true,
      },
      {
        label: 'Total Liabilities',
        data: ['1', '1'],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        fill: true,
      },
    ],
  };

  const { financialAccountsProcessed, setFinancialAccountsProcessed } =
    useContext(AppContext);
  const [accountFinalObjHolder, setAccountFinalObjHolder] = useState({});
  const [differenceCalculaton, setDifferenceCalculaton] = useState({});
  const [toDocumentDate, setTopDocumentDate] = useState('');
  const [isInfromationFoundOnDb, setIsInfromationFoundOnDb] = useState(null);
  const [graphData, setGraphData] = useState(defaultGraphData);

  const transformDataToCustomFormat = data => {
    if (Array.isArray(data) && data.length > 0) {
      // Filter out entries where parsedData is null
      const filteredData = data.filter(item => item?.parsedData !== null);

      // Extract years from the filtered data array and sort them in ascending order
      const labels = filteredData.map(item => item?.year).sort((a, b) => a - b);

      // Initialize arrays for Net Assets, Total Assets, and Total Liabilities
      let netAssets = Array(labels.length).fill(null);
      let totalAssets = Array(labels.length).fill(null);
      let totalLiabilities = Array(labels.length).fill(null);

      // Loop through the filtered data to populate the arrays
      filteredData.forEach(({ parsedData, year }) => {
        // Find the index of the year in the sorted labels array
        const yearIndex = labels.indexOf(year);

        if (yearIndex !== -1) {
          netAssets[yearIndex] = parsedData?.equity;
          totalAssets[yearIndex] = parsedData?.total_assets;
          totalLiabilities[yearIndex] =
            Math.sign(parsedData?.total_liabilities) === -1
              ? parsedData?.total_liabilities
              : -parsedData?.total_liabilities;
        }
      });

      // Return the custom data in the required format
      return {
        labels,
        datasets: [
          {
            label: 'Net Assets',
            data: netAssets,
            borderColor: 'rgba(54, 162, 235, 1)',
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            fill: true,
          },
          {
            label: 'Total Assets',
            data: totalAssets,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            fill: true,
          },
          {
            label: 'Total Liabilities',
            data: totalLiabilities,
            borderColor: 'rgba(153, 102, 255, 1)',
            backgroundColor: 'rgba(153, 102, 255, 0.5)',
            fill: true,
          },
        ],
      };
    }
  };

  const [showLoadingForFinancalGraph, setShowLoadingForFinancalGraph] =
    useState(false);

  const [showLoadingForFinancalPoints, setShowLoadingForFinancalPoints] =
    useState(false);

  const checkFinancialInformationExitsInDb = async companyData => {
    // if exits then use this object as a main object
    if ('fileDate' in companyData) {
      setShowLoadingForFinancalPoints(true);
      setShowLoadingForFinancalGraph(true);
      setTopDocumentDate(companyData?.fileDate);
      let parsedData = {
        total_liabilities: companyData?.latestData?.total_liabilities,
        total_assets: companyData?.latestData?.total_assets,
        cash_in_bank: companyData?.latestData?.cash_in_bank,
      };
      setAccountFinalObjHolder(parsedData);

      // setup multi file graph data
      setGraphData(companyData?.graphData);
      setShowLoadingForFinancalGraph(false);

      // setup difference calculation
      setDifferenceCalculaton(companyData?.runTimeDifferenceCalculation);
      setShowLoadingForFinancalPoints(false);
      setIsInfromationFoundOnDb(true);
      setFinancialAccountsProcessed(prevState => {
        prevState = prevState || {};
        const companyNumber = selectedCompany?.company_number;
        if (!companyNumber) {
          return {};
        }
        return {
          ...prevState,
          [companyNumber]: {
            ...prevState[companyNumber],
            fileDate: companyData?.fileDate,
            graph: companyData?.graphData,
            nonGraph: parsedData,
            differenceCal: companyData?.runTimeDifferenceCalculation,
            isDb: true,
          },
        };
      });
    } else {
      setIsInfromationFoundOnDb(false);
    }
  };

  const checkCompanyInfoInSideTheState = selectedCompany => {
    if (financialAccountsProcessed === null) return false;

    let isCompanyInfoInState =
      selectedCompany?.company_number in financialAccountsProcessed;

    if (isCompanyInfoInState === false) return isCompanyInfoInState;

    // is it db or non db

    // if db then
    let cfDataFromState =
      financialAccountsProcessed[selectedCompany?.company_number];

    let graphData = cfDataFromState?.graph;
    let nonGraphData = cfDataFromState?.nonGraph;
    let differenceData = cfDataFromState?.differenceCal;
    let fileDate = cfDataFromState?.fileDate;

    if (graphData && nonGraphData) {
      setAccountFinalObjHolder(nonGraphData);
      setGraphData(graphData);
      setDifferenceCalculaton(differenceData);
      setTopDocumentDate(fileDate);
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (selectedCompany) {
      let isInState = checkCompanyInfoInSideTheState(selectedCompany);
      if (isInState === false) {
        const fetchFinancialRecentInformation = async () => {
          try {
            setDifferenceCalculaton({});
            setAccountFinalObjHolder({});
            setTopDocumentDate('');
            setGraphData(defaultGraphData);
            setIsInfromationFoundOnDb(null);
            //first in db if data exits then return otherwisw check live call
            let companyDataRes = await getCompaniesFinancialsByCompanyNumber(
              selectedCompany?.company_number
            );

            await checkFinancialInformationExitsInDb(
              companyDataRes,
              selectedCompany?.company_number
            );
          } catch (error) {
            setShowLoadingForFinancalPoints(false);
          }
        };
        fetchFinancialRecentInformation();
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (isInfromationFoundOnDb === false) {
      const fetchFinancialRecentInformation = async () => {
        // single data only to show text value of accounts
        try {
          setShowLoadingForFinancalPoints(true);
          let financialData = await financialDocumentParser({
            company_number: selectedCompany?.company_number,
          });
          setTopDocumentDate(financialData?.data[0]?.date_complete);
          let parsedData = {
            total_liabilities:
              financialData?.data[0]?.parsedData?.latest_data
                ?.total_liabilities,
            total_assets:
              financialData?.data[0]?.parsedData?.latest_data?.total_assets,
            cash_in_bank:
              financialData?.data[0]?.parsedData?.latest_data?.cash_in_bank,
          };
          setAccountFinalObjHolder(parsedData);
          setShowLoadingForFinancalPoints(false);
          setDifferenceCalculaton(
            financialData?.data[0]?.parsedData?.calculated_fields
          );

          setFinancialAccountsProcessed(prevState => {
            prevState = prevState || {};
            const companyNumber = selectedCompany?.company_number;
            if (!companyNumber) {
              return {};
            }
            return {
              ...prevState,
              [companyNumber]: {
                ...prevState[companyNumber],
                nonGraph: parsedData,
                isDb: false,
                fileDate: financialData?.data[0]?.date_complete,
                differenceCal:
                  financialData?.data[0]?.parsedData?.calculated_fields,
              },
            };
          });
        } catch (error) {
          setShowLoadingForFinancalPoints(false);
        }
      };

      const fetchFinancialAllYearsInformation = async () => {
        try {
          setShowLoadingForFinancalGraph(true);
          let multiFinancialData = await multiFinancialDocumentParser({
            company_number: selectedCompany?.company_number,
          });
          let dataForGraph = transformDataToCustomFormat(
            multiFinancialData?.data
          );
          setGraphData(dataForGraph);
          setShowLoadingForFinancalGraph(false);

          setFinancialAccountsProcessed(prevState => {
            prevState = prevState || {};
            const companyNumber = selectedCompany?.company_number;
            if (!companyNumber) {
              return {};
            }
            return {
              ...prevState,
              [companyNumber]: {
                ...prevState[companyNumber],
                graph: dataForGraph,
                isDb: false,
              },
            };
          });
        } catch (error) {
          setShowLoadingForFinancalGraph(false);
        }
      };

      fetchFinancialRecentInformation();
      fetchFinancialAllYearsInformation();
    }
  }, [isInfromationFoundOnDb]);

  return (
    <>
      <div className="financials-container">
        <h2 className="financials-title"><img style={{ marginRight: '10px' }} src={FinancialIcon} alt="icon" />Financials</h2>
        <div style={{ marginBottom: '20px' }} className="financials-area-chart">
          <div style={{ position: 'relative' }}>
            <AreaChart data={graphData} />

            {showLoadingForFinancalGraph && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <TableLoadingScreen width="80px" />
                </div>
              </div>
            )}
          </div>
        </div>

        <div>
          <div style={{ position: 'relative' }}>
            <>
              {/* <div>
                <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
              </div> */}
              <div className="financials-content">
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={assetFolderIcon} alt="icon" />
                  <span className="financial-item-label">Total Assets</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <div className="value text-info">
                      {showLoadingForFinancalPoints !== true
                        ? ` ${formatCurrency(`£${formatNumber(accountFinalObjHolder?.total_assets)}`)}`
                        : 'Pending'}
                    </div>
                    <>{formatDifference(differenceCalculaton?.total_assets)}</>
                  </div>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={stackDollarIcon} alt="icon" />
                  <span className="financial-item-label">Total Liabilities</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    {' '}
                    <p className="value text-info">
                      {showLoadingForFinancalPoints !== true
                        ? formatCurrency(
                          `£${formatNumber(-accountFinalObjHolder?.total_liabilities)}`
                        )
                        : 'Pending'}
                    </p>
                    <>
                      {formatDifference(
                        differenceCalculaton?.total_liabilities
                      )}
                    </>
                  </div>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={briefcaseIcon} alt="icon" />
                  <span className="financial-item-label">Net Assets</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <p className="value text-info">
                      {showLoadingForFinancalPoints !== true
                        ? formatCurrency(
                          `£${calculateNetAssets(
                            accountFinalObjHolder?.total_assets,
                            accountFinalObjHolder?.total_liabilities
                          )}`
                        )
                        : 'Pending'}
                    </p>
                    <>{formatDifference(differenceCalculaton?.equity)}</>
                  </div>
                  <div>
                    <span><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div>
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={turnoverIcon} alt="icon" />
                  <span className="financial-item-label">Turnover</span>
                  <p className="value">Unreported</p>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={employeesIcon} alt="icon" />
                  <span className="financial-item-label">Employees</span>
                  <p className="value">Unreported</p>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={ratioIcon} alt="icon" />
                  <span className="financial-item-label">Debt Ratio (%)</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <p className="value">
                      {showLoadingForFinancalPoints !== true
                        ? calculateDebtRatio(
                          accountFinalObjHolder?.total_assets,
                          accountFinalObjHolder?.total_liabilities
                        )
                        : 'Pending'}
                    </p>
                    <>{formatDifference(differenceCalculaton?.depth_ratio)}</>
                  </div>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={bankIcon} alt="icon" />
                  <span className="financial-item-label">Cash in Bank</span>
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <p className="value">
                      {showLoadingForFinancalPoints !== true
                        ? accountFinalObjHolder?.cash_in_bank
                          ? formatCurrency(
                            `£${formatNumber(accountFinalObjHolder?.cash_in_bank)}`
                          )
                          : 'Unreported'
                        : 'Pending'}
                    </p>
                    <>{formatDifference(differenceCalculaton?.cash_in_bank)}</>
                  </div>
                  {/* <div>
                    <span style={{ color: '#079455' }}><FontAwesomeIcon icon="arrow-up" /> 12%</span> vs last month
                  </div> */}
                </div>
                {/* <div className="financial-item">
                  <img style={{ height: 32, width: 32 }} src={fileIcon} alt="icon" />
                  <span className="financial-item-label">Year Ended</span>
                  <p className="value">
                    {showLoadingForFinancalPoints !== true
                      ? forMateDateIntoEnglish(toDocumentDate)
                      : 'Pending'}
                  </p>
                </div> */}
              </div>
              <a href="#" className="view-financials-link">
                VIEW FINANCIALS
              </a>
            </>

            {showLoadingForFinancalPoints && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'bottom',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <TableLoadingScreen width="80px" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CEIFinancials;
