import React from 'react';
import './CEISciCode.css';
import SciCodeIcon from '../../../assets/images/icons/Sic Icon.png';
import { capitalizeFirstLetter } from '../../../utils/helper';

const SciCode = ({ selectedCompany }) => {

    return (
        <div className="sic-code-container">
            <h2 className="financials-title">
                <img className="sic-icon" src={SciCodeIcon} alt="icon" />
                SIC Code
            </h2>
            <table className="sic-table">
                <thead>
                    <tr>
                        <th>Code</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>{
                    selectedCompany?.sic_codes_object?.map((sic, index) => (
                        <tr key={index}>
                            <td>{sic.code}</td>
                            <td>{capitalizeFirstLetter(sic.description)}</td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    );
};

export default SciCode;
