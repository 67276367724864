import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home.js';
import { AppContext } from './context/Context.js';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { useLoading } from './context/LoadingContext/LoadingContext.js';
import LoadingScreen from './components/LoadingScreen/LoadingScreen.js';
import PaymentCancel from './pages/Payment/PaymentCancel.js';
import PricingPage from './pages/PricingPage/PricingPage.jsx';
import { useUserSetupContext } from './context/UserLoadingContext/UserLoadingContext.js';
import { InitializeGoogleAnalytics } from './googleAnalytics/googleAnalytics.js';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary.jsx';
import { triggerGoogleAnalyticsEvent } from './utils/googleAnalyticsEvent.js';
import AuthProvider from './context/AuthProvider.js';
import MainLayout from './MainLayout.jsx';
import UserAuthHandler from './components/Login/UserAuthHandler.jsx';
import { db } from './context/firebaseContext/firebase.js';
import { collection, doc, getDocs, onSnapshot } from 'firebase/firestore';

function App() {
  const UNPROTECTED_ROUTES = [
    '/ccj-dashboard/judgement-checker',
    '/ccj-dashboard/judgement-checker-business',
    '/ccj-dashboard/report',
    '/ccj-dashboard/privacy-policy',
    '/ccj-dashboard/terms-and-conditions',
    '/ccj-dashboard/check-review-business',
    '/ccj-dashboard/judgement-checker-your-detail',
    '/ccj-dashboard/judgement-checker-person',
    '/ccj-dashboard/check-review-person',
    '/payment-cancel',
    '/sign-up-post',
    '/login-post',
  ];
  const { isLoading } = useLoading();
  const { isUserSetup } = useUserSetupContext();
  const [user, setUser] = useState(null);
  const [clGraph, setClGraph] = useState(null);
  const [companiesAndOfficers, setCompaniesAndOfficers] = useState(null);
  const [company, setCompany] = useState(null);
  const [officer, setOfficer] = useState(null);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
  const [searchedEntities, setSearchedEntites] = useState([]);
  const [sideBarInfo, setSideBarInfo] = useState({});
  const [
    corporateExplorerSelectedFilterItem,
    setCorporateExplorerSelectedFilterItem,
  ] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [appData, setAppData] = useState({
    userInfo: {},
    groups: [],
    selectedGroup: {},
    sideBarExpanded: true,
  });
  const [judgementChecker, setJudgementChecker] = useState({
    businessJudgementChecker: {},
    personJudgementChecker: {},
  });
  const [corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters] =
    useState({
      officerFilters: null,
      companyFilters: null,
    });
  const [financialAccountsDoc, setFinancialAccountsDoc] = useState(null);
  const [financialAccountsProcessed, setFinancialAccountsProcessed] =
    useState(null);
  const [handleNavigation, setHandleNavigation] = useState(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState(null);
  const [isBeingWatched, setIsBeingWatched] = useState(false);
  const [trackShareholder, setTrackShareholder] = useState([]);
  const [corporateLinksDynamicFilters, setCorporateLinksDynamicFilters] =
    useState({
      company: {
        companyNumber: '',
        location: '',
        postalCode: '',
        companyStatus: [],
        companyType: [],
        creationPeriod: [],
        cessationPeriod: [],
      },
      officer: {
        occupation: [],
        positionRole: [],
        address: '',
        nationality: [],
        postal_code: '',
        age: [],
      },
    });

  const [ngTasks, setNgTasks] = useState([]);
  const [ngTaskId, setNgTaskId] = useState(null);

  useEffect(() => {
    if (user?.uid && ngTaskId) {
      // Reference to the main task document
      const taskDocRef = doc(
        db,
        process.env.REACT_APP_QUEUE_COLLECTION_NAME,
        user.uid,
        'tasks',
        ngTaskId
      );

      // Listen for changes in the main task document
      const unsubscribeTask = onSnapshot(taskDocRef, async docSnap => {
        if (docSnap.exists()) {
          let updatedTask = { ...docSnap.data(), id: docSnap.id };

          // Fetch and merge `relationship` subcollection if it exists
          const relationshipRef = collection(taskDocRef, 'relationship');
          const relationshipSnapshot = await getDocs(relationshipRef);

          if (!relationshipSnapshot.empty) {
            const sortedChunks = relationshipSnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => a.chunkIndex - b.chunkIndex);

            const completeGraphData = sortedChunks
              .map(chunk => chunk.data)
              .join('');

            try {
              updatedTask.graph = JSON.parse(completeGraphData);
            } catch (error) {
              console.error('Error parsing relationship JSON:', error);
            }
          }

          // Fetch and merge `result` subcollection if it exists
          const resultsRef = collection(taskDocRef, 'result');
          const resultsSnapshot = await getDocs(resultsRef);

          if (!resultsSnapshot.empty) {
            const sortedChunks = resultsSnapshot.docs
              .map(doc => doc.data())
              .sort((a, b) => a.chunkIndex - b.chunkIndex);

            const completeResultData = sortedChunks
              .map(chunk => chunk.data)
              .join('');

            try {
              updatedTask.result = JSON.parse(completeResultData);
            } catch (error) {
              console.error('Error parsing result JSON:', error);
            }
          }

          setNgTasks([updatedTask]);
        } else {
          setNgTasks([]);
          console.warn('Task deleted or not found');
        }
      });

      // Cleanup listener on unmount
      return () => {
        unsubscribeTask();
      };
    }
  }, [user, ngTaskId]);

  useEffect(() => {
    console.log('ngTaskId', ngTaskId, ngTasks);
  }, [ngTaskId, ngTasks]);

  useEffect(() => {
    const googleAnalytics = async () => {
      InitializeGoogleAnalytics();
      await triggerGoogleAnalyticsEvent('page_visits', 'home');
    };
    googleAnalytics();
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      {isUserSetup && <LoadingScreen />}
      <AppContext.Provider
        value={{
          appInfo: appData,
          toggleSidebar: toggleSidebar,
          setToggleSidebar: setToggleSidebar,
          setAppInfo: setAppData,
          userLoggedIn: isUserLoggedIn,
          setIsUserLoggedIn: setIsUserLoggedIn,
          judgementChecker: judgementChecker,
          setJudgementChecker: setJudgementChecker,
          setUser: setUser,
          user: user,
          searchedEntities: searchedEntities,
          setSearchedEntites: setSearchedEntites,
          sideBarInfo: sideBarInfo,
          setSideBarInfo: setSideBarInfo,
          corporateExplorerSelectedFilterItem:
            corporateExplorerSelectedFilterItem,
          setCorporateExplorerSelectedFilterItem:
            setCorporateExplorerSelectedFilterItem,
          setClGraph,
          clGraph,
          companiesAndOfficers,
          setCompaniesAndOfficers,
          officer,
          setOfficer,
          company,
          setCompany,
          corporateLinksAdvanceFilters,
          setCorporateLinksAdvanceFilters,
          handleNavigation,
          setHandleNavigation,
          financialAccountsDoc,
          setFinancialAccountsDoc,
          financialAccountsProcessed,
          setFinancialAccountsProcessed,
          userCurrentLocation,
          setUserCurrentLocation,
          isBeingWatched,
          setIsBeingWatched,
          setTrackShareholder,
          trackShareholder,
          corporateLinksDynamicFilters,
          setCorporateLinksDynamicFilters,
          ngTasks,
          setNgTaskId,
          setNgTasks,
        }}
      >
        <BrowserRouter>
          <AuthProvider unprotectedRoutes={UNPROTECTED_ROUTES}>
            <ErrorBoundary>
              <Routes>
                <Route>
                  <Route element={<Home />} path="/" />
                  <Route element={<Home />} path="/home" />
                  <Route element={<PaymentCancel />} path="/payment-cancel" />
                  <Route element={<PricingPage />} path="/pricing-page" />
                  <Route element={<UserAuthHandler />} path="/auth" />
                  <Route element={<MainLayout />} path="/*" />
                </Route>
              </Routes>
            </ErrorBoundary>
          </AuthProvider>
        </BrowserRouter>
        <ToastContainer />
      </AppContext.Provider>
    </>
  );
}

export default App;
