import React, { useState, useEffect } from 'react';
import { routeConfig } from '../../utils/routeConfig';
import { useAuth } from '../../context/AuthProvider';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [redirectPath, setRedirectPath] = useState('/auth');
  const { logout } = useAuth();
  const ERROR_THRESHOLD = 3; // Maximum allowed consecutive errors

  const findKeyBySegment = (segment, config) => {
    try {
      for (const [key, values] of Object.entries(config)) {
        if (values.includes(segment)) {
          return key;
        }
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const getSegments = url => {
    const { pathname } = new URL(url);
    const segments = pathname.split('/').filter(segment => segment !== '');
    return {
      lastSegment: segments[segments.length - 1],
      precedingSegment: segments[segments.length - 2] || '',
    };
  };

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      setHasError(true);

      // Track the number of errors
      let errorCount = parseInt(
        sessionStorage.getItem('errorCount') || '0',
        10
      );
      errorCount += 1;
      sessionStorage.setItem('errorCount', errorCount);

      if (errorCount >= ERROR_THRESHOLD) {
        // If the error count exceeds the threshold, log out the user
        sessionStorage.removeItem('errorCount'); // Reset error count
        logout();
        window.location.href = '/auth';
        return;
      }

      const { lastSegment } = getSegments(window.location.href);
      if (lastSegment) {
        let newPath = findKeyBySegment(lastSegment, routeConfig);
        setRedirectPath(newPath);

        setTimeout(() => {
          window.location.href = '/' + newPath;
        }, 400);
      }
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', errorHandler);
    };
  }, [logout]);

  if (hasError) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center vh-100">
        <h1>Something went wrong.</h1>
        <p className="text-center">
          Navigating to <em>/{redirectPath}</em>{' '}
        </p>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
