import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';
import './Services.css';
import corporateLink from '../../assets/images/icons/corporateLinkIcon.png';
import arrowFront from '../../assets/images/icons/arrowFront.png';
import judgementChecker from '../../assets/images/icons/judgementChecker.png';
import corporateSearch from '../../assets/images/icons/corporateSearch.png';
import corporateProfile from '../../assets/images/icons/corporateProfile.png';
import { firebaseAuth } from '../../context/firebaseContext/firebase';
import { AppContext } from '../../context/Context';
import Pricing from './Pricing';
import MainFooter from '../MainFooter/footer';

const Services = () => {
  const { user, setUser } = useContext(AppContext);
  const navigate = useNavigate();
  const pricingRef = useRef(null);

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleClick = boxNumber => {
    switch (boxNumber) {
      case 1:
        navigate('/ccj-dashboard/judgement-checker');
        break;
      case 2:
        navigate('/cl-dashboard/search-entites');
        break;
      case 3:
        navigate('/ce-dashboard/ce-corporate-explorer');
        break;
      case 4:
        navigate('/cei-dashboard/cei-corporate-explore-insight');
        break;
      case 5:
        navigate('/bi-dashboard/background-investigation');
        break;
      case 6:
        navigate('/fo-dashboard/foregn-uk-ownership');
        break;
      default:
        navigate('/ccj-dashboard/judgement-checker');
    }
  };

  const onClickServicesSubscriptionPackages = () => {
    // Scroll to the pricing section
    if (pricingRef.current) {
      pricingRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (firebaseAuth) {
      setUser(prevState => ({
        ...prevState,
        authProvider: firebaseAuth?.currentUser?.providerData[0]?.providerId,
      }));
    }
  }, []);

  return (
    <div>
      {/* Banner Section */}
      <div className="banner">
        <h1 className="banner-heading">Unlock the Power of Industry</h1>
        <h1 className="banner-heading">Insights</h1>
        <p className="banner-paragraph" style={{ marginTop: '2%' }}>
          Probe Digital delivers comprehensive company research, revealing key
          connections between.
        </p>
        <p className="banner-paragraph">
          businesses, directors, and industry leaders. Discover the most
          accurate data to drive smarter decisions.
        </p>
        {/* <div className="button-container">
          <button
            className="banner-button"
            onClick={() => console.log('Subscription Packages clicked')}
          >
            Subscription Packages
          </button>
          <button
            className="banner-button"
            onClick={() => console.log('Contact Us clicked')}
          >
            Contact Us
          </button>
        </div> */}
      </div>

      <div class="services">
        <div class="services-heading">
          <span>Services</span>
          <h2>Click to access service</h2>
          <p>
            Gain comprehensive intelligence on businesses, discover new
            customers, and mitigate risks with real-time, accurate data on
            companies, directors, share interests, company assets, and
            judgments.
          </p>
        </div>
        <div class="services-container">
          <div
            class="service-box"
            onClick={() => {
              handleClick(2);
            }}
          >
            <div>
              <img
                className="service-box-icon"
                src={corporateLink}
                alt="service-box-icon"
                width={'70px'}
                height={'70px'}
              />
              <h3>Corporate Links</h3>
              <p>
                Discover the intricate relationships between businesses,
                individuals, and gain insight into the network.
              </p>
            </div>
            <span href="#" onClick={() => handleClick(1)}>
              Access Now
              <img
                src={arrowFront}
                alt="font-arrow"
                width={'12px'}
                height={'12px'}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </div>
          <div
            class="service-box"
            onClick={() => {
              handleClick(3);
            }}
          >
            <div>
              <img
                className="service-box-icon"
                src={corporateSearch}
                alt="service-box-icon"
                width={'70px'}
                height={'70px'}
              />
              <h3>Corporate Search</h3>
              <p>
                Effortlessly learn about companies that matter with
                comprehensive insight.
              </p>
            </div>
            <span href="#" onClick={() => handleClick(2)}>
              Access Now
              <img
                src={arrowFront}
                alt="font-arrow"
                width={'12px'}
                height={'12px'}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </div>
          <div
            class="service-box"
            onClick={() => {
              handleClick(4);
            }}
          >
            <div>
              <img
                className="service-box-icon"
                src={corporateProfile}
                alt="service-box-icon"
                width={'70px'}
                height={'70px'}
              />
              <h3>Corporate Profile</h3>
              <p>
                Explore detailed corporate profiles with relationships and
                insights into networks.
              </p>
            </div>
            <span href="#" onClick={() => handleClick(3)}>
              Access Now
              <img
                src={arrowFront}
                alt="font-arrow"
                width={'12px'}
                height={'12px'}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </div>
          <div
            class="service-box"
            onClick={() => {
              handleClick(1);
            }}
          >
            <div>
              <img
                className="service-box-icon"
                src={judgementChecker}
                alt="service-box-icon"
                width={'70px'}
                height={'70px'}
              />
              <h3>Judgment Checker</h3>
              <p>
                Discover intricate relationships between businesses and
                individuals and gain insights.
              </p>
            </div>
            <span href="#" onClick={() => handleClick(4)}>
              Access Now
              <img
                src={arrowFront}
                alt="font-arrow"
                width={'12px'}
                height={'12px'}
                style={{ marginLeft: '8px' }}
              />
            </span>
          </div>
          {/* <div class="service-box" onClick={() => { handleClick(5) }}>
            <img className="service-box-icon" src={corporateProfile} alt="service-box-icon" width={'70px'} height={'70px'} />
            <h3>Background Investigation</h3>
            <p>Explore detailed Background Investigations with relationships and insights into networks.</p>
            <span href='#' onClick={() => handleClick(3)}>Access Now
              <img src={arrowFront} alt="font-arrow" width={'12px'} height={'12px'} style={{ marginLeft: '8px' }} />
            </span>
          </div>
          <div class="service-box" onClick={() => { handleClick(6) }}>
            <img className="service-box-icon" src={judgementChecker} alt="service-box-icon" width={'70px'} height={'70px'} />
            <h3>Foreign UK Ownership</h3>
            <p>Discover intricate relationships between businesses and individuals and gain insights.</p>
            <span href='#' onClick={() => handleClick(4)}>Access Now
              <img src={arrowFront} alt="font-arrow" width={'12px'} height={'12px'} style={{ marginLeft: '8px' }} />
            </span>
          </div> */}
        </div>
      </div>

      <div className="pricing" ref={pricingRef}>
        <div className="pricing-heading">
          <span>Pricing</span>
          <h2>Simple Pricing, Powerful Features</h2>
          <p>
            Simple, transparent pricing that grows with you. Buy more, pay less.
          </p>
        </div>
        <Pricing />
      </div>

      <MainFooter />
    </div>
  );
};

export default Services;
