import React, { useContext, useEffect, useState } from 'react';
import './CEILatestActivity.css';
import {
  getCompaniesAndOfficerHistoryForWatchlist,
  getWatchListByCompanyNumberAndUserId,
  setWatchListForCompanyByUserId,
} from '../../../api/watchList/watchList';
import { AppContext } from '../../../context/Context';
import { useNavigate } from 'react-router';
import { useLoading } from '../../../context/LoadingContext/LoadingContext';
import graphIcon from '../../../assets/images/icons/GraphIcon.png'
import document from '../../../assets/images/icons/document.png'
import personActivity from '../../../assets/images/icons/personActivity.png'
import fileCheck from '../../../assets/images/icons/fileCheck.png'

const CEILatestActivity = ({ selectedCompany }) => {
  const { setIsLoading } = useLoading();
  const navigate = useNavigate();
  const { user, setIsBeingWatched, isBeingWatched } = useContext(AppContext);
  const [watchlist, setWatchList] = useState(null);

  const onClickAddToWatchList = async () => {
    try {
      if (selectedCompany) {
        setIsLoading(true);
        let companyNumber = selectedCompany?.company_number;
        let userId = user?.uid;
        let relationship = 'Owner';

        let addRespons = await setWatchListForCompanyByUserId(
          userId,
          companyNumber,
          relationship
        );

        if (addRespons?.success) {
          await watchCheck();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const watchCheck = async () => {
    let isWatch = await getWatchListByCompanyNumberAndUserId(
      user?.uid,
      selectedCompany?.company_number
    );
    if (typeof isWatch === 'boolean') {
      setIsBeingWatched(isWatch);
    }
  };

  useEffect(() => {
    if (selectedCompany) {
      const fetchData = async () => {
        await watchCheck();
        let watchListResponse = await getCompaniesAndOfficerHistoryForWatchlist(
          selectedCompany.company_number
        );
        setWatchList(watchListResponse?.watchlist);
      };

      if (selectedCompany) {
        fetchData();
      }
    }
  }, [selectedCompany]);

  const iconAccordingToMessage = (message) => {
    let iconSrc;

    switch (true) {
      case message.includes('Resigned'):
        iconSrc = personActivity
        break;
      case message.includes('Appointed'):
        iconSrc = personActivity
        break;
      case message.includes('Submitted'):
        iconSrc = fileCheck
        break;
      case message.includes('Address'):
        iconSrc = document
        break;
      default:
        return <span style={{ fontSize: '20px' }}>🔍</span>; // Default icon
    }

    return <img className="activity-icon" src={iconSrc} alt="icon" width={20} height={20} />;
  };

  return (
    <div className="cei-latest-activity">
      <h2 className="contact-card-title"><img style={{ marginRight: '10px' }} src={graphIcon} alt="icon" />Latest Activity</h2>
      <div className="activity-list-container">
        <ul className="activity-list">
          {watchlist &&
            watchlist?.reverse()?.map((activity, index) => (
              <li key={index} className="activity-item">
                <div>
                  <span>{iconAccordingToMessage(activity?.message || '')}</span>
                </div>
                <div className="activity-details">
                  <p className="activity-type">{activity?.message}</p>
                  <p className="activity-time">
                    {/* <span style={{ fontWeight: 'bold' }}>
                      {activity?.updatedOn?.timeString}
                    </span>{' '} */}
                    <span className="activity-date">
                      {activity?.updatedOn?.formattedDate}
                    </span>
                  </p>
                </div>
              </li>
            ))}
          {watchlist?.length < 1 &&
            <li className="activity-item">
              <p className="activity-type">No watch list activity available.</p>
            </li>
          }
        </ul>
      </div>
      {/* {isBeingWatched === true ? (
        <button
          className="add-to-watch-list-btn"
          onClick={() => navigate('/watchlist')}
        >
          Watching
        </button>
      ) : (
        <button
          className="add-to-watch-list-btn"
          onClick={() => onClickAddToWatchList()}
        >
          ADD TO WATCH LIST
        </button>
      )} */}
    </div>
  );
};

export default CEILatestActivity;
