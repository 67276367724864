import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import { getMonthName } from '../../../../utils/common';
import {
  nameCleanUp,
  nationalityToFlag,
  statusDiv,
} from '../../../../utils/helper';
import { Twitter } from '@material-ui/icons';
const PeopleAndContactItem = ({ officer, isCardView = false }) => {

  return (
    <div
      className={` ${isCardView
        ? 'company-info-modal-person-list-is-card-view'
        : 'company-info-modal-person-list'
        }`}
    >
      <span className="company-info-modal-person-picture"></span>
      <div className="company-info-modal-person-list-info">
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%', marginLeft: '10px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '2px',
              }}
            >
              <div>
                <div style={{ fontSize: '14px', fontWeight: 600 }}>{officer.name}</div>
                <div style={{ marginLeft: '0px' }} className="company-info-modal-overview-value">Nationality:<span>{" "}{officer.nationality}</span></div>
                <div style={{ marginLeft: '0px' }} className="company-info-modal-overview-value company-info-modal-overview-value-people-list">
                  <div>
                    {officer?.date_of_birth?.month && officer?.date_of_birth?.year && (
                      <span>
                        DOB:{" "}
                        {getMonthName(officer?.date_of_birth?.month)}
                        {officer.date_of_birth.year}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div style={{ color: '#00D1A9', fontSize: '12px', fontWeight: '400', textAlign: 'right' }}>{officer.officer_role}</div>
                <div className="company-info-modal-person-list-info-link-buttons">
                  {/* <div>
                  {officer.resigned_on
                    ? statusDiv('resigned')
                    : statusDiv('active')}
                </div> */}
                  <div>
                    <a
                      href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(officer?.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter style={{ width: '20px', color: '#98A2B3' }} />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(officer?.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon style={{ width: '20px', color: '#98A2B3' }} />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://www.google.com/search?q=${officer?.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LanguageIcon style={{ width: '20px', color: '#98A2B3' }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PeopleAndContactItem;
