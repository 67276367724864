import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faSearch,
  faClone,
  faTh,
  faSquareXmark,
  faNotesMedical,
  faUserLock,
  faShareSquare,
  faTimes,
  faBuilding,
  faCloud,
  faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import PrivateBuildings from '../../assets/images/icons/CompaniesIcon.png'
import './CorporateExplorerFilterExpanded.css';
import CONSTANTS from '../../utils/constants';
import { debounce, flattenStructure, searchList } from '../../utils/helper';

const CorporateExplorerFilterExpanded = ({
  onClose,
  filterReadyToApply,
  setFilterReadyToApply,
  totalCompaniesDocument,
  setTotalCompaniesDocument,
  applyFilters,
  locationSearchInput,
  locationSearchResults,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [checkedOptions, setCheckedOptions] = useState({});
  const [locationSearchValue, setLocationSearchValue] = useState('');
  const [sicCodeSearchValue, setSicCodeSearchValue] = useState('');
  const [searchedSicCodes, setSearchedSicCodes] = useState([]);
  const [tempFilterReadyToApply, setTempFilterReadyToApply] = useState([]);
  const [selectedTypeCategory, setSelectedTypeCategory] = useState('Private');

  // Initialize states when component mounts
  useEffect(() => {
    setTempFilterReadyToApply([...filterReadyToApply]);

    // Initialize checkedOptions from existing filters
    const initialCheckedOptions = {};
    filterReadyToApply.forEach(filter => {
      const header = filter.header.toLowerCase();
      if (header === 'status') {
        filter.filter.forEach(value => {
          // Find the original case value from filterOptions
          const originalCaseValue = filterOptions.status.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`status-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'type') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.type.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`type-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'accounts category') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.accountsCategory.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`accountsCategory-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'incorporation') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.incorporation.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`incorporation-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'accounts year end') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.accountsYearEnd.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`accountsYearEnd-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'accounts due by') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.accountsDueBy.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`accountsDueBy-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'accounts last made') {
        filter.filter.forEach(value => {
          const originalCaseValue = filterOptions.accountsLastMade.find(opt => opt.value.toLowerCase() === value)?.value;
          if (originalCaseValue) {
            initialCheckedOptions[`accountsLastMade-${originalCaseValue}`] = true;
          }
        });
      } else if (header === 'registered address') {
        filter.filter.forEach(value => {
          const [location, postcode] = value.split('__');
          initialCheckedOptions[`location-${location}-${postcode}`] = true;
        });
      }
    });
    setCheckedOptions(initialCheckedOptions);

    // Initialize selectedOptions from existing filters
    const initialSelectedOptions = {};
    filterReadyToApply.forEach(filter => {
      const header = filter.header.toLowerCase();
      if (header === 'status') {
        initialSelectedOptions.status = filter.filter.map(value =>
          filterOptions.status.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'type') {
        initialSelectedOptions.type = filter.filter.map(value =>
          filterOptions.type.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'accounts category') {
        initialSelectedOptions.accountsCategory = filter.filter.map(value =>
          filterOptions.accountsCategory.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'incorporation') {
        initialSelectedOptions.incorporation = filter.filter.map(value =>
          filterOptions.incorporation.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'accounts year end') {
        initialSelectedOptions.accountsYearEnd = filter.filter.map(value =>
          filterOptions.accountsYearEnd.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'accounts due by') {
        initialSelectedOptions.accountsDueBy = filter.filter.map(value =>
          filterOptions.accountsDueBy.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      } else if (header === 'accounts last made') {
        initialSelectedOptions.accountsLastMade = filter.filter.map(value =>
          filterOptions.accountsLastMade.find(opt => opt.value.toLowerCase() === value)?.value || value
        );
      }
    });
    setSelectedOptions(initialSelectedOptions);
  }, []);

  // Update states when filterReadyToApply changes
  useEffect(() => {
    setTempFilterReadyToApply([...filterReadyToApply]);
  }, [filterReadyToApply]);

  // Define options for each filter category
  const filterOptions = {
    status: [
      { type: 'label', value: 'Active' },
      { type: 'label', value: 'Dormant' },
      { type: 'label', value: 'Dissolved' },
      { type: 'label', value: 'In Liquidation' },
      { type: 'label', value: 'In Administration' },
      { type: 'label', value: 'In Receivership' },
    ],
    type: [
      { type: 'header', value: 'Private', isSelectable: true },
      { type: 'label', value: 'Private (All)', category: 'Private' },
      { type: 'label', value: 'Private Unlimited with Share Capital', category: 'Private' },
      { type: 'label', value: 'Private Unlimited without Share Capital', category: 'Private' },
      { type: 'label', value: 'Private Limited with Share Capital', category: 'Private' },
      { type: 'label', value: 'Private Limited by Guarantee without Share Capital (Exempt from using Capital)', category: 'Private' },
      { type: 'label', value: 'Private Limited (Exempt from using Limited)', category: 'Private' },
      { type: 'header', value: 'Public', isSelectable: true },
      { type: 'label', value: 'Public (All)', category: 'Public' },
      { type: 'label', value: 'Public Limited with Share Capital', category: 'Public' },
      { type: 'label', value: 'Old Public Limited', category: 'Public' },
      { type: 'label', value: 'Societas Europaea (SE)', category: 'Public' },
      { type: 'header', value: 'Other', isSelectable: true },
      { type: 'label', value: 'Limited Partnership', category: 'Other' },
      { type: 'label', value: 'Other', category: 'Other' },
    ],
    accountsCategory: [
      { type: 'label', value: 'Micro Entity' },
      { type: 'label', value: 'Small' },
      { type: 'label', value: 'Medium' },
      { type: 'label', value: 'Full' },
      { type: 'label', value: 'Group' },
      { type: 'label', value: 'Dormant' },
      { type: 'label', value: 'Interim' },
      { type: 'label', value: 'Initial' },
      { type: 'label', value: 'Total Exemption Full' },
      { type: 'label', value: 'Total Exemption Small' },
      { type: 'label', value: 'Partial Exemption' },
      { type: 'label', value: 'Audit Exemption Subsidiary' },
      { type: 'label', value: 'Filing Exemption Subsidiary' },
      { type: 'label', value: 'Audited Abridged' },
      { type: 'label', value: 'Unaudited Abridged' },
    ],
    incorporation: [
      { type: 'label', value: 'Today' },
      { type: 'label', value: 'Yesterday' },
      { type: 'label', value: 'This Week (Mon - Today)' },
      { type: 'label', value: 'Last 7 days' },
      { type: 'label', value: 'Last week (Mon - Sun)' },
      { type: 'label', value: 'Last 14 days' },
      { type: 'label', value: 'This month' },
      { type: 'label', value: 'Last 30 days' },
      { type: 'label', value: 'Last month' },
      { type: 'label-date-range', value: 'Select custom date' },
    ],
    accountsYearEnd: [
      { type: 'label', value: 'Today' },
      { type: 'label', value: 'Yesterday' },
      { type: 'label', value: 'Tomorrow' },
      { type: 'label', value: 'This Week (Mon - Today)' },
      { type: 'label', value: 'Last 7 days' },
      { type: 'label', value: 'Next 7 days' },
      { type: 'label', value: 'Last 14 days' },
      { type: 'label', value: 'Next 14 days' },
      { type: 'label', value: 'This month (1st - Today)' },
      { type: 'label', value: 'Last 30 days' },
      { type: 'label', value: 'Next 30 days' },
      { type: 'label-date-range', value: 'Select custom date' },
    ],
    accountsDueBy: [
      { type: 'label', value: 'Today' },
      { type: 'label', value: 'Yesterday' },
      { type: 'label', value: 'Tomorrow' },
      { type: 'label', value: 'This Week (Mon - Today)' },
      { type: 'label', value: 'Last 7 days' },
      { type: 'label', value: 'Next 7 days' },
      { type: 'label', value: 'Last 14 days' },
      { type: 'label', value: 'Next 14 days' },
      { type: 'label', value: 'This month (1st - Today)' },
      { type: 'label', value: 'Last 30 days' },
      { type: 'label', value: 'Next 30 days' },
      { type: 'label-date-range', value: 'Select custom date' },
    ],
    accountsLastMade: [
      { type: 'label', value: 'Today' },
      { type: 'label', value: 'Yesterday' },
      { type: 'label', value: 'Tomorrow' },
      { type: 'label', value: 'This Week (Mon - Today)' },
      { type: 'label', value: 'Last 7 days' },
      { type: 'label', value: 'Next 7 days' },
      { type: 'label', value: 'Last 14 days' },
      { type: 'label', value: 'Next 14 days' },
      { type: 'label', value: 'This month (1st - Today)' },
      { type: 'label', value: 'Last 30 days' },
      { type: 'label', value: 'Next 30 days' },
      { type: 'label-date-range', value: 'Select custom date' },
    ],
  };

  const handleFilterClick = (filterType) => {
    if (selectedFilter === filterType) {
      setSelectedFilter(null);
    } else {
      setSelectedFilter(filterType);
    }
    setOpenDropdown(null); // Close any open dropdowns when changing filter type
  };

  const handleApplyFilters = () => {
    setFilterReadyToApply([...tempFilterReadyToApply]);
    applyFilters();
    // Don't close the expanded filter to maintain state
    // onClose();
  };

  const handleDropdownClick = (dropdownType, event) => {
    // Prevent event from bubbling up
    event.stopPropagation();
    if (openDropdown === dropdownType) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(dropdownType);
    }
  };

  const filterableDropdowns = {
    'status': 'Status',
    'type': 'Type',
    'accountsCategory': 'Accounts Category',
    'incorporation': 'Incorporation',
    'accountsYearEnd': 'Accounts Year End',
    'accountsDueBy': 'Accounts Due By',
    'accountsLastMade': 'Accounts Last Made'
  };

  const handleCheckboxChange = (dropdownType, option, event) => {
    // Prevent event from bubbling up
    event.stopPropagation();
    // Create a unique key for this checkbox
    const checkboxKey = `${dropdownType}-${option}`;

    // Toggle the checkbox state
    const newCheckedOptions = {
      ...checkedOptions,
      [checkboxKey]: !checkedOptions[checkboxKey]
    };
    setCheckedOptions(newCheckedOptions);

    // Update selected options - collect all checked options for this dropdown
    const selectedForDropdown = Object.keys(newCheckedOptions)
      .filter(key => key.startsWith(`${dropdownType}-`) && newCheckedOptions[key])
      .map(key => key.replace(`${dropdownType}-`, ''));
    setSelectedOptions({
      ...selectedOptions,
      [dropdownType]: selectedForDropdown.length > 0 ? selectedForDropdown : undefined
    });

    // Check if the current dropdown is one we want to filter
    if (filterableDropdowns[dropdownType]) {
      const updatedTempFilters = [...tempFilterReadyToApply];
      const header = filterableDropdowns[dropdownType];
      const existingIndex = updatedTempFilters.findIndex(
        item => item.header === header
      );
      if (existingIndex !== -1) {
        // If filter already exists, update it
        if (selectedForDropdown.length > 0) {
          updatedTempFilters[existingIndex].filter = selectedForDropdown.map(value => value.toLowerCase());
        } else {
          // Remove the filter if no options are selected
          updatedTempFilters.splice(existingIndex, 1);
        }
      } else if (selectedForDropdown.length > 0) {
        // Add new filter
        updatedTempFilters.push({
          header: header,
          filter: selectedForDropdown.map(value => value.toLowerCase())
        });
      }
      setTempFilterReadyToApply(updatedTempFilters);
    }
  };

  // Handle location search input change
  const handleLocationSearchChange = (e) => {
    const value = e.target.value;
    setLocationSearchValue(value);
    if (locationSearchInput && value) {
      locationSearchInput(value);
    }
  };

  // Handle location selection - similar to handleStatusLabelInput in FilterMidInput
  const handleLocationSelection = (result) => {
    const locationValue = result.value + "__" + result.key;
    const status = locationValue.toLowerCase();

    // Clone the state array to avoid directly mutating it
    const updatedTempFilters = [...tempFilterReadyToApply];
    const header = "Registered Address";

    // Check if an item with the same header already exists
    const existingIndex = updatedTempFilters.findIndex(
      item => item.header === header
    );
    if (existingIndex !== -1) {
      // If item with same header exists, check if status already exists in the filter array
      if (!updatedTempFilters[existingIndex].filter.includes(status)) {
        // If status doesn't exist, push it to the filter array
        updatedTempFilters[existingIndex].filter.push(status);
      }
    } else {
      // If item with same header doesn't exist, append it to the array
      updatedTempFilters.push({
        header: header,
        filter: [status]
      });
    }
    setTempFilterReadyToApply(updatedTempFilters);
  };

  // Add the search handler function
  const handleSicCodeSearch = debounce((searchInput) => {
    if (searchInput) {
      let searchedResults = searchList(
        searchInput,
        flattenStructure(CONSTANTS.SIC_CODES)
      );
      setSearchedSicCodes(searchedResults);
    } else {
      setSearchedSicCodes([]);
    }
  }, 400);

  // Add handler for selecting a SIC code
  const handleSicCodeSelection = (sicCode) => {
    const updatedTempFilters = [...tempFilterReadyToApply];
    const header = "SIC Code";
    const lowercaseSicCode = sicCode.toLowerCase();

    const existingIndex = updatedTempFilters.findIndex(
      item => item.header === header
    );
    if (existingIndex !== -1) {
      // If filter exists, toggle the selection
      const filterIndex = updatedTempFilters[existingIndex].filter.indexOf(lowercaseSicCode);
      if (filterIndex === -1) {
        // Add if not present
        updatedTempFilters[existingIndex].filter.push(lowercaseSicCode);
      } else {
        // Remove if present
        updatedTempFilters[existingIndex].filter.splice(filterIndex, 1);
      }
      // Remove the filter entry if no values left
      if (updatedTempFilters[existingIndex].filter.length === 0) {
        updatedTempFilters.splice(existingIndex, 1);
      }
    } else {
      // Add new filter
      updatedTempFilters.push({
        header: header,
        filter: [lowercaseSicCode]
      });
    }
    setTempFilterReadyToApply(updatedTempFilters);
  };

  // Add this new function inside the component
  const handleRemoveOption = (dropdownType, optionToRemove, event) => {
    event.stopPropagation();
    const checkboxKey = `${dropdownType}-${optionToRemove}`;

    const newCheckedOptions = {
      ...checkedOptions,
      [checkboxKey]: false
    };
    setCheckedOptions(newCheckedOptions);

    const selectedForDropdown = Object.keys(newCheckedOptions)
      .filter(key => key.startsWith(`${dropdownType}-`) && newCheckedOptions[key])
      .map(key => key.replace(`${dropdownType}-`, ''));
    setSelectedOptions({
      ...selectedOptions,
      [dropdownType]: selectedForDropdown.length > 0 ? selectedForDropdown : undefined
    });

    const updatedTempFilters = [...tempFilterReadyToApply];
    const header = filterableDropdowns[dropdownType];
    const existingIndex = updatedTempFilters.findIndex(
      item => item.header === header
    );
    if (existingIndex !== -1) {
      if (selectedForDropdown.length > 0) {
        updatedTempFilters[existingIndex].filter = selectedForDropdown.map(value => value.toLowerCase());
      } else {
        updatedTempFilters.splice(existingIndex, 1);
      }
      setTempFilterReadyToApply(updatedTempFilters);
    }
  };

  const renderDropdownOptions = (dropdownType) => {
    if (!openDropdown || openDropdown !== dropdownType) {
      return null;
    }

    const options = filterOptions[dropdownType];
    if (!options) return null;

    const getStatusIcon = (status) => {
      switch (status) {
        case 'Active':
          return faClone;
        case 'Dormant':
          return faTh;
        case 'Dissolved':
          return faSquareXmark;
        case 'In Liquidation':
          return faNotesMedical;
        case 'In Administration':
          return faUserLock;
        case 'In Receivership':
          return faShareSquare;
        default:
          return null;
      }
    };

    // Special handling for type dropdown
    if (dropdownType === 'type') {
      const categories = [
        { name: 'Private', icon: faBuilding },
        { name: 'Public', icon: faCloud },
        { name: 'Other', icon: faBriefcase }
      ];
      return (
        <div className="dropdown-options" onClick={(e) => e.stopPropagation()}>
          <div className="type-category-buttons">
            {categories.map((category) => (
              <button
                key={category.name}
                className={`type-category-button ${selectedTypeCategory === category.name ? 'selected' : ''}`}
                onClick={() => setSelectedTypeCategory(category.name)}
              >
                <FontAwesomeIcon icon={category.icon} className="type-category-icon" />
                {category.name}
              </button>
            ))}
          </div>
          <div className="type-options">
            {options
              .filter(option => option.category === selectedTypeCategory && option.type === 'label')
              .map((option, index) => {
                const checkboxKey = `${dropdownType}-${option.value}`;
                return (
                  <div
                    key={index}
                    className="dropdown-option-checkbox"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <input
                      type="checkbox"
                      id={checkboxKey}
                      checked={!!checkedOptions[checkboxKey]}
                      onChange={(e) => handleCheckboxChange(dropdownType, option.value, e)}
                    />
                    <label htmlFor={checkboxKey}>
                      {option.value}
                    </label>
                  </div>
                );
              })}
          </div>
        </div>
      );
    }

    // Default rendering for other dropdowns
    return (
      <div className="dropdown-options" onClick={(e) => e.stopPropagation()}>
        {options.map((option, index) => {
          // Create a unique key for this checkbox
          const checkboxKey = `${dropdownType}-${option.value}`;

          if (option.type === 'header') {
            return (
              <div key={index} className="option-header">
                {option.value}
              </div>
            );
          } else {
            const statusIcon = dropdownType === 'status' ? getStatusIcon(option.value) : null;
            return (
              <div
                key={index}
                className="dropdown-option-checkbox"
                onClick={(e) => e.stopPropagation()}
                data-status={dropdownType === 'status' ? option.value : undefined}
              >
                <input
                  type="checkbox"
                  id={checkboxKey}
                  checked={!!checkedOptions[checkboxKey]}
                  onChange={(e) => handleCheckboxChange(dropdownType, option.value, e)}
                />
                {statusIcon && <FontAwesomeIcon icon={statusIcon} className="status-icon" />}
                <label htmlFor={checkboxKey}>
                  {option.value}
                </label>
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <div className="expanded-filter-wrapper">
      <div className="cp-explorer-filter-expanded">
        <div className="cp-explorer-filter-expanded-container" onClick={() => handleFilterClick('keyData')}>
          <div className='cp-explorer-filter-expanded-top'>
            <button
              className="cp-explorer-filter-expanded-dropdown"
              type="text"
            >
              Key Data
            </button>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className="cp-explorer-filter-expanded-container" onClick={() => handleFilterClick('dates')}>
          <div className='cp-explorer-filter-expanded-top'>
            <button
              className="cp-explorer-filter-expanded-dropdown"
              type="text"
            >
              Dates
            </button>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className="cp-explorer-filter-expanded-container" onClick={() => handleFilterClick('location')}>
          <div className='cp-explorer-filter-expanded-top'>
            <button
              className="cp-explorer-filter-expanded-dropdown"
              type="text"
            >
              Location
            </button>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
        <div className="cp-explorer-filter-expanded-container" onClick={() => handleFilterClick('sicCodes')}>
          <div className='cp-explorer-filter-expanded-top'>
            <button
              className="cp-explorer-filter-expanded-dropdown"
              type="text"
            >
              Sic Codes
            </button>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>

      {/* Filter content based on selection */}
      {selectedFilter === 'keyData' && (
        <div className="cp-explorer-filter-expanded">
          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('status', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.status && selectedOptions.status.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('status', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))} Status
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('status')}
          </div>

          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('type', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.type && selectedOptions.type.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('type', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}                Type
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('type')}
          </div>

          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('accountsCategory', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.accountsCategory && selectedOptions.accountsCategory.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('accountsCategory', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}
                <span className="desktop-text">Accounts Category</span>
                <span className="mobile-text">Accounts</span>
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('accountsCategory')}
          </div>
        </div>
      )}

      {selectedFilter === 'dates' && (
        <div className="cp-explorer-filter-expanded dates-filter-grid">
          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('incorporation', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.incorporation && selectedOptions.incorporation.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('incorporation', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}                Incorporation
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('incorporation')}
          </div>

          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('accountsYearEnd', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.accountsYearEnd && selectedOptions.accountsYearEnd.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('accountsYearEnd', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}                Accounts Year End
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('accountsYearEnd')}
          </div>

          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('accountsDueBy', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.accountsDueBy && selectedOptions.accountsDueBy.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('accountsDueBy', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}                Accounts Due By
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('accountsDueBy')}
          </div>

          <div className="cp-explorer-filter-expanded-container" onClick={(e) => handleDropdownClick('accountsLastMade', e)}>
            <div className='cp-explorer-filter-expanded-top'>
              <button className="cp-explorer-filter-expanded-dropdown" type="text">
                {selectedOptions.accountsLastMade && selectedOptions.accountsLastMade.map((option, index) => (
                  <span key={index} className="selected-option">
                    {option}
                    <span className="remove-option" onClick={(e) => handleRemoveOption('accountsLastMade', option, e)}>
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                  </span>
                ))}                Accounts Last Made
              </button>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            {renderDropdownOptions('accountsLastMade')}
          </div>
        </div>
      )}

      {selectedFilter === 'location' && (
        <div>
          <div className="cp-explorer-right-search-container" style={{ marginTop: '2%' }}>
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '10px' }} />
            <input
              className="cp-explorer-right-search-input"
              style={{ width: '90%' }}
              type="text"
              placeholder='Search for a town, city or postcode'
              value={locationSearchValue}
              onChange={handleLocationSearchChange}
            />
          </div>
          {/* Only show results if there's a search value and results exist */}
          {locationSearchValue && Array.isArray(locationSearchResults) && locationSearchResults.length > 0 && (
            <div className="location-search-results">
              {locationSearchResults.map((result, index) => {
                const checkboxKey = `location-${result.value}-${result.key}`;
                return (
                  <div
                    key={index}
                    className="dropdown-option-checkbox"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '8px',
                      borderBottom: '1px solid #e4e4e4',
                      cursor: 'pointer',
                      color: '#003967'
                    }}
                    onClick={() => handleLocationSelection(result)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type="checkbox"
                        id={checkboxKey}
                        checked={!!checkedOptions[checkboxKey]}
                        onChange={(e) => {
                          const newCheckedOptions = {
                            ...checkedOptions,
                            [checkboxKey]: !checkedOptions[checkboxKey]
                          };
                          setCheckedOptions(newCheckedOptions);
                          handleLocationSelection(result);
                        }}
                        style={{ marginRight: '8px' }}
                      />
                      <label htmlFor={checkboxKey}>{result.value}</label>
                    </div>
                    <div>{result.key}</div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}

      {selectedFilter === 'sicCodes' && (
        <div>
          <div className="cp-explorer-right-search-container" style={{ marginTop: '2%'}}>
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: '10px' }} />
            <input
              className="cp-explorer-right-search-input"
              style={{ width: '90%' }}
              type="text"
              placeholder='Search for a code or classification'
              value={sicCodeSearchValue}
              onChange={(e) => {
                setSicCodeSearchValue(e.target.value);
                handleSicCodeSearch(e.target.value);
              }}
            />
          </div>
          {sicCodeSearchValue && searchedSicCodes.length > 0 && (
            <div className="sic-code-search-results">
              {searchedSicCodes.map((sicCode, index) => (
                <div
                  key={index}
                  className="dropdown-option-checkbox"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px',
                    borderBottom: '1px solid #e4e4e4',
                    cursor: 'pointer',
                    color: '#003967'
                  }}
                  onClick={() => handleSicCodeSelection(sicCode)}
                >
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <input
                      type="checkbox"
                      id={`sicCode-${index}`}
                      checked={tempFilterReadyToApply.some(
                        item => item.header === "SIC Code" &&
                          item.filter.includes(sicCode.toLowerCase())
                      )}
                      readOnly
                      style={{ marginRight: '8px', pointerEvents: 'none' }}
                    />
                    <label
                      htmlFor={`sicCode-${index}`}
                      style={{ width: '100%' }}
                    >
                      {sicCode}
                    </label>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Apply Filters Button */}
      {tempFilterReadyToApply?.length > 0 && (
        <div className="filter-actions">
          <button
            className="apply-filters-button"
            onClick={() => {
              handleApplyFilters();
              setLocationSearchValue('');
            }}
          >
            Apply Filters
          </button>
        </div>
      )}
    </div>
  );
};

export default CorporateExplorerFilterExpanded;