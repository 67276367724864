import React from 'react';
import './CEIPersonInsightsMutualPeople.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import mutualPeople from '../../../../assets/images/icons/MutualPeople.png'
import { nameCleanUp } from '../../../../utils/helper';

const CEIPersonInsightsMutualPeople = ({ mutual_people }) => {

  return (
    <div className="corporate-explore-insight-person-insights-container">
      <h2 className="corporate-explore-insight-person-insights-title">
        <img className="corporate-explore-insight-appointments-icon" src={mutualPeople} alt="icon" />Mutual People
      </h2>
      <div className="corporate-explore-insight-person-insights-topbar">
        <h6>Name</h6>
        <h6>Companies</h6>
        <h6>Explore</h6>
      </div>
      {mutual_people &&
        mutual_people?.map((person, index) => (
          <div
            key={index}
            className="corporate-explore-insight-person-insights-main"
          >
            {/* <div className="corporate-explore-insight-person-insights-icon">
              <FontAwesomeIcon icon={faUser} className="details-icon" />
            </div> */}
            <div className="corporate-explore-insight-person-insights-person-details">
              <div className="corporate-explore-insight-person-insights-person-detail-person-main-div">
                <span style={{ flex: 1 }} className="corporate-explore-insight-person-insights-person-detail-person-name">
                  {person.name}
                </span>

                <div style={{ flex: 1 }}>
                  {person.company_workes.map((company, idx) => (
                    <div
                      key={idx}
                      className="corporate-explore-insight-person-insights-detail-person-companies"
                    >
                      <div className="corporate-explore-insight-person-insights-person-detail-person-main-div">
                        <span className="cei-company-name" style={{ fontSize: '12px', textDecoration: 'underline' }}>
                          {company.name},{' '}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <div style={{ display: 'flex', flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  <div style={{ display: 'flex' }}>
                    <a
                      href={`https://www.google.com/search?q=${person?.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LanguageIcon style={{ width: '20px' }} />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://www.linkedin.com/search/results/all/?keywords=${nameCleanUp(person?.name)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedInIcon style={{ width: '20px' }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CEIPersonInsightsMutualPeople;
