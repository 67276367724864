import React from 'react';
import overview from '../../../assets/images/company1.png';
import './CorporateExploreInsightLogo.css';
import { capitalize } from 'lodash';

const CorporateExploreInsightLogo = ({ selectedCompany }) => {
  return (
    <div className="corporate-explore-insight-container-logo">
      <span
        className={`top-right-badge${(selectedCompany?.company_status?.toLowerCase() === "d" || selectedCompany?.company_status?.toLowerCase() === "dissolved") ? "-dissolved" : ""
          }`}
      >
        {selectedCompany?.company_status?.toLowerCase() === "d"
          ? "Dissolved"
          : capitalize(selectedCompany?.company_status)}
      </span>
      <div className="corporate-explore-insight-company-Logo">
        <img src={overview} alt="Company Logo" className="company-logo" />
        <div className="corporate-explore-insight-company-Name">
          <a href="#" style={{ color: '#021B41' }}>
            {selectedCompany?.title}
          </a>
        </div>
        <div style={{ marginBottom: '0px' }} className="corporate-explore-insight-company-Address">
          Company Number: {selectedCompany?.company_number} | Since {selectedCompany?.date_of_creation?.slice(0, 4)}
        </div>{' '}
        <div className="corporate-explore-insight-company-Address">
          {selectedCompany?.address}
        </div>{' '}
      </div>
    </div>
  );
};

export default CorporateExploreInsightLogo;
